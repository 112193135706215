import {
  FetchDateStartOfNextMonth,
  FetchDateBefore2Year
} from "../../../services/utility";

// current policy section
export const initialHealthCurrentPolicy = {
  insurance_company: "",
  expiration_date: FetchDateStartOfNextMonth(),
  insured_since: FetchDateBefore2Year()
};

// health data
export const initialHealthMedicalData = {
  height: null,
  weight: null,
  birth_date: null,
  gender: null,
  student: null,
  tobacco: null,
  bmi: null,
  medical_condition: null,
  currently_employed: null,
  number_in_household: null,
  household_income: null,
  hospitalized: null,
  ongoing_medical_treatment: null,
  previously_denied: null,
  prescriptions: null,
  prescription_description: "",
  qualifying_life_condition: null
};
// health spouse data
export const initialHealthSpouseChildData = {
  height: "",
  weight: "",
  birth_date: "",
  gender: "",
  student: "",
  tobacco: "",
  medical_condition: ""
};
