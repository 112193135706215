import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral["100"]
  },
  dialogContent: { padding: "0 15" },
  dialogActionBtn: { color: theme.palette.primary.main }
}));

const ModalPopUp = ({ title, dataList, open, closeHandler }) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      onClose={closeHandler}
      open={open}
    >
      <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {dataList?.map((data, index) => (
          <Typography key={index}>{data}</Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} className={classes.dialogActionBtn}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalPopUp;
