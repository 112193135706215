import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./Hero.style";
import { useTranslation } from "react-i18next";

const HeroSection = ({ title, vertical, bannerImagePath }) => {
  const props = {
    verticalWidth: vertical?.length,
    bgPath: bannerImagePath
  };
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <div
      className={`${classes.heroContainer} ${classes.bannerImg} ${
        title?.length && classes.heroComponentHeight
      } ${bannerImagePath?.length && classes.heroComponentHeight2}`}
    >
      <div className={classes.heroTextWrapper}>
        <Typography className={vertical && classes.verticalName}>
          {vertical}
        </Typography>
        <Typography className={classes.heroText}>
          {title}
          {/* how much we can{" "}
          <span className={classes.heroTextDecor}>save for you!</span> */}
        </Typography>
      </div>
    </div>
  );
};

export default HeroSection;
