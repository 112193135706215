import React, { useEffect, useState } from "react";
import AddressQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/AddressQuestion.component";
import ZipPhoneNumber from "../../../components/QuestionTypes/TextInputTypeComponent/ZipPhoneNumber.component";
import NameField from "../../../components/QuestionTypes/TextInputTypeComponent/NameField.component";
import EmailSubmit from "../../../components/QuestionTypes/TextInputTypeComponent/EmailSubmit.component";
import DOB from "../../../components/QuestionTypes/DOB/Dob.component";
import HeightSelectComponent from "../../../components/QuestionTypes/HeightComponent/HeightComponent";
import SingleDropdownComponent from "../../../components/QuestionTypes/SingleDropdown/SingleDropdownComponent";
import YesNoQuestion from "../../../components/QuestionTypes/YesNoQuestion/YesNoQuestion.component";
import UtilityField from "../../../components/QuestionTypes/TextInputTypeComponent/UtilityField.component.";
import MultichoiceQuestion from "../../../components/MultichoiceQuestion/MultichoiceQuestion";
import TextFieldQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/TextField.component";
import { alertOperations } from "../../../store/alerts";

import { FindIndexValue, CompareCurrentDate } from "../../../services/utility";
import { commonSelectors, commonOperations } from "../../../store/common";
import { useDispatch } from "react-redux";
import { UTILITY_COMPANY_LIST } from "../../../services/UtilityProviders";

import useTrustedFormScript from "../TrustedFormScript";
const LifeInsuranceForm = ({
  jornayaCampaignID,
  url,
  contactFieldValues,
  contactFieldHandler,
  questions,
  setCompleted,
  questionFieldValues,
  questionFieldHandler,
  submit,
  loading,
  vertical,
  showPartnersList,
  personDataArr,
  personCount,
  handlePersonDataArr,
  handleResetPersonSection,
  sectionName,
  setSectionName
}) => {
  const [active, setActive] = useState("zip");
  const [index, setIndex] = useState(0);
  const totalQuestions = 5 + questions.length;
  const [overAllIndex, setOverAllIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const dispatch = useDispatch();

  // filters utility provider according to zip code
  const UtilitiesProvider = UTILITY_COMPANY_LIST.filter(
    (utility) => utility[contactFieldValues.state]
  );

  //sets city and state value in contact field and moves to next question
  const updateCityState = async (res) =>
    await contactFieldHandler(
      {
        ...contactFieldValues,
        city: res?.city,
        state: res?.state
      },
      setActive("question")
    );

  const getLocationDetail = () => {
    dispatch(
      commonOperations.fetchLocation(
        contactFieldValues?.zip_code,
        updateCityState
      )
    );
  };

  useEffect(() => {
    if (active !== "question" || index === 0) {
      if (!answeredQuestions.includes(active)) {
        setAnsweredQuestions(answeredQuestions.concat(active));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
    }
  }, [active]);

  useEffect(() => {
    if (
      sectionName === "life person" &&
      questions[index].id === "coverage_type"
    ) {
      const temp_medicine_detail =
        personDataArr[personDataArr.length - 1].medicine_detail;
      const removeArr = temp_medicine_detail?.length
        ? temp_medicine_detail.pop()
        : [];
      questionFieldHandler({
        ...personDataArr[personDataArr.length - 1],
        medication_name: removeArr?.medication_name?.length
          ? removeArr?.medication_name
          : "",
        dosage: removeArr?.dosage?.length ? removeArr?.dosage : "",
        frequency: removeArr?.frequency?.length ? removeArr?.frequency : "",
        comment: removeArr?.comment?.length ? removeArr?.comment : "",
        medicine_detail: temp_medicine_detail
      });

      if (removeArr?.comment?.length) {
        setPreviousQuestion(index - 1);
      } else {
        setPreviousQuestion(FindIndexValue(questions, "medical_condition"));
      }
      personDataArr?.length && personDataArr.pop();
    }
  }, [sectionName]);

  const setNextQuestion = (nextIndex) => {
    if (nextIndex >= 0 && nextIndex <= questions.length - 1) {
      if (!answeredQuestions.includes(index)) {
        setAnsweredQuestions(answeredQuestions.concat(index));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
      setIndex(nextIndex);
    } else {
      setActive("zip");
    }
    if (nextIndex === questions.length) {
      setActive("name");
    }
  };

  const setPreviousQuestion = (index) => {
    if (index >= 0 && index <= questions.length) {
      setIndex(index);
    } else {
      setActive("zip");
    }
    if (index === questions.length) {
      setActive("name");
    }
  };

  const handlePersonSectionNext = () => {
    questionFieldValues.medicine_detail.push({
      medication_name: questionFieldValues?.medication_name,
      dosage: questionFieldValues?.dosage,
      frequency: questionFieldValues?.frequency,
      comment: questionFieldValues?.comment
    });
    questionFieldValues.medication_name = "";
    questionFieldValues.dosage = "";
    questionFieldValues.frequency = "";
    questionFieldValues.comment = "";
    handlePersonDataArr([...personDataArr, questionFieldValues]);
    handleResetPersonSection();

    if (personDataArr?.length + 1 === personCount) {
      setSectionName("life requested policy");
      setNextQuestion(index + 1);
    } else {
      setNextQuestion(FindIndexValue(questions, "birth_date"));
    }
  };

  useTrustedFormScript(); // trusted form called
  const renderComponent = () => {
    switch (active) {
      case "zip":
        return (
          <ZipPhoneNumber
            dialogHandler={showPartnersList}
            configURL={url}
            dataHandler={contactFieldHandler}
            data={contactFieldValues}
            fetchLocationDetail={getLocationDetail}
          />
        );
      case "name":
        return (
          <NameField
            handleNext={() => setActive("address")}
            handlePrevious={() => setActive("question")}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
          />
        );
      case "address":
        return (
          <AddressQuestion
            handlePrevious={() => setActive("name")}
            handleNext={() => setActive("email")}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
          />
        );
      case "email":
        return (
          <EmailSubmit
            jornayaID={jornayaCampaignID}
            configURL={url}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
            handleNext={submit}
            handlePrevious={() => setActive("address")}
            loading={loading}
            vertical={vertical}
            dialogHandler={showPartnersList}
          />
        );

      case "question":
        if (questions[index]) {
          if (questions[index].type === "radio") {
            return (
              <MultichoiceQuestion
                data={questions[index]}
                vertical={vertical}
                lifePersonCount={personDataArr?.length}
                nextQuestionHandler={() => {
                  if (questions[index].id === "coverage_term") {
                    setSectionName("life current policy");
                  }
                  if (questions[index].id === "medicine_count") {
                    questionFieldValues?.medicine_count === "none"
                      ? setNextQuestion(questions, "coverage_type")
                      : setNextQuestion(index + 1);
                  } else {
                    setNextQuestion(index + 1);
                  }
                }}
                handlePrevious={() => {
                  if (questions[index].id === "insurance_company") {
                    setSectionName("life requested policy");
                  }
                  if (
                    questions[index].id === "coverage_type" &&
                    questions[index].title.includes(
                      "What coverage type would you like?"
                    )
                  ) {
                    setSectionName("life person");
                  } else {
                    setPreviousQuestion(index - 1);
                  }
                }}
                showRadio={
                  questions[index].id === "insurance_company" ? false : true
                }
                showAutoComplete={
                  questions[index].id === "insurance_company" ? true : false
                }
                questionFieldValues={questionFieldValues}
                dataHandler={questionFieldHandler}
              />
            );
          } else if (questions[index].type === "yesno") {
            return (
              <YesNoQuestion
                vertical={vertical}
                lifePersonCount={personDataArr?.length}
                handleYes={() => setNextQuestion(index + 1)}
                handleNo={() => setNextQuestion(index + 1)}
                handlePrevious={() => setPreviousQuestion(index - 1)}
                question={questions[index]}
                questionFieldValues={questionFieldValues}
                dataHandler={questionFieldHandler}
              />
            );
          } else if (questions[index].type === "TEXT_INPUT")
            if (questions[index].id !== "utility_provider") {
              return (
                <TextFieldQuestion
                  vertical={vertical}
                  lifePersonCount={personDataArr?.length}
                  medicineCount={questionFieldValues?.medicine_detail?.length}
                  data={questions[index]}
                  questionFieldValues={questionFieldValues}
                  handleNext={() => {
                    if (questions[index].id === "comment") {
                      if (
                        questionFieldValues?.medicine_count !== "none" &&
                        questionFieldValues?.medicine_detail?.length + 1 <=
                          questionFieldValues?.medicine_count
                      ) {
                        questionFieldHandler({
                          ...questionFieldValues,
                          medicine_detail: [
                            ...questionFieldValues.medicine_detail,
                            {
                              medication_name:
                                questionFieldValues.medication_name,
                              dosage: questionFieldValues.dosage,
                              frequency: questionFieldValues.frequency,
                              comment: questionFieldValues.comment
                            }
                          ],
                          medication_name: "",
                          dosage: "",
                          frequency: "",
                          comment: ""
                        });
                        questionFieldValues?.medicine_count !== "none" &&
                        questionFieldValues?.medicine_detail?.length + 1 ===
                          questionFieldValues?.medicine_count
                          ? handlePersonSectionNext()
                          : setNextQuestion(
                              FindIndexValue(questions, "medication_name")
                            );
                      }
                    }
                    questions[index].id !== "comment" &&
                      setNextQuestion(index + 1);
                  }}
                  handlePrevious={() => {
                    if (
                      questions[index].id === "medication_name" &&
                      questionFieldValues?.medicine_detail?.length > 0
                    ) {
                      const tempArr = [...questionFieldValues?.medicine_detail];
                      const removedArr = tempArr?.length ? tempArr.pop() : [];
                      questionFieldHandler({
                        ...questionFieldValues,
                        medication_name: removedArr.medication_name,
                        dosage: removedArr.dosage,
                        frequency: removedArr.frequency,
                        comment: removedArr.comment,
                        medicine_detail: tempArr
                      });

                      setPreviousQuestion(FindIndexValue(questions, "comment"));
                    } else {
                      setPreviousQuestion(index - 1);
                    }
                  }}
                  dataHandler={questionFieldHandler}
                />
              );
            } else {
              return (
                <UtilityField
                  data={questions[index]}
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setPreviousQuestion(index - 1)}
                  dataHandler={questionFieldHandler}
                  questionFieldValues={questionFieldValues}
                  utilityOptions={
                    Boolean(UtilitiesProvider?.length)
                      ? UtilitiesProvider[0][contactFieldValues.state]
                      : []
                  }
                />
              );
            }
          else if (questions[index].type === "TEXT_INPUT_2") {
            if (questions[index].id === "spouse_first_name") {
              return (
                <NameField
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  question={questions[index]}
                />
              );
            }
          } else if (questions[index].type === "dropdown") {
            if (
              questions[index].id === "expiration_date" ||
              questions[index].id === "insured_since" ||
              questions[index].id === "birth_date"
            ) {
              return (
                <DOB
                  lifePersonCount={personDataArr?.length}
                  showHint={true}
                  handlePrevious={() => {
                    if (questions[index].id === "birth_date") {
                      if (personDataArr?.length != 0) {
                        const removedPersonArr = personDataArr.pop();

                        const temp_medicine_detail =
                          removedPersonArr.medicine_detail;
                        const removedMedicineArr = temp_medicine_detail?.length
                          ? temp_medicine_detail.pop()
                          : [];
                        questionFieldHandler({
                          ...removedPersonArr,
                          medication_name: removedMedicineArr?.medication_name
                            ?.length
                            ? removedMedicineArr?.medication_name
                            : "",
                          dosage: removedMedicineArr?.dosage?.length
                            ? removedMedicineArr?.dosage
                            : "",
                          frequency: removedMedicineArr?.frequency?.length
                            ? removedMedicineArr?.frequency
                            : "",
                          comment: removedMedicineArr?.comment?.length
                            ? removedMedicineArr?.comment
                            : "",
                          medicine_detail: temp_medicine_detail
                        });

                        if (removedMedicineArr?.comment?.length) {
                          setPreviousQuestion(
                            FindIndexValue(questions, "comment")
                          );
                        } else {
                          setPreviousQuestion(
                            FindIndexValue(questions, "medical_condition")
                          );
                        }
                      } else {
                        setSectionName("life person count");
                        setPreviousQuestion(index - 1);
                      }
                    } else {
                      setPreviousQuestion(index - 1);
                    }
                  }}
                  handleNext={() => {
                    if (questions[index].id === "insured_since") {
                      if (
                        !CompareCurrentDate(
                          `${
                            questionFieldValues[
                              `${[questions[index]?.id]}_year_temp`
                            ]
                          }-${
                            questionFieldValues[
                              `${[questions[index]?.id]}_month_temp`
                            ].id
                          }-${
                            questionFieldValues[
                              `${[questions[index]?.id]}_day_temp`
                            ]
                          }`
                        )
                      ) {
                        return dispatch(
                          alertOperations.addAlert(
                            "Please check the given date.",
                            "error"
                          )
                        );
                      }
                    }
                    if (questions[index].id === "expiration_date") {
                      if (
                        CompareCurrentDate(
                          `${
                            questionFieldValues[
                              `${[questions[index]?.id]}_year_temp`
                            ]
                          }-${
                            questionFieldValues[
                              `${[questions[index]?.id]}_month_temp`
                            ].id
                          }-${
                            questionFieldValues[
                              `${[questions[index]?.id]}_day_temp`
                            ]
                          }`
                        )
                      ) {
                        return dispatch(
                          alertOperations.addAlert(
                            "Ensure this date is future date.",
                            "error"
                          )
                        );
                      }
                    }
                    questionFieldHandler({
                      ...questionFieldValues,
                      [questions[index].id]: `${
                        questionFieldValues[
                          `${[questions[index]?.id]}_year_temp`
                        ]
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_month_temp`
                        ].id
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_day_temp`
                        ]
                      }`
                    });

                    setNextQuestion(index + 1);
                  }}
                  question={questions[index]}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  vertical={vertical}
                />
              );
            }
            if (questions[index].id === "height") {
              return (
                <HeightSelectComponent
                  vertical={vertical}
                  lifePersonCount={personDataArr?.length}
                  showHint={false}
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  question={questions[index]}
                />
              );
            }
            if (
              questions[index].id === "person_count" ||
              questions[index].id === "medicine_count"
            ) {
              return (
                <SingleDropdownComponent
                  showHint={false}
                  vertical={vertical}
                  lifePersonCount={personDataArr?.length}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  handleNext={() => {
                    if (questions[index].id === "medicine_count") {
                      if (questionFieldValues?.medicine_count === "none") {
                        handlePersonDataArr([
                          ...personDataArr,
                          questionFieldValues
                        ]);
                        handleResetPersonSection();
                        if (personDataArr?.length + 1 === personCount) {
                          setSectionName("life requested policy");
                          setNextQuestion(
                            FindIndexValue(questions, "coverage_type")
                          );
                        } else {
                          setNextQuestion(
                            FindIndexValue(questions, "birth_date")
                          );
                        }
                      } else {
                        setNextQuestion(index + 1);
                      }
                    }
                    if (questions[index].id === "person_count") {
                      setSectionName("life person");
                      setNextQuestion(index + 1);
                    }
                  }}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  question={questions[index]}
                />
              );
            }
          }
        }
      default:
        return <> </>;
    }
  };
  return <>{renderComponent()}</>;
};

export default LifeInsuranceForm;
