export const INSURANCE_LINK =
  "https://files.jangl.com/partners-list/insurance.html";
export const START_YEAR = "1922";
export const CURRENT_YEAR = new Date().getFullYear();
export const MAX_MAKE_ID = 603;
export const INCHES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const FEET = [3, 4, 5, 6, 7];
export const NUMBER_COUNT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const Months = [
  {
    id: "01",
    value: "January"
  },
  {
    id: "02",
    value: "February"
  },
  {
    id: "03",
    value: "March"
  },
  {
    id: "04",
    value: "April"
  },
  {
    id: "05",
    value: "May"
  },
  {
    id: "06",
    value: "June"
  },
  {
    id: "07",
    value: "July"
  },
  {
    id: "08",
    value: "August"
  },
  {
    id: "09",
    value: "September"
  },
  {
    id: "10",
    value: "October"
  },
  {
    id: "11",
    value: "November"
  },
  {
    id: "12",
    value: "December"
  }
];
export const INSURANCE_COMPANY = [
  "21st Century Insurance",
  "5 Star Auto Protection",
  "AA Auto Protect",
  "AAA Auto Warranty",
  "AAA Insurance Co.",
  "AARP",
  "Acceptance Insurance Agency of Tennessee, Inc",
  "Accuquote",
  "Advanced Vehicle Protection Center",
  "Aegis First",
  "Aegon US Holding Corp.",
  "Aetna",
  "Aflac",
  "Agent Insider",
  "Agentra Healthcare",
  "AHCP",
  "AIG Direct",
  "AIS",
  "Aliera Healthcare",
  "Alliance",
  "Allied",
  "Allied Insurance Partners",
  "Allina Health",
  "Allstate",
  "Allstate Insurance",
  "ALLWEB",
  "AllWebLeads",
  "Alpine Digital Group, Inc.",
  "America Auto Care",
  "America's Health Care Plan",
  "America's Insurance",
  "American Automotive Alliance, LLC",
  "American Family Insurance",
  "American Fidelity Assurance",
  "American Fidelity Corp.",
  "American Financial",
  "American Life & Health",
  "American National Insurance",
  "American National Insurance Company",
  "American Republic",
  "American Republic Insurance Co.",
  "American Select Partners, LLC",
  "American Service Insurance Agency",
  "American Workers Insurance Services",
  "Amerigroup",
  "Amica Insurance",
  "Angelic Marketing Group",
  "Answer Financial",
  "Anthem BCBS",
  "Anthem Blue Cross and Blue Shield",
  "Aon Corp.",
  "Apollo Interactive",
  "Ardent Health Services",
  "Assurance IQ, Inc.",
  "Assurant",
  "Astonish",
  "Auto Protection 1",
  "Auto Protection Club",
  "Auto Repair Protection Services",
  "Auto Service Center",
  "AutoCarenow.com",
  "Avenge Digital",
  "AvMed Inc.",
  "Bankers Life and Casualty",
  "Bankrate",
  "Bankrate Insurance",
  "Bantam Connect",
  "BE Marketing Solutions Inc.",
  "Blue Cross and Blue Shield Association",
  "Blue Cross Blue Shield of Nebraska",
  "Blue Cross/Blue Shield Association Companies",
  "Bolt",
  "Brightway",
  "Caitlin Insurance Co.",
  "California Insurance Specialist",
  "Capital Auto Protection",
  "Capital District Physicians Health Plan",
  "Capital Health Advisors Inc.",
  "Carchex",
  "Caresource",
  "CareZone Insurance Services",
  "CarGuard Administration",
  "Carle Clinic Association",
  "CarShield",
  "CDX Consultants",
  "Centene Corp.",
  "Centene Corporation",
  "Certainty Auto Protection",
  "Choice Direct",
  "Cigna",
  "Clearlink",
  "CNA Insurance",
  "CO Farm Bureau",
  "Colonial Life & Accident Insurance Company",
  "Colorado Bankers",
  "CommercialInsurance.net",
  "CommunityCare",
  "Compare Insurance Quotes",
  "Confie Seguros",
  "Connect Insurance Brands",
  "Connect Plus",
  "Conseco",
  "Conseco Life",
  "Consolidated USA Insurance",
  "Consumer United",
  "Contactability",
  "Conventry",
  "Cost-U-Less_Insurance",
  "Cotton States Insurance",
  "Country Companies",
  "Country Financial",
  "Coventry Health Care",
  "Coverage One Insurance Group LLC",
  "Coverage One Insurance Group, LLC.",
  "Covered Insurance Solutions Inc.",
  "CoverHound",
  "Covida",
  "CS Marketing",
  "CSE",
  "Datalot",
  "Dean Health",
  "Delphi Financial",
  "Delta Auto Assurance",
  "Delta Auto Protect",
  "Direct General",
  "DirectMail.com",
  "Drivers Protection, LLC",
  "DTRIC",
  "e-telequote",
  "Efinancial",
  "Egis Auto",
  "eHealth",
  "Electric",
  "Elephant",
  "EmblemHealth",
  "Empire Consumer Services",
  "Empire Health Consultants LLC",
  "Endurance Warranty Servcies",
  "Epiq Insurance",
  "Erie Insurance Company",
  "ESG Insurance LLC",
  "Esurance",
  "EverQuote",
  "Everquote's Marketing Partners",
  "Excel Impact",
  "Fallon Community Health Plan",
  "Family First",
  "Farm Bureau",
  "Farmers Insurance",
  "Fidelity Life",
  "Fiorella",
  "First Mutual Insurance Group (FMIG)",
  "First Option Health Group",
  "Florida Plan Advisors",
  "Foresters",
  "Fortis",
  "Freeway Insurance",
  "GAINSCO Auto Insurance Agency, Inc.",
  "GEICO",
  "Geisinger Insurance",
  "Generation Life",
  "Genworth Financial",
  "GetAuto",
  "Getinsured.com",
  "GHI",
  "GMAC Insurance",
  "GoHealth Insurance",
  "GoHealth/Norvax",
  "Golden Rule Insurance Co.",
  "Golden Rule Insurance Company",
  "GoMediGap",
  "Great American",
  "Great Way",
  "Great West",
  "GreatAmerican",
  "Group Health Cooperative",
  "Group Health Incorporated",
  "Guardian Life",
  "Guide One",
  "Hanover",
  "Hartford",
  "Hartford Fire & Casualty",
  "Harvard Commonwealth Health Plan",
  "Hawaii Medical Services Association",
  "HCC Insurance holdings",
  "Health and Life Plans of America",
  "Health Benefit Group",
  "Health Benefits Center",
  "Health Benefits One",
  "Health Care Service Corp.",
  "Health Choice One",
  "Health Enrollment Center",
  "Health Insurance Innovations (HII)",
  "Health Insurance Services",
  "Health Markets",
  "Health Net",
  "Health Now New York Inc.",
  "Health Option One",
  "Health Partners of Philadelphia",
  "Health Partners of Philadelphia Inc.",
  "Health Plan One",
  "Health Plan One, LLC",
  "Health Plans 4 Less",
  "Health Team One, LLC",
  "Health Works Agency LLC",
  "Healthcare Advisors",
  "Healthcare Solutions Team",
  "Healthcare, Inc",
  "HealthCompare",
  "HealtheDeals",
  "Healthinsurance.net",
  "HealthMarkets",
  "HealthNet",
  "HealthPlanOne, LLC",
  "HealthPlus of Michigan",
  "HealthSpring",
  "Henry Ford Health System",
  "High Quality Vehicle Protection",
  "Highmark",
  "HII - Starr Insurance",
  "HIP Insurance",
  "Hiscox",
  "Homeinsurance.com",
  "Hometown Quotes",
  "Horace Mann",
  "Hospital Services Association of NEPA",
  "HPO",
  "Humana",
  "Ideal Concepts",
  "Identify Health IQ Insurance Services",
  "IHC Group",
  "IHC Health Plans",
  "IMO",
  "Imperial Health Group",
  "Imperial Health Group LLC",
  "Independence Blue Cross",
  "Independent Carriers",
  "Independent Health Association",
  "Independent Insurance Consultants",
  "Infinity Insurance",
  "Infinix Media",
  "Innovate Financial Group",
  "Innovation Direct Group",
  "Insphere",
  "Insphere Insurance Solutions",
  "InsuraMatch",
  "Insurance Health Hotline",
  "Insurance Services",
  "Insurance Solutions LLC",
  "insuranceQuotes",
  "InsureMe",
  "InsureOne",
  "Insurita LLC",
  "Insweb",
  "Integrity Vehicle Services, Inc",
  "IWebQuotes",
  "John Hancock",
  "Kaiser",
  "Kaiser Permanente",
  "Kaleida Health",
  "Kanopy",
  "Katch LLC",
  "Kemper",
  "Kin Insurance, Inc.",
  "Komparison",
  "LendingTree",
  "Liberty Automobile Protection",
  "Liberty Mutual Insurance Company",
  "Liberty National",
  "Liberty National Life Insurance Company",
  "Lifetime Healthcare",
  "Lifetime Medicare Advisors",
  "LifeWise Health Plan of Oregon",
  "Lighthouse",
  "Lighthouse Insurance Group LLC",
  "Lincoln National",
  "LoanBright",
  "Louisiana Health Services",
  "M Plan Inc.",
  "Managed Health Inc.",
  "MAPFRE",
  "Mass Nexus",
  "MCGP International",
  "Media Alpha",
  "Medical Card System Inc.",
  "Medical Mutual of Ohio",
  "Medicare Group USA LLC",
  "Medicare Solutions",
  "MEGA Life and Health Insurance",
  "Mercury",
  "Metlife",
  "Metropolitan Insurance Co.",
  "Military.com",
  "Mississippi Insurance",
  "MMM Healthcare Inc.",
  "Molina Healthcare",
  "Molina Healthcare Inc.",
  "Moss",
  "Mutual of Omaha",
  "MVP",
  "My Health Advisors",
  "Myfinancebuzz",
  "National Auto Protection Corp.",
  "National Better Living Association",
  "National Brokers of America",
  "National General Insurance Company (NGIC)",
  "National Plan Advisors",
  "National Repair Solutions",
  "National Vehicle Protection Services, Inc.",
  "Nations Health Group Guide One Insurance",
  "Nationwide",
  "Neighborhood Health Plan Inc.",
  "NetQuote",
  "New York Life",
  "NewQuest",
  "Next Gen",
  "Nexus Corporation",
  "Noridian Mutual Insurance Company",
  "Northwestern Mutual",
  "Omega Auto Care",
  "Onviant Insurance Agency Inc.",
  "Open Market Quotes",
  "OpenJar",
  "Overflow Works",
  "Pacific Benefits Group",
  "Pacific Benefits Group Northwest",
  "Palmer Administrative Services Inc.",
  "Parasol Agent Network",
  "Parasol Agents Network",
  "Peak Protection Group",
  "Pemco",
  "Physicians Health Plan",
  "Physicians Mutual",
  "Plasmid Direct",
  "Plasmid Media, LLC",
  "Platinum Health",
  "Plymouth Rock",
  "PM Group Loans",
  "Precise",
  "Precise Leads",
  "Preferred Care",
  "Premera Blue Cross",
  "Premier Health Associates Inc",
  "Premier Health Solutions",
  "Presbyterian Healthcare Services",
  "Principal Financial",
  "Principal Financial Group",
  "Priority Insurance",
  "Prodigy Insurance Associates LLC",
  "Progressive Insurance",
  "Pronto California Agency",
  "Protech Vehicle Services LLC",
  "Protect Your Home",
  "Protecta America",
  "ProtectMyCar",
  "Providence Health Plan",
  "Prudential of America",
  "PURE",
  "Quick Quote",
  "Quinstreet",
  "Quote Whiz LLC",
  "Quote Wizard, LLC.",
  "QuoteHound INC",
  "QuoteWhiz",
  "QuoteWizard",
  "QW Insurance Solutions, LLC.",
  "Rank Media",
  "Real-Comp Data and Marketing",
  "Red Auto Protection",
  "Renaissance Health Services Corp.",
  "RSA",
  "SafeAuto",
  "SAFECO",
  "Safeguard Insurance Market",
  "Safeway Insurance",
  "Sagicor",
  "Sales Data Pro",
  "Scan Health Plan",
  "Secure Car Care",
  "Secure Horizons",
  "Security Health Plan of Wisconsin Inc.",
  "Select Quote",
  "SelectHealth",
  "SelectQuote",
  "SelectQuote A&H",
  "Senior Market Quotes",
  "Sentara Health Management",
  "Sentry Insurance Company",
  "Shelter Insurance",
  "Shelter Insurance Company",
  "Sierra Health Services",
  "Simple Health",
  "Simple Health Plans Inc.",
  "Simple Insurance Leads",
  "Smart Match Insurance Agency",
  "SolidQuote LLC",
  "Spectrum",
  "Spectrum Health",
  "Spring Insurance Solutions",
  "Stancorp Financial",
  "State Auto",
  "State Farm",
  "Sterling Senior Health",
  "Stone Tapert",
  "Stratum Media DMCC",
  "StringBit inc",
  "Summit direct mail",
  "Sun Life Assurance Company",
  "Synergy Insurance",
  "Tech Insurance",
  "The General",
  "The Hartford",
  "The Hartford AARP",
  "The IHC Group",
  "The Regence Group",
  "This or That Media dba HometownQuotes",
  "Three Rivers Holdings",
  "Thrivent Financial for Lutherans",
  "TIAA-CREF",
  "Titan",
  "Torchlight",
  "Traders Insurance",
  "Transamerica",
  "Travelers Insurance",
  "Tricare",
  "Triple-S Inc.",
  "TriState",
  "True Auto",
  "TrueChoice Insurance Services",
  "Tryton",
  "TSC Direct Inc.",
  "Tufts Associates HMO Inc.",
  "TWG",
  "TZ Insurance Solutions",
  "TZ Insurance Solutions, LLC",
  "Ucare",
  "Underground Elephant",
  "Unified Rightmed",
  "United Enrollment Services, LLC",
  "United Medicare",
  "United Medicare Advisors",
  "United Repair Programs",
  "United Teacher Associates Ins Co.",
  "UnitedHealth Group",
  "UnitedHealthcare",
  "UnitedHealthOne",
  "Unitrin",
  "Unitrin Direct",
  "Universal American",
  "Universal American Corporation",
  "Universal American Financial Corp.",
  "University Health Care Inc.",
  "UnumProvident Corp.",
  "UPMC Health System",
  "US Health Consultants",
  "US Health Group (Freedom Life)",
  "USAA.",
  "USDS (US Dealer Services)",
  "USHealth Advisors",
  "USHEALTH Group",
  "USinsuranceOnline",
  "USNEWS",
  "Vanguard Health Ventures",
  "Vehicle Assurance Co",
  "Velapoint",
  "VitalOne Health Plans Direct, LLC.",
  "Warranty Agents",
  "Warranty Direct",
  "WEA Inc.",
  "WellCare Health Plans",
  "WellPoint",
  "Western & Southern Life",
  "Western Mutual",
  "World Mail Direct USA",
  "XLHealth",
  "Your Lowest Quote",
  "Zurich"
];

export const SOLAR_PARTNERS_LIST = [
  "1st Light Energy Inc",
  "A and R Solar SPC",
  "Accord Power",
  "Advanced Energy Solutions dba AES Solar",
  "Alder Energy Systems",
  "Alternative Energy Southeast, Inc.",
  "American Design & Build, Ltd.",
  "American Solar & Roofing",
  "Aptos Solar Technology LLC",
  "Best Energy Power",
  "BHInc",
  "Big Country Solar Solutions, LLC",
  "BrightEye Solar",
  "BrightView Landscapes, LLC",
  "Brooklyn Solarworks",
  "Cape Fear Solar Systems, LLC",
  "Carolina Mountain Solar",
  "Catamount Solar",
  "Cenergy Power",
  "Central South Carpenters Regional Council",
  "City Service DataCom, Inc.",
  "CleanTech Energy Solutions, Inc.",
  "Coastal Solar Power Company, LLC",
  "Cosmic Solar, Inc.",
  "Current Electric Company",
  "Directional Services Inc.",
  "Earthlight Technologies",
  "Efficient Energy of Tennessee, LLC",
  "EightTwenty",
  "EmPower Solar",
  "EnergyLink",
  "Ensolar",
  "Equix Infrastructure, Inc.",
  "Everlight Solar",
  "Family Solar Company",
  "Filtrexx International",
  "Freedom Forever",
  "Freedom Solar LLC",
  "Genforce Power Inc.",
  "Geoscape Solar",
  "Go Smart Solar",
  "Go Solar Power",
  "Goldstar Solar Inc.",
  "Good Faith Energy",
  "Grasshopper Solar Corporation",
  "GrayWolf Industrial",
  "Green Lantern Solar",
  "Green Seal Environmental",
  "Green Street Power Partners, LLC",
  "Gridsmart Solar, LLC",
  "GroundWork Renewables, Inc.",
  "Halo Energy Partners",
  "Hannah Solar Government Services LLC",
  "Harvest Solar",
  "Headline Solar",
  "Helios Energy LLC",
  "Hudson Renewables",
  "Idahome Solar",
  "Ideal Energy, LLC",
  "Insource Renewables",
  "Ipsun Solar",
  "JSunPV LLC",
  "Key Solar LLC",
  "Landscape Design of Goldsboro, Inc.",
  "Leeward Renewable Energy",
  "LightWave Solar Electric LLC",
  "Longhorn Solar",
  "M.L. Schmitt Electrical",
  "MCF Solar LLC",
  "Missouri Solar Applications, LLC",
  "Namaste Solar",
  "National Roofing Partners",
  "Net Zero Solar",
  "New Farm Solar",
  "Newport Solar",
  "NJ Solar Power, LLC",
  "NM Solar Group, Inc",
  "NY State Solar",
  "Onsite Companies",
  "Optimus Solar, LLC",
  "Orbital Solar Services",
  "Pearlwind, LLC",
  "Peed Bros., Inc.",
  "Peg Gen Holdings",
  "Pfister Energy",
  "Pinnacle Roofing Professionals, LLC",
  "Pivot Energy",
  "PosiGen, LLC",
  "Power Home Remodeling Group",
  "Power Home Solar LLC",
  "ProSolar Group, LLC",
  "Puget Sound Solar LLC",
  "PV Squared",
  "Radical Solar Energy LLC",
  "RER Energy Group",
  "Revolution Solar",
  "Rising Sun Solar Electric LLC",
  "Roof Power Solar",
  "Ryan Incorporated Central",
  "Sentry Electrical Group, Inc",
  "SGC Power",
  "Shine Development Partners",
  "Sigora Solar",
  "Simpleray",
  "SimpleRay, LLC",
  "Sol-Up USA, LLC",
  "Solar Energy Solutions",
  "Solar Fields",
  "Solar Impact, Inc.",
  "Solar Renewable Energy, LLC",
  "Solar Topps LLC",
  "Solar Wave Energy, Inc.",
  "Solaria Energy Solutions",
  "Solariant Capital, LLC",
  "Solaris Energy",
  "SolarMax Technology, Inc.",
  "solarXperts",
  "Solect Energy Development, LLC",
  "South Mountain Company",
  "Southern Coker Power LLC",
  "Southern View Energy",
  "SSE Enterprises LLC",
  "Standard Solar",
  "StraightUp Solar",
  "Sukut Construction, LLC",
  "Summit Energy Group",
  "Sun Wind Solutions",
  "Sun-Wind Solutions, LLC",
  "SunChase Power LLC",
  "SunConnect Corporation",
  "SunCycle Renewable Energy",
  "Sunnova Energy Corporation",
  "Sunrise Power Solutions",
  "Sunrun",
  "SunVest Solar, Inc.",
  "SUNworks Inc.",
  "Technicians for Sustainability LLC",
  "TerraSol Energies, Inc.",
  "Tesla",
  "The Clary Group LLC",
  "Timberlyn, Inc.",
  "TM Montante Solar",
  "TMI Energy Solutions",
  "Trinity Solar",
  "Unbound Solar",
  "United Home Solutions",
  "USA Energy Savings Program LLC",
  "Valley Interior Systems",
  "Valley Solar LLC",
  "Vanguard Energy Partners, LLC",
  "Vanguard Solar Services LLC",
  "Vision Solar LLC",
  "Wanzek Construction",
  "Watson Electrical Construction",
  "Western Earthworks, LLC"
];

export const MEDICARE_PARTNERS_LIST = [
  "Medicare Partner 1",
  "Medicare Partner 2",
  "Medicare Partner 3"
];
export const HOME_PARTNERS_LIST = [
  "Home Partner 1",
  "Home Partner 2",
  "Home Partner 3"
];
export const LIFE_INSURANCE_PARTNERS_LIST = [
  "Life insurance Partner 1",
  "Life insurance Partner 2",
  "Life insurance Partner 3"
];

export const HEALTH_INSURANCE_PARTNERS_LIST = [
  "Health insurance Partner 1",
  "Health insurance Partner 2",
  "Health insurance Partner 3"
];
