import React from "react";
import Radio from "@material-ui/core/Radio";
import useStyles from "./CustomRadio.styles";
const CustomRadio = (props) => {
  const classes = useStyles();
  return (
    <Radio
      color="primary"
      checkedIcon={
        <span className={`${classes.radioIcon} ${classes.checkedRadioIcon}`} />
      }
      icon={<span className={classes.radioIcon} />}
      {...props}
    />
  );
};
export default CustomRadio;