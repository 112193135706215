import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: "1.5rem",
    margin: "15px 10px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.25rem"
    }
  },
  spaceTop: { marginTop: 15 },
  spaceRight: { marginRight: 15 },
  styledIcon: {
    fontSize: "18px",
    color: theme.palette.primary.darkText,
    opacity: 1
  },
  btn: {
    fontSize: "18px",
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.booleanButton,
    border: "1px solid #E4E4E4",
    borderRadius: "5px",
    boxShadow: "none",
    height: "45px",
    width: "220px",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
      height: "40px",
      width: "84px"
    }
  }
}));
