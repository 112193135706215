// requested policy section
export const initialLifeRequestedPolicy = {
  coverage_type: null,
  coverage_amount: null,
  coverage_term: null
};

// current policy section
export const initialLifeCurrentPolicy = {
  insurance_company: null,
  expiration_date: null,
  insured_since: null,
  coverage_type: null
};

// person section
export const initialLifePerson = {
  birth_date: null,
  gender: null,
  height: null,
  weight: null,
  residence_type: null,
  months_at_current_residence: null,
  marital_status: null,
  education: null,
  occupation: null,
  relationship_to_applicant: null,
  student: null,
  tobacco: null,
  expectant_parent: null,
  relative_heart: null,
  relative_cancer: null,
  hospitalized: null,
  ongoing_medical_treatment: null,
  dui: null,
  previously_denied: null,
  hazard_pilot: null,
  hazard_felony: null,
  hazard_other: null,
  hazardous_activity: null,
  medical_condition: null,
  medicine_count: null,
  medication_name: null,
  dosage: null,
  frequency: null,
  comment: null,
  medicine_detail: []
};
