import { combineReducers } from "redux";
import * as types from "./types";

const initialState = {
  vehicleMake: []
};

/**
 * Vertical reducer
 * @param {*} state
 * @param {*} action
 */
const vehicleMakeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_VEHICLE_MAKE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const vehicleModelReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_VEHICLE_MODEL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  vehiclesMake: vehicleMakeReducer,
  vehiclesModel: vehicleModelReducer
});

export default reducer;
