import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./HeightStyle";
import { Selector } from "./Selector";
import { useTranslation } from "react-i18next";
import ToolTipPopUp from "../../ToolTip/ToolTipPopUp.component";
import { NavigationButton } from "../../CustomButton/Button";
import { ChangeTitle } from "../../../services/utility";
const HeightComponent = ({
  handleNext,
  handlePrevious,
  showHint,
  dataHandler,
  data,
  question,
  lifePersonCount,
  healthChildCount,
  vertical
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleOnChangeDate = ({ name, value }) => {
    name === "inches" && dataHandler({ ...data, height_inches_temp: value });
    name === "feet" && dataHandler({ ...data, height_feet_temp: value });
  };
  const formatTitle = () => {
    if (vertical === "life_insurance") {
      return ChangeTitle(question.title, lifePersonCount, "person");
    } else if (vertical === "health_insurance") {
      return ChangeTitle(question.title, healthChildCount, "child");
    } else {
      return question.title;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.toolTipWrapper}>
          <Typography variant="h5" className={classes.title}>
            {formatTitle()}
          </Typography>
          {showHint && (
            <ToolTipPopUp hint={"Hint description about the question"} />
          )}
        </div>
        <Selector heightData={data} handleOnChange={handleOnChangeDate} />
      </div>
      <NavigationButton
        disabledValue={Boolean(data?.height_feet_temp) ? false : true}
        onClickPrev={handlePrevious}
        onClickNext={handleNext}
      />
    </>
  );
};

export default HeightComponent;
