import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./Dob.style";
import { DateSelector } from "./DateSelector";
import { useTranslation } from "react-i18next";
import { START_YEAR, CURRENT_YEAR as current_year } from "../../../Constants";
import ToolTipPopUp from "../../ToolTip/ToolTipPopUp.component";
import { NavigationButton } from "../../CustomButton/Button";
import {
  FetchAboveAgeLimitYears,
  FormatQuestion,
  FetchFutureYears,
  FormatHomeClaimSectionTitle,
  FetchPastYears,
  ChangeTitle
} from "../../../services/utility";

const Dob = ({
  handleNext,
  handlePrevious,
  showHint,
  dataHandler,
  data,
  question,
  addedDriverCount,
  vertical,
  homeClaimCount,
  lifePersonCount,
  healthChildCount
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const yearsOption = () => {
    if (question.id === "policy_expiration_date") {
      return FetchFutureYears(10);
    } else if (question.id === "claim_date") {
      const yearValue = homeClaimCount?.length
        ? homeClaimCount[homeClaimCount.length - 1]?.claim_date_year_temp
        : 0;
      return FetchPastYears(yearValue);
    } else if (vertical == "home" && question.id === "expiration_date") {
      return FetchFutureYears(10);
    } else if (vertical == "home" && question.id === "insured_since") {
      return FetchPastYears();
    } else if (
      (vertical == "life_insurance" || vertical == "health_insurance") &&
      question.id === "insured_since"
    ) {
      return FetchPastYears(current_year - 20);
    } else if (
      (vertical == "life_insurance" || vertical == "health_insurance") &&
      question.id === "expiration_date"
    ) {
      return FetchFutureYears(5);
    } else if (vertical == "life_insurance" && question.id === "birth_date") {
      return FetchAboveAgeLimitYears(START_YEAR, 16);
    } else {
      return FetchAboveAgeLimitYears(START_YEAR, 18);
    }
  };

  const handleOnChangeDate = ({ name, value }) => {
    name === "day" &&
      dataHandler({ ...data, [`${question?.id}_day_temp`]: value });
    name === "month" &&
      dataHandler({ ...data, [`${question?.id}_month_temp`]: value });
    name === "year" &&
      dataHandler({ ...data, [`${question?.id}_year_temp`]: value });
  };

  const handleTitleFormat = () => {
    if (vertical === "auto_insurance") {
      return FormatQuestion(t, question.id, question.title, addedDriverCount);
    } else if (vertical === "home") {
      return FormatHomeClaimSectionTitle(
        question.title,
        homeClaimCount?.length
      );
    } else if (vertical === "life_insurance") {
      return ChangeTitle(question.title, lifePersonCount, "person");
    } else if (vertical === "health_insurance") {
      return ChangeTitle(question.title, healthChildCount, "child");
    } else {
      return question.title;
    }
  };
  return (
    <>
      <div className={classes.container}>
        <Typography className={`${classes.title} ${classes.spaceBottom}`}>
          {handleTitleFormat()}&nbsp;
          {showHint && (
            <ToolTipPopUp hint={"Hint description about the question"} />
          )}
        </Typography>
        <DateSelector
          questionType={question.id}
          startYear={START_YEAR}
          handleOnChange={handleOnChangeDate}
          day={data[`${question?.id}_day_temp`]}
          month={data[`${question?.id}_month_temp`]}
          year={data[`${question?.id}_year_temp`]}
          years={yearsOption()}
        />
      </div>
      <NavigationButton
        disabledValue={
          Boolean(data[`${question?.id}_day_temp`]) &&
          Boolean(data[`${question?.id}_month_temp`]) &&
          Boolean(data[`${question?.id}_year_temp`])
            ? false
            : true
        }
        onClickPrev={handlePrevious}
        onClickNext={handleNext}
      />
    </>
  );
};

export default Dob;
