import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import { NavigationButton } from "../../CustomButton/Button";
import useJornayaScript from "../../../container/Pages/JornayaTokenGenerationScript";
const EmailField = ({
  jornayaID,
  configURL,
  handleNext,
  data,
  dataHandler,
  handlePrevious,
  loading,
  vertical,
  dialogHandler
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { email } = data;
  const [error, setError] = useState({
    email: false
  });
  const [submitLabel, setSubmitLabel] = useState(false);
  const verifyEmail = () => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(email)) {
      setSubmitLabel(true);
      //handleNext();
    } else {
      setError({ email: true });
    }
  };

  useJornayaScript(jornayaID);
  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {t("EMAIL_FIELD_TITLE")}
      </Typography>
      <TextField
        error={error.email}
        id="outlined-basic"
        placeholder="Email Address"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={classes.spaceBottom}
        value={email}
        helperText={error.email && "Invalid email."}
        onChange={(e) => {
          dataHandler({ ...data, email: e.target.value });
          setError({ ...error, email: false });
        }}
      />
      <input
        type="hidden"
        name="xxTrustedFormCertUrl"
        id="xxTrustedFormCertUrl_0"
        value="https://cert.trustedform.com/454a35b802f3e7b63ffabb4efedb7c6ebe67886c"
      />
      <input
        type="hidden"
        name="xxTrustedFormPingUrl"
        id="xxTrustedFormPingUrl_0"
        value="https://ping.trustedform.com/0.HJDqajg8vVF20hwiDBJ92kct5idepgBM4vHylmh43kShfWSZKwm8HI37KqPToi0y3dhTIQ3N.uOjUbzs7aKNUWivkGh7I9Q.50hgmcLFsDabNx1wtZZPdQ"
      />

      <label>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        <div className={classes.emailParaContainer} id="legalText">
          {vertical === "medicare"
            ? MedicareLegalText(t, configURL, dialogHandler, classes)
            : LegalText(t, configURL, dialogHandler, classes)}
        </div>
      </label>

      <NavigationButton
        disabledValue={!email?.length}
        onClickPrev={handlePrevious}
        onClickNext={verifyEmail}
        nextLabel={"SUBMIT"}
        loading={loading}
        enableSubmitLabel={submitLabel}
      />
    </div>
  );
};

const LegalText = (t, configURL, dialogHandler, classes) => {
  if (configURL?.tcpa_consent_text) {
    window.tcpa_consent_text = configURL?.tcpa_consent_text;
    const terms_and_condition_tag = (
      <a
        href={
          configURL && configURL?.terms_and_conditions?.length
            ? configURL?.terms_and_conditions
            : null
        }
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("TERMS_LINK")}
      </a>
    );

    const privacy_policy_tag = (
      <a
        href={
          configURL && configURL.privacy_policy.length
            ? configURL.privacy_policy
            : null
        }
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("PRIVACY_POLICY")}
      </a>
    );

    const partner_tag = configURL.partner.length ? (
      <a
        href={configURL && configURL.partner.length ? configURL.partner : null}
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("PARTNER_COMPANIES")}{" "}
      </a>
    ) : (
      <a onClick={dialogHandler} className={classes.termsLink}>
        {t("PARTNER_COMPANIES")}{" "}
      </a>
    );

    let configLegalText = configURL?.tcpa_consent_text;

    const formatStr = () =>
      [
        { tagValue: "replace3", value: "<partner companies>" },
        { tagValue: "replace2", value: "<terms and conditions>" },
        { tagValue: "replace1", value: "<privacy policy>" }
      ].forEach(({ tagValue, value }) => {
        if (configLegalText.toLowerCase().includes(value)) {
          const strSplit = configLegalText.toLowerCase().split(value);
          configLegalText =
            configLegalText.substring(0, strSplit[0].length) +
            tagValue +
            configLegalText.substring(
              configLegalText.toLowerCase().indexOf(value) + value?.length,
              configLegalText.length
            );
          return configLegalText;
        } else {
          return configLegalText;
        }
      });

    formatStr();

    const fetchText = () => {
      const linksArray = [
        {
          tagValue: partner_tag,
          value: configLegalText.indexOf("replace3"),
          name: "replace3"
        },
        {
          tagValue: terms_and_condition_tag,
          value: configLegalText.indexOf("replace2"),
          name: "replace2"
        },
        {
          tagValue: privacy_policy_tag,
          value: configLegalText.indexOf("replace1"),
          name: "replace1"
        }
      ]
        .filter(({ value }) => value != -1)
        .sort((a, b) => a.value - b.value);

      const newStr = configLegalText.split(linksArray[0]?.name);

      const newStr2 = linksArray[1]?.name?.length
        ? newStr[1].split(linksArray[1].name)
        : "";

      const newStr3 = linksArray[2]?.name?.length
        ? newStr2[1].split(linksArray[2].name)
        : "";

      return (
        <>
          {newStr[0]}
          {linksArray[0]?.tagValue} {newStr2?.length ? newStr2[0] : newStr[1]}{" "}
          {linksArray[1]?.name?.length ? linksArray[1]?.tagValue : ""}{" "}
          {newStr3?.length ? newStr3[0] : newStr2[1]}{" "}
          {linksArray[2]?.name?.length ? linksArray[2]?.tagValue : ""}{" "}
          {newStr3?.length ? newStr3[1] : ""}
        </>
      );
    };

    return <Typography className={classes.emailPara}>{fetchText()}</Typography>;
  } else {
    window.tcpa_consent_text = `${t("EMAIL_SUBMISSION_PART1")}${t(
      "PARTNER_COMPANIES"
    )}${t("EMAIL_SUBMISSION_PART2")}${t("TERMS_LINK")} and ${t(
      "PRIVACY_POLICY"
    )}${t("EMAIL_SUBMISSION_PART3")}`;
    return (
      <Typography className={classes.emailPara}>
        {t("EMAIL_SUBMISSION_PART1")}{" "}
        {configURL.partner.length ? (
          <a
            href={
              configURL && configURL.partner.length ? configURL.partner : null
            }
            className={classes.termsLink}
            target={"_blank"}
          >
            {t("PARTNER_COMPANIES")}{" "}
          </a>
        ) : (
          <a onClick={dialogHandler} className={classes.termsLink}>
            {t("PARTNER_COMPANIES")}{" "}
          </a>
        )}
        {t("EMAIL_SUBMISSION_PART2")}{" "}
        <a
          href={
            configURL && configURL.terms_and_conditions.length
              ? configURL.terms_and_conditions
              : null
          }
          className={classes.termsLink}
          target={"_blank"}
        >
          {t("TERMS_LINK")}
        </a>{" "}
        and{" "}
        <a
          href={
            configURL && configURL.privacy_policy.length
              ? configURL.privacy_policy
              : null
          }
          className={classes.termsLink}
          target={"_blank"}
        >
          {t("PRIVACY_POLICY")}
        </a>
        {t("EMAIL_SUBMISSION_PART3")}
      </Typography>
    );
  }
};

const MedicareLegalText = (t, configURL, dialogHandler, classes) => {
  if (configURL?.tcpa_consent_text) {
    window.tcpa_consent_text = configURL?.tcpa_consent_text;
    const terms_and_condition_tag = (
      <a
        href={
          configURL && configURL.terms_and_conditions
            ? configURL.terms_and_conditions
            : null
        }
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("TERMS_LINK")}
      </a>
    );

    const privacy_policy_tag = (
      <a
        href={
          configURL && configURL.privacy_policy.length
            ? configURL.privacy_policy
            : null
        }
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("PRIVACY_POLICY")}
      </a>
    );

    const partner_tag = configURL?.partner?.length ? (
      <a
        href={configURL && configURL.partner.length ? configURL.partner : null}
        className={classes.termsLink}
        target={"_blank"}
      >
        {t("PARTNER_COMPANIES")}{" "}
      </a>
    ) : (
      <a onClick={dialogHandler} className={classes.termsLink}>
        {t("PARTNER_COMPANIES")}{" "}
      </a>
    );
    let configLegalText = configURL?.tcpa_consent_text;

    const formatStr = () =>
      [
        { tagValue: "replace3", value: "<partner companies>" },
        { tagValue: "replace2", value: "<terms and conditions>" },
        { tagValue: "replace1", value: "<privacy policy>" }
      ].forEach(({ tagValue, value }) => {
        if (configLegalText.toLowerCase().includes(value)) {
          const strSplit = configLegalText.toLowerCase().split(value);
          configLegalText =
            configLegalText.substring(0, strSplit[0].length) +
            tagValue +
            configLegalText.substring(
              configLegalText.toLowerCase().indexOf(value) + value?.length,
              configLegalText.length
            );
          return configLegalText;
        } else {
          return configLegalText;
        }
      });

    formatStr();

    const fetchText = () => {
      const linksArray = [
        {
          tagValue: partner_tag,
          value: configLegalText.indexOf("replace3"),
          name: "replace3"
        },
        {
          tagValue: terms_and_condition_tag,
          value: configLegalText.indexOf("replace2"),
          name: "replace2"
        },
        {
          tagValue: privacy_policy_tag,
          value: configLegalText.indexOf("replace1"),
          name: "replace1"
        }
      ]
        .filter(({ value }) => value != -1)
        .sort((a, b) => a.value - b.value);

      const newStr = configLegalText.split(linksArray[0]?.name);

      const newStr2 = linksArray[1]?.name?.length
        ? newStr[1].split(linksArray[1].name)
        : "";

      const newStr3 = linksArray[2]?.name?.length
        ? newStr2[1].split(linksArray[2].name)
        : "";

      return (
        <>
          {newStr[0]}
          {linksArray[0]?.tagValue} {newStr2?.length ? newStr2[0] : newStr[1]}{" "}
          {linksArray[1]?.name?.length ? linksArray[1]?.tagValue : ""}{" "}
          {newStr3?.length ? newStr3[0] : newStr2[1]}{" "}
          {linksArray[2]?.name?.length ? linksArray[2]?.tagValue : ""}{" "}
          {newStr3?.length ? newStr3[1] : ""}
        </>
      );
    };

    return <Typography className={classes.emailPara}>{fetchText()}</Typography>;
  } else {
    window.tcpa_consent_text = `${t("MEDICARE_EMAIL_SUBMISSION_PART1")}${t(
      "PARTNER_COMPANIES"
    )}${t("MEDICARE_EMAIL_SUBMISSION_PART2")}${t("TERMS_LINK")} and ${t(
      "PRIVACY_POLICY"
    )}${t("EMAIL_SUBMISSION_PART3")}`;
    return (
      <Typography className={classes.emailPara}>
        {t("MEDICARE_EMAIL_SUBMISSION_PART1")}{" "}
        {configURL.partner.length ? (
          <a
            href={
              configURL && configURL.partner.length ? configURL.partner : null
            }
            className={classes.termsLink}
            target={"_blank"}
          >
            {t("PARTNER_COMPANIES")}{" "}
          </a>
        ) : (
          <a onClick={dialogHandler} className={classes.termsLink}>
            {t("PARTNER_COMPANIES")}{" "}
          </a>
        )}
        {t("MEDICARE_EMAIL_SUBMISSION_PART2")}{" "}
        <a
          href={
            configURL && configURL.terms_and_conditions
              ? configURL.terms_and_conditions
              : null
          }
          className={classes.termsLink}
          target={"_blank"}
        >
          {t("TERMS_LINK")}
        </a>{" "}
        and{" "}
        <a
          href={
            configURL && configURL.privacy_policy.length
              ? configURL.privacy_policy
              : null
          }
          className={classes.termsLink}
          target={"_blank"}
        >
          {t("PRIVACY_POLICY")}
        </a>
        {t("EMAIL_SUBMISSION_PART3")}
      </Typography>
    );
  }
};
export default EmailField;
