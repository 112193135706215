import { loaderOperations } from "../loader";
import { alertOperations } from "../alerts";
import { getLocation } from "../../services/api";
import * as actions from "./actions";
import i18n from "../../i18n";
/**
 * Fetch location using zip URL
 * @param {*} params
 */
const fetchLocation = (ZIP, callBackFn) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());

      if (ZIP == 99999) {
        callBackFn({
          address2: "New York",
          city: "New York",
          county: "",
          state: "NY",
          timezone: "",
          zip_code: ZIP
        });
        dispatch(
          actions.fetchLocationSuccess({
            data: {
              address2: "New York",
              city: "New York",
              county: "",
              state: "NY",
              timezone: "",
              zip_code: ZIP
            }
          })
        );
      } else {
        let res = await getLocation(ZIP);
        if (res.data) {
          callBackFn(res.data);
          dispatch(
            actions.fetchLocationSuccess({
              data: res.data
            })
          );
        }
      }
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      err.response.data === "Not Found"
        ? dispatch(alertOperations.addAlert("Invalid zip code", "error"))
        : dispatch(alertOperations.addAlert(err.response.data, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

export { fetchLocation };
