import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  hintiIcon: {
    position: "relative",
    top: 4,
    fontSize: "24px",
    marginLeft: "4px",
    color: theme.palette.primary.hintIconBg,
    opacity: 1
  }
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.darkText,
    color: theme.palette.primary.white,
    fontSize: 12,
    marginBottom: 6
  },
  arrow: {
    color: theme.palette.primary.darkText
  }
}))(Tooltip);
const ToolTipPopUp = ({ hint }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <CustomTooltip
        arrow
        title={hint}
        placement="top"
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <HelpRoundedIcon
          className={classes.hintiIcon}
          onClick={handleTooltipOpen}
        />
      </CustomTooltip>
    </ClickAwayListener>
  );
};

export default ToolTipPopUp;
