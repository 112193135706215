// contact fields
export const initialContactFields = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip_code: ""
};
// Solar
export const initialSolarField = {
  //set here all other fields
  best_call_time: null,
  own_property: null,
  purchase_time_frame: null,
  monthly_electric_bill: null,
  utility_provider: null,
  roof_shade: null,
  property_type: null,
  credit_rating: null
};
// medicare
export const initailMedicareFields = {
  //set here all other fields
  has_medicare: null,
  height: null,
  weight: null,
  birth_date: null,
  gender: null,
  tobacco: null,
  marital_status: null,
  policy_expiration_date: null,
  spouse_first_name: null,
  spouse_last_name: null,
  spouse_birth_date: null
};
