import {
  FetchDateStartOfNextMonth,
  FetchDateBefore2Year
} from "../../../services/utility";

// vehicle section
export const initialVehicleVaule = {
  year: "",
  make: "",
  model: "",
  submodel: "",
  salvaged: false,
  rental: false,
  towing: false,
  alarm: true,
  four_wheel_drive: false,
  abs: true,
  airbags: true,
  automatic_seat_belts: true,
  garage: "No cover",
  ownership: "",
  primary_use: "",
  annual_miles: "",
  weekly_commute_days: 3,
  one_way_distance: 15000,
  comprehensive_deductible: "1000",
  collision_deductible: "1000"
};

// driver section
export const initialDriverVaule = {
  first_name: "",
  last_name: "",
  birth_date: "",
  marital_status: "",
  relationship: "",
  gender: "",
  license_status: "Active",
  license_state: "",
  license_ever_suspended: false,
  age_licensed: 18,
  residence_type: "",
  months_at_residence: 12,
  occupation: "",
  months_at_employer: 18,
  education: "",
  bankruptcy: false
};

// requested policy section
export const initialRequestedPolicy = {
  coverage_type: "",
  bodily_injury: "",
  property_damage: "",
  comprehensive_deductible: "",
  collision_deductible: ""
};

// current policy section
export const initialCurrentPolicy = {
  insurance_company: "",
  expiration_date: FetchDateStartOfNextMonth(),
  insured_since: FetchDateBefore2Year(),
  coverage_type: ""
};
