import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  notification: {
    "&>div": {
      top: "146px",
      left: "1271px",
      width: "fit-content",
      height: "42px",
      borderRadius: "5px",
      opacity: "1",
      boxShadow: "0px 3px 60px #00000029"
    }
  },
  notificationSuccess: {
    "&>div": {
      color: theme.palette.primary.main,
      background: theme.palette.primary.white + " 0% 0% no-repeat padding-box",
      border: "3px solid " + theme.palette.primary.main
    }
  },
  close: {
    padding: theme.spacing(0.5)
  },
  iconButton: {
    padding: 0
  }
}));
