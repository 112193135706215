import { createSelector } from "reselect";

const featureSelector = ({ autoInsurance }) => autoInsurance;

/**
 * get vehicle make
 */
export const getVehicleMakeData = createSelector(
  featureSelector,
  ({ vehiclesMake }) => {
    return vehiclesMake;
  }
);

/**
 * get vehicle model
 */
export const getVehicleModelData = createSelector(
  featureSelector,
  ({ vehiclesModel }) => {
    return vehiclesModel;
  }
);
