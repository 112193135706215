import React, { useEffect } from "react";

const useJornayacript = (jornayaID) => {
  useEffect(() => {
    if (jornayaID) {
      enableJornayaCampaign(jornayaID);
    }
  }, []);
};
window.getJornayaToken = function (token) {
  if (token) window.jornayaToken = token;
  // save token variable here
};

const enableJornayaCampaign = (campaignId) => {
  var s = document.createElement("script");
  s.id = "LeadiDscript_campaign";
  s.type = "text/javascript";
  s.async = true;
  s.src =
    "//create.lidstatic.com/campaign/" +
    campaignId +
    ".js?snippet_version=2&callback=getJornayaToken";
  var fs = document.getElementsByTagName("script")[0];
  fs.parentNode.insertBefore(s, fs);
};

export default useJornayacript;
