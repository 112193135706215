import React, { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./YesNoQuestion.style";
import { useTranslation } from "react-i18next";
import { PreviousButton } from "../../CustomButton/Button";
import { FormatQuestion, ChangeTitle } from "../../../services/utility";
const YesNoQuestion = ({
  question,
  handleYes,
  handleNo,
  handlePrevious,
  vehicleCount,
  vertical,
  lifePersonCount,
  addedDriverCount = null,
  show = true
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleFormat = () => {
    if (vertical === "life_insurance") {
      return ChangeTitle(question.title, lifePersonCount, "person");
    } else {
      return FormatQuestion(
        t,
        question.id,
        question.title,
        addedDriverCount,
        vehicleCount
      );
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Typography className={`${classes.title} ${classes.spaceTop}`}>
          {titleFormat()}
        </Typography>
        <div className={classes.spaceTop}>
          <Button
            variant="contained"
            className={`${classes.btn} ${classes.spaceRight}`}
            startIcon={<CheckIcon className={classes.styledIcon} />}
            onClick={() => handleYes()}
          >
            {t("YES")}
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            startIcon={<ClearIcon className={classes.styledIcon} />}
            onClick={() => handleNo()}
          >
            {t("NO")}
          </Button>
        </div>
      </div>
      {show && <PreviousButton onClickPrev={() => handlePrevious()} />}
    </>
  );
};

export default YesNoQuestion;
