import * as types from "./types";

/**
 * Fetch List Success for vehicle make
 * @param {*} data
 */
const fetchLocationSuccess = (data) => {
  return {
    type: types.FETCH_LOCATION_SUCCESS,
    payload: data
  };
};

export { fetchLocationSuccess };
