import React, { useEffect, useState } from "react";
import useStyles from "./MultiChoiceQuestion.styles";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Typography,
  InputBase
} from "@material-ui/core";
import RadioButton from "../CustomRadio/CustomRadio.component";
import { NavigationButton } from "../CustomButton/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import {
  autoInsuranceSelectors,
  autoInsuranceOperations
} from "../../store/autoInsurance";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FormatQuestion,
  FormatHomeClaimSectionTitle,
  ChangeTitle
} from "../../services/utility";
//import { DateSelector } from "./../../components/QuestionTypes/DOB/DateSelector";

const MultichoiceQuestion = ({
  data,
  nextQuestionHandler,
  handlePrevious,
  dataHandler,
  questionFieldValues,
  addedDriverCount,
  homeClaimCount,
  lifePersonCount,
  healthChildCount,
  showAutoComplete = false,
  showRadio = true,
  vertical
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const savedExpirationDate = questionFieldValues?.expiration_date
  //   ? questionFieldValues.expiration_date.split("-")
  //   : "";

  // const [expireDate, setExpireDate] = useState(initialExp);
  //const { exp_day, exp_month, exp_year } = expireDate;

  // const resetExpDate = () => setExpireDate(initialExp);

  const val = data.id;
  //const val2 = data.id2;
  const vehicleMakeData = useSelector(
    autoInsuranceSelectors.getVehicleMakeData
  );
  const vehicleModelData = useSelector(
    autoInsuranceSelectors.getVehicleModelData
  );

  const disableValue =
    data?.field === "optional" ? false : !questionFieldValues[val]?.length;

  const dataValues =
    data?.id === "make"
      ? vehicleMakeData?.data
      : data.id === "model"
      ? vehicleModelData?.data
      : data.id === "year"
      ? data?.values.sort(function (a, b) {
          return b - a;
        })
      : data.values;

  // clears selected option when goes to previous step and goes back to model selection step
  useEffect(() => {
    data.id === "make" &&
      dataHandler({
        ...questionFieldValues,
        model: ""
      });
  }, [vehicleModelData]);

  const titleFormat = (question) => {
    if (vertical === "auto_insurance") {
      if (
        data.id === "model" ||
        data.id === "ownership" ||
        data.id === "primary_use" ||
        data.id === "annual_miles"
      ) {
        return question.replace("{MAKE}", questionFieldValues.make);
      } else {
        return FormatQuestion(t, data.id, question, addedDriverCount);
      }
    } else if (vertical === "home") {
      return FormatHomeClaimSectionTitle(question, homeClaimCount);
    } else if (vertical === "life_insurance") {
      return ChangeTitle(question, lifePersonCount, "person");
    } else if (vertical === "health_insurance") {
      return ChangeTitle(question, healthChildCount, "child");
    } else {
      return question;
    }
  };

  // return vertical === "auto_insurance"
  //   ? FormatQuestion(t, data.id, question, addedDriverCount)
  //   : question;
  const onClickHandler = async () => {
    if (data.id === "make") {
      await dispatch(
        autoInsuranceOperations.fetchVehicleModel(
          questionFieldValues.make.toLowerCase(),
          questionFieldValues.year
        )
      );
    }
    nextQuestionHandler();
  };

  // const handleOnChangeDate = ({ name, value }) => {
  //   if (name === "day") setExpireDate({ ...expireDate, exp_day: value });
  //   if (name === "month") setExpireDate({ ...expireDate, exp_month: value });
  //   if (name === "year") setExpireDate({ ...expireDate, exp_year: value });
  // };

  // useEffect(() => {
  //   if (Boolean(exp_day) && Boolean(exp_month) && Boolean(exp_year)) {
  //     dataHandler({
  //       ...questionFieldValues,
  //       [val2]: `${exp_year}-${exp_month}-${exp_day}`
  //     });
  //   }
  // }, [expireDate.exp_day, expireDate.exp_month, expireDate.exp_year]);

  return (
    <>
      <Typography className={classes.question}>
        {titleFormat(data.title)}
      </Typography>
      {showRadio && (
        <div className={classes.container}>
          <RadioGroup
            row
            name="controlled-radio-buttons-group"
            value={data.id}
            onChange={(event) => {
              dataHandler({
                ...questionFieldValues,
                [val]: event.target.value
              });
            }}
            className={classes.options}
          >
            {dataValues?.length > 0 ? (
              dataValues.map((answer, index) => {
                return (
                  <Box className={classes.radioBoxCover} key={index + 1}>
                    <FormControlLabel
                      // className={classes.formLabelStyle}
                      className={
                        answer.toString() === questionFieldValues[val]
                          ? `${classes.btnChecked} ${classes.btn} ${classes.spaceRight}`
                          : `${classes.btn} ${classes.spaceRight}`
                      }
                      checked={answer.toString() === questionFieldValues[val]}
                      value={answer}
                      control={<RadioButton icon="" checkedIcon="" />}
                      label={
                        <span className={classes.answerLabel}>{answer}</span>
                      }
                    />
                  </Box>
                );
              })
            ) : (
              <Typography variant="h3" className={classes.question}>
                {data.id === "model" ? t("NO_OPTIONS_MODEL") : t("NO_OPTIONS")}
              </Typography>
            )}
          </RadioGroup>
        </div>
      )}
      {showAutoComplete && (
        <Autocomplete
          options={
            dataValues?.length
              ? dataValues?.map((value) =>
                  typeof value === "string" ? value : value.toString()
                )
              : []
          }
          getOptionLabel={(option) => option?.toString()}
          value={questionFieldValues[val]}
          onChange={(event, newValue) => {
            dataHandler({ ...questionFieldValues, [val]: newValue });
          }}
          className={classes.spaceBottom}
          classes={{ option: classes.autoCompleteOptions }}
          renderInput={(params) => (
            <InputBase
              placeholder={`Browse ${data?.id}`.replaceAll("_", " ")}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              className={`${classes.textInputField} ${classes.autoCompleteField}`}
              endAdornment={
                Boolean(questionFieldValues[val]) && (
                  <ClearIcon
                    onClick={() => {
                      dataHandler({
                        ...questionFieldValues,
                        [val]: ""
                      });
                    }}
                    style={{ fontSize: "22px", paddingRight: "5px" }}
                  />
                )
              }
            />
          )}
        />
      )}
      {/* {data.id === "insurance_company" && (
        <div className={classes.expiryDateContainer}>
          <Typography variant="h5" className={classes.question}>
            {titleFormat(t("EXPIRATION_DATE"))}
          </Typography>
          <DateSelector
            handleOnChange={handleOnChangeDate}
            day={exp_day}
            month={exp_month}
            year={exp_year}
          />
        </div>
      )} */}
      <NavigationButton
        disabledValue={disableValue}
        onClickPrev={() => handlePrevious()}
        onClickNext={onClickHandler}
      />
    </>
  );
};
export default MultichoiceQuestion;
