import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import { NavigationButton } from "../../CustomButton/Button";
const AddressQuestion = ({ handleNext, handlePrevious, dataHandler, data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { address, city, state, zip_code } = data;

  const [error, setError] = useState({
    street: false
  });

  const validate_address = /^[^-\s-\,-\.][a-zA-Z\d\s\,\.]*$/;
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{t("ADDRESS_TITLE")}</Typography>
      <TextField
        error={error.street}
        id="outlined-basic"
        placeholder="Street Address"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={`${classes.spaceTop} ${classes.spaceBottom}`}
        value={address}
        helperText={error.street && "Field should not be empty."}
        onChange={(e) => {
          let input = e.target.value;

          if (!input || validate_address.test(input)) {
            dataHandler({ ...data, address: e.target.value });
            setError({ ...error, street: false });
          }
        }}
      />
      <TextField
        disabled
        id="outlined-basic"
        placeholder="10601"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={`${classes.spaceBottom} ${classes.disabledBg}`}
        value={zip_code}
      />
      <div className={`${classes.spaceBottom} ${classes.textField2Wrapper}`}>
        <TextField
          disabled
          variant="outlined"
          InputProps={{
            className: classes.textInputField2
          }}
          className={classes.disabledBg}
          value={city}
        />
        <TextField
          disabled
          variant="outlined"
          InputProps={{
            className: classes.textInputField2
          }}
          className={classes.disabledBg}
          value={state}
        />
      </div>
      <NavigationButton
        disabledValue={!address?.length}
        onClickPrev={handlePrevious}
        onClickNext={handleNext}
      />
    </div>
  );
};

export default AddressQuestion;
