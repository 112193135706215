import React, { useState } from "react";
import { Typography, InputBase } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { NavigationButton } from "../../CustomButton/Button";

const UtilityField = ({
  utilityOptions,
  handleNext,
  next,
  previous,
  dataHandler,
  data,
  handlePrevious,
  questionFieldValues
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [utility, setUtility] = useState(
    utilityOptions.find((obj) => {
      return obj.name === questionFieldValues.utility_provider;
    })
  );
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {data ? data.title : t("What is the name of your utility provider?")}
      </Typography>

      <Autocomplete
        options={utilityOptions}
        getOptionLabel={(option) => (Boolean(option?.name) ? option?.name : "")}
        value={utility}
        onChange={(event, newValue) => {
          setUtility(newValue.name);
          dataHandler({
            ...questionFieldValues,
            utility_provider: newValue.name
          });
        }}
        classes={{ option: classes.autoCompleteOptions }}
        renderInput={(params) => (
          <InputBase
            placeholder="Utility provider"
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            className={`${classes.textInputField} ${classes.autoCompleteField} ${classes.spaceBottom} ${classes.spaceTop}`}
            endAdornment={
              Boolean(utility) && (
                <ClearIcon
                  onClick={() => setUtility("")}
                  style={{ fontSize: "22px", paddingRight: "5px" }}
                />
              )
            }
          />
        )}
      />
      <NavigationButton
        disabledValue={!Boolean(utility)}
        onClickPrev={() => (previous ? handleNext(previous) : handlePrevious())}
        onClickNext={() => handleNext(next)}
      />
    </div>
  );
};

export default UtilityField;
