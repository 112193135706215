import { makeStyles } from "@material-ui/core/styles";
import HEADER_BG_AUTO from "../../assets/hero-auto.png";
import HEADER_BG_SOLAR from "../../assets/hero-solar.png";
import HEADER_BG_MEDICARE from "../../assets/hero-medicare.png";
import UNDERLINE from "../../assets/Underline@2x.png";
export default makeStyles((theme) => ({
  heroContainer: {
    backgroundColor: theme.palette.primary.headerBg,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    width: "100%",
    height: "90px"
  },
  heroComponentHeight: {
    height: "120px"
  },
  heroComponentHeight2: {
    height: "140px"
  },
  bannerImg: ({ bgPath }) => ({
    backgroundImage: bgPath ? `url(${bgPath})` : "",
    [theme.breakpoints.between("xs", "sm")]: {
      backgroundImage: "none"
    }
  }),
  solarBannerImg: {
    backgroundImage: `url(${HEADER_BG_SOLAR})`,
    [theme.breakpoints.between("xs", "sm")]: {
      backgroundImage: "none"
    }
  },
  medicareBannerImg: {
    backgroundImage: `url(${HEADER_BG_MEDICARE})`,
    [theme.breakpoints.between("xs", "sm")]: {
      backgroundImage: "none"
    }
  },
  verticalName: {
    textAlign: "left",
    lineHeight: 0.8,
    textTransform: "capitalize",
    fontSize: "1rem",
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      display: "block",
      transform: "translate(0,6px)",
      width: ({ verticalWidth }) => `${verticalWidth / 2.3}ch`,
      height: "2px",
      backgroundColor: theme.palette.primary.main,
      marginBottom: "8px"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      // marginBottom: "10px",
      "&::after": {
        margin: "auto",
        marginBottom: "15px"
      }
    }
  },

  heroText: {
    fontSize: "2.18rem",
    textAlign: "left",
    color: theme.palette.primary.darkText,
    fontWeight: theme.typography.weights.light,
    textTransform: "capitalize",
    opacity: 1,
    lineHeight: 1.3,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.5rem",
      textAlign: "center"
    }
  },
  heroTextDecor: {
    fontSize: "2.18rem",
    color: theme.palette.primary.darkText,
    fontWeight: theme.typography.weights.semiBold,
    position: "relative",
    "&::after": {
      content: '""',
      width: "100%",
      height: "10px",
      position: "absolute",
      bottom: -10,
      left: 0,
      backgroundImage: `url(${UNDERLINE})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.5rem",
      display: "block",
      textAlign: "center",
      "&::after": {
        width: "100%",
        margin: "auto",
        backgroundSize: "12ch",
        backgroundPositionX: "center"
      }
    }
  },
  heroTextWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    height: "65%",
    paddingLeft: "5%",
    [theme.breakpoints.between("xs", "sm")]: {
      alignItems: "center",
      height: "70%",
      paddingLeft: 0
    }
  }
}));
