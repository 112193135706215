import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  radioIcon: {
    borderRadius: "50%",
    width: 15,
    height: 15,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    margin: "0 10px"
  },
  checkedRadioIcon: {
    "&:before": {
      display: "block",
      width: 13,
      height: 13,
      marginTop: 1,
      marginLeft: 1,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      content: '""'
    }
  }
}));
