import React, { useEffect, useState } from "react";
import AddressQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/AddressQuestion.component";
import ZipPhoneNumber from "../../../components/QuestionTypes/TextInputTypeComponent/ZipPhoneNumber.component";
import NameField from "../../../components/QuestionTypes/TextInputTypeComponent/NameField.component";
import EmailSubmit from "../../../components/QuestionTypes/TextInputTypeComponent/EmailSubmit.component";
import DOB from "../../../components/QuestionTypes/DOB/Dob.component";
import HeightSelectComponent from "../../../components/QuestionTypes/HeightComponent/HeightComponent";
import YesNoQuestion from "../../../components/QuestionTypes/YesNoQuestion/YesNoQuestion.component";
import UtilityField from "../../../components/QuestionTypes/TextInputTypeComponent/UtilityField.component.";
import MultichoiceQuestion from "../../../components/MultichoiceQuestion/MultichoiceQuestion";
import TextFieldQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/TextField.component";

import { commonSelectors, commonOperations } from "../../../store/common";
import { useDispatch } from "react-redux";
import { UTILITY_COMPANY_LIST } from "../../../services/UtilityProviders";

import useTrustedFormScript from "../TrustedFormScript";
const MedicareForm = ({
  jornayaCampaignID,
  url,
  contactFieldValues,
  contactFieldHandler,
  questions,
  setCompleted,
  questionFieldValues,
  questionFieldHandler,
  submit,
  loading,
  vertical,
  showPartnersList
}) => {
  const [active, setActive] = useState("zip");
  const [index, setIndex] = useState(0);
  const totalQuestions = 5 + questions.length;
  const [overAllIndex, setOverAllIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const dispatch = useDispatch();

  // filters utility provider according to zip code
  const UtilitiesProvider = UTILITY_COMPANY_LIST.filter(
    (utility) => utility[contactFieldValues.state]
  );

  //sets city and state value in contact field and moves to next question
  const updateCityState = async (res) =>
    await contactFieldHandler(
      {
        ...contactFieldValues,
        city: res?.city,
        state: res?.state
      },
      setActive("question")
    );

  const getLocationDetail = () => {
    dispatch(
      commonOperations.fetchLocation(
        contactFieldValues?.zip_code,
        updateCityState
      )
    );
  };

  useEffect(() => {
    if (active !== "question" || index === 0) {
      if (!answeredQuestions.includes(active)) {
        setAnsweredQuestions(answeredQuestions.concat(active));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
    }
  }, [active]);

  const setNextQuestion = (nextIndex) => {
    if (nextIndex >= 0 && nextIndex <= questions.length - 1) {
      if (!answeredQuestions.includes(index)) {
        setAnsweredQuestions(answeredQuestions.concat(index));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
      setIndex(nextIndex);
    } else {
      setActive("zip");
    }
    if (nextIndex === questions.length) {
      setActive("name");
    }
  };

  const setPreviousQuestion = (index) => {
    if (index >= 0 && index <= questions.length) {
      setIndex(index);
    } else {
      setActive("zip");
    }
    if (index === questions.length) {
      setActive("name");
    }
  };
  useTrustedFormScript(); // trusted form called
  const renderComponent = () => {
    switch (active) {
      case "zip":
        return (
          <ZipPhoneNumber
            dialogHandler={showPartnersList}
            configURL={url}
            dataHandler={contactFieldHandler}
            data={contactFieldValues}
            fetchLocationDetail={getLocationDetail}
          />
        );
      case "name":
        return (
          <NameField
            handleNext={() => setActive("address")}
            handlePrevious={() => setActive("question")}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
          />
        );
      case "address":
        return (
          <AddressQuestion
            handlePrevious={() => setActive("name")}
            handleNext={() => setActive("email")}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
          />
        );
      case "email":
        return (
          <EmailSubmit
            jornayaID={jornayaCampaignID}
            configURL={url}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
            handleNext={submit}
            handlePrevious={() => setActive("address")}
            loading={loading}
            vertical={vertical}
            dialogHandler={showPartnersList}
          />
        );
      case "question":
        if (questions[index]) {
          if (questions[index].type === "radio") {
            return (
              <MultichoiceQuestion
                data={questions[index]}
                nextQuestionHandler={() => {
                  if (
                    vertical === "medicare" &&
                    questions[index].id === "marital_status"
                  ) {
                    if (
                      questionFieldValues?.marital_status ==
                        "Domestic Partner" ||
                      questionFieldValues?.marital_status == "Married"
                    ) {
                      setNextQuestion(index + 1);
                    } else {
                      questionFieldHandler({
                        ...questionFieldValues,
                        spouse_first_name: null,
                        spouse_last_name: null,
                        spouse_birth_date: null
                      });
                      setActive("name");
                    }
                  } else {
                    setNextQuestion(index + 1);
                  }
                }}
                handlePrevious={() => setPreviousQuestion(index - 1)}
                questionFieldValues={questionFieldValues}
                dataHandler={questionFieldHandler}
              />
            );
          } else if (questions[index].type === "yesno") {
            return (
              <YesNoQuestion
                handleYes={() => setNextQuestion(index + 1)}
                handleNo={() => setNextQuestion(index + 1)}
                handlePrevious={() => setPreviousQuestion(index - 1)}
                question={questions[index]}
                questionFieldValues={questionFieldValues}
                dataHandler={questionFieldHandler}
              />
            );
          } else if (questions[index].type === "TEXT_INPUT")
            if (questions[index].id !== "utility_provider") {
              return (
                <TextFieldQuestion
                  data={questions[index]}
                  questionFieldValues={questionFieldValues}
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setPreviousQuestion(index - 1)}
                  dataHandler={questionFieldHandler}
                />
              );
            } else {
              return (
                <UtilityField
                  data={questions[index]}
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setPreviousQuestion(index - 1)}
                  dataHandler={questionFieldHandler}
                  questionFieldValues={questionFieldValues}
                  utilityOptions={
                    Boolean(UtilitiesProvider?.length)
                      ? UtilitiesProvider[0][contactFieldValues.state]
                      : []
                  }
                />
              );
            }
          else if (questions[index].type === "TEXT_INPUT_2") {
            if (questions[index].id === "spouse_first_name") {
              return (
                <NameField
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  question={questions[index]}
                />
              );
            }
          } else if (questions[index].type === "dropdown") {
            if (
              questions[index].id === "birth_date" ||
              questions[index].id === "policy_expiration_date" ||
              questions[index].id === "spouse_birth_date"
            ) {
              return (
                <DOB
                  showHint={true}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  handleNext={() => {
                    questionFieldHandler({
                      ...questionFieldValues,
                      [questions[index].id]: `${
                        questionFieldValues[
                          `${[questions[index]?.id]}_year_temp`
                        ]
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_month_temp`
                        ].id
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_day_temp`
                        ]
                      }`
                    });
                    questions[index].id === "spouse_birth_date"
                      ? setActive("name")
                      : setNextQuestion(index + 1);
                  }}
                  question={questions[index]}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                />
              );
            }
            if (questions[index].id === "height") {
              return (
                <HeightSelectComponent
                  showHint={false}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  handleNext={() => setNextQuestion(index + 1)}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  question={questions[index]}
                />
              );
            }
          }
        }
      default:
        return <> </>;
    }
  };
  return <>{renderComponent()}</>;
};

export default MedicareForm;
