import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  callHelpContainerXs: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10
  },
  callIcon: {
    width: "32px",
    height: "32px",
    margin: "0",
    paddingRight: "10px"
  },
  callHelpTextWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 0"
  },
  helperText: {
    textAlign: "left",
    color: theme.palette.primary.darkText,
    fontSize: "16px"
  },
  phoneNumber: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    textDecoration: "none",
    fontWeight: theme.typography.weights.semiBold,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px"
    }
  }
}));
