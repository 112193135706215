// personal section
export const initialHomePersonalVaule = {
  birth_date: null,
  gender: null,
  marital_status: null,
  occupation: null
};

// properties section
export const initialHomePropertiesValue = {
  property_type: null,
  zip_code: null,
  ownership: null,
  occupancy: null,
  garage: null,
  foundation: null,
  home_security: null,
  year_built: null,
  stories: null,
  bedrooms: null,
  bathrooms: null,
  square_footage: null,
  dwelling_value: null,
  construction_class: null,
  construction_type: null,
  roof_type: null,
  panel_type: null,
  proximity_water: null,
  exterior_walls: null,
  heating_type: null,
  wiring_type: null,
  dog: null
};

// requested policy section
export const initialHomeRequestedPolicy = {
  coverage_type: null,
  liability: null,
  deductible: null,
  replacement_cost: null
};

// current policy section
export const initialHomeCurrentPolicy = {
  current_insurance_check: "",
  insurance_company: null,
  expiration_date: null,
  insured_since: null,
  coverage_type: null
};

// claim section
export const initialHomeClaim = {
  claim_date: null,
  type: null,
  paid_amount: null
};
