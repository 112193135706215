import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./SingleDropdownStyle";
import { Selector } from "./Selector";
import { useTranslation } from "react-i18next";
import ToolTipPopUp from "../../ToolTip/ToolTipPopUp.component";
import { NavigationButton } from "../../CustomButton/Button";
import { ChangeTitle } from "../../../services/utility";
const SingleDropdown = ({
  handleNext,
  handlePrevious,
  showHint,
  dataHandler,
  data,
  question,
  vertical,
  lifePersonCount
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleOnChangeDate = ({ name, value }) => {
    name === "count" && dataHandler({ ...data, [question?.id]: value });
  };

  const formatTitle = () => {
    if (vertical === "life_insurance") {
      return ChangeTitle(question.title, lifePersonCount, "person");
    } else {
      return question.title;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.toolTipWrapper}>
          <Typography variant="h5" className={classes.title}>
            {formatTitle()}
          </Typography>
          {showHint && (
            <ToolTipPopUp hint={"Hint description about the question"} />
          )}
        </div>
        <Selector
          id={question?.id}
          data={data[question?.id]}
          handleOnChange={handleOnChangeDate}
        />
      </div>
      <NavigationButton
        disabledValue={Boolean(data[question?.id]) ? false : true}
        onClickPrev={handlePrevious}
        onClickNext={handleNext}
      />
    </>
  );
};

export default SingleDropdown;
