import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import "./i18n";
import {
  StylesProvider,
  createGenerateClassName
} from "@material-ui/core/styles";
import AutoInsurance from "./container/VerticalComponent/AutoInsurance";
import Solar from "./container/VerticalComponent/Solar";
import Medicare from "./container/VerticalComponent/Medicare";
import HomeInsurance from "./container/VerticalComponent/HomeInsurance";
import LifeInsurance from "./container/VerticalComponent/LifeInsurance";
import HealthInsurance from "./container/VerticalComponent/HealthInsurance";

const generateClassName = createGenerateClassName({
  productionPrefix: "jf",
  disableGlobal: true
});

if (document.getElementById("auto-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <AutoInsurance
          configFilePath={document
            .getElementById("auto-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("auto-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("auto-form-container")
  );
} else if (document.getElementById("solar-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <Solar
          configFilePath={document
            .getElementById("solar-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("solar-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("solar-form-container")
  );
} else if (document.getElementById("medicare-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <Medicare
          configFilePath={document
            .getElementById("medicare-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("medicare-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("medicare-form-container")
  );
} else if (document.getElementById("home-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <HomeInsurance
          configFilePath={document
            .getElementById("home-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("home-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("home-form-container")
  );
} else if (document.getElementById("life-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <LifeInsurance
          configFilePath={document
            .getElementById("life-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("life-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("life-form-container")
  );
} else if (document.getElementById("health-form-container")) {
  ReactDOM.render(
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <HealthInsurance
          configFilePath={document
            .getElementById("health-form-container")
            .getAttribute("data-config-value")}
          bannerImagePath={document
            .getElementById("health-form-container")
            .getAttribute("banner-image-path")}
        />
      </StylesProvider>
    </Provider>,
    document.getElementById("health-form-container")
  );
}
// Uncomment to run on local and qa
//  ReactDOM.render(
//   <Provider store={store}>
//     <StylesProvider generateClassName={generateClassName}>
//       <App />
//     </StylesProvider>
//   </Provider>,
//   document.getElementById("root")
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
