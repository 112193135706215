import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import { NavigationButton } from "../../CustomButton/Button";
import { FormatQuestion } from "../../../services/utility";
const NameField = ({
  question,
  handleNext,
  dataHandler,
  data,
  handlePrevious,
  addedDriverCount
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let value1 = Boolean(question?.id) ? question?.id : "first_name";
  let value2 = Boolean(question?.id2) ? question?.id2 : "last_name";

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {addedDriverCount
          ? FormatQuestion(t, "name", t("NAME_FIELD_TITLE"), addedDriverCount)
          : Boolean(question?.title)
          ? question?.title
          : t("NAME_FIELD_TITLE")}
      </Typography>
      <TextField
        id="outlined-basic"
        placeholder="First Name"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={`${classes.spaceTop} ${classes.spaceBottom}`}
        value={data[value1]}
        onChange={(e) => {
          let input = e.target.value;
          if (!input || input[input.length - 1].match("[A-Za-z]")) {
            dataHandler({ ...data, [value1]: input });
          }
        }}
      />
      <TextField
        id="outlined-basic"
        placeholder="Last Name"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={classes.spaceBottom}
        value={data[value2]}
        onChange={(e) => {
          let input = e.target.value;
          if (!input || input[input.length - 1].match("[A-Za-z]")) {
            dataHandler({ ...data, [value2]: input });
          }
        }}
      />
      <NavigationButton
        disabledValue={!data[value1]?.length || !data[value2]?.length}
        onClickPrev={handlePrevious}
        onClickNext={handleNext}
      />
    </div>
  );
};

export default NameField;
