import React from "react";
import useStyles from "./ProgressBar.style";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const ProgressBar = ({ completed }) => {
  const props = {
    done: completed
  };
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <div className={classes.progressBarContainer}>
      <Typography className={classes.progressText}>
        {completed}% <span className={classes.textSize}>{t("COMPLETED")}</span>
      </Typography>
      <div className={classes.progressBar}>
        <div className={classes.progressDone}>
          <span
            className={
              completed === 0
                ? `${classes.progressValue} ${classes.zeroProgressValue}`
                : classes.progressValue
            }
          >
            {completed}%
          </span>
        </div>
      </div>
      <div className={classes.progressTextContainer}>
        <span className={classes.labelText}>{t("START")}</span>
        <span className={classes.labelText}>{t("END")}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
