import i18n from "../i18n";
import { callRESTAPI } from "./apiHandler";

// default api base url
const locationBaseURL = `https://location.jangl.network/`;

const vehicleApiBaseURL = "https://vpic.nhtsa.dot.gov/api/";

export const getLocation = (zip) =>
  callRESTAPI(locationBaseURL + `zip_code/${zip}/`, "get");

export const saveSolarForm = (params, baseURL, headers) =>
  callRESTAPI(baseURL + "v2/solar/capture", "post", params, null, headers);

export const saveHomeForm = (params, baseURL, headers) =>
  callRESTAPI(
    baseURL + "v2/home_insurance/capture",
    "post",
    params,
    null,
    headers
  );

export const saveMedicareForm = (params, baseURL, headers) =>
  callRESTAPI(
    baseURL + "v2/medicare_enrollment/capture",
    "post",
    params,
    null,
    headers
  );

export const saveAutoInsuranceForm = (params, baseURL, headers) =>
  callRESTAPI(
    baseURL + "v2/auto_insurance/capture",
    "post",
    params,
    null,
    headers
  );
export const saveLifeInsuranceForm = (params, baseURL, headers) =>
  callRESTAPI(
    baseURL + "v2/life_insurance/capture",
    "post",
    params,
    null,
    headers
  );

export const saveHealthInsurance = (params, baseURL, headers) =>
  callRESTAPI(
    baseURL + "v2/health_insurance/capture",
    "post",
    params,
    null,
    headers
  );

export const getAllMakersAPI = () =>
  callRESTAPI(vehicleApiBaseURL + "vehicles/GetAllMakes?format=json", "get");

export const getModelAPI = (make, year) =>
  callRESTAPI(
    vehicleApiBaseURL +
      `vehicles/GetModelsForMakeYear/make/${make}/modelyear/${year}?format=json`,
    "get"
  );
