import React, { useEffect } from "react";

const useTrustedFormScript = () => {
  useEffect(() => {
    const field = "xxTrustedFormCertUrl";
    const provideReferrer = false;
    const invertFieldSensitivity = false;
    const tf = document.createElement("script");
    tf.type = "text/javascript";
    tf.async = true;
    tf.src =
      "http" +
      ("https:" == document.location.protocol ? "s" : "") +
      "://api.trustedform.com/trustedform.js?provide_referrer=" +
      encodeURI(provideReferrer) +
      "&field=" +
      encodeURI(field) +
      "&l=" +
      new Date().getTime() +
      Math.random() +
      "&invert_field_sensitivity=" +
      invertFieldSensitivity;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(tf, s);
  }, []);
};

export default useTrustedFormScript;
