import shortid from "shortid";
import * as types from "./types";

// append alert message object
const addAlert = (message, type) => {
  return {
    type: types.ADD_ALERT,
    alert: {
      message,
      type,
      key: shortid.generate()
    }
  };
};

const removeAlert = (key) => ({
  type: types.REMOVE_ALERT,
  key
});

/**
 * Only to be used in case of refresh/reload
 */
const removeAllAlert = () => ({
  type: types.REMOVE_ALL_ALERT
});

export { addAlert, removeAlert, removeAllAlert };
