import React from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import useStyles from "./SingleDropdownStyle";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { NUMBER_COUNT as numberLimit } from "../../../Constants";

export const Selector = ({ id, data, handleOnChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const checkDataList = () => {
    if (
      id === "medicine_count" ||
      id === "spouse_count" ||
      id === "children_count"
    ) {
      return ["none", ...numberLimit];
    } else if (id === "claim_count") {
      return ["none", 1, 2, 3];
    } else if (id === "person_count") {
      return [...numberLimit];
    } else {
      return [];
    }
  };
  const dataList = checkDataList();

  return (
    <div className={classes.selectorContainer}>
      <FormControl margin="dense" size="small" variant="outlined">
        <div>
          <Select
            IconComponent={(props) => (
              <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
            disableunderline
            name="count"
            displayEmpty
            value={data}
            onChange={(e) =>
              handleOnChange({ name: e.target.name, value: e.target.value })
            }
          >
            {/* <MenuItem disabled className={classes.selectOptions}>
              -
            </MenuItem> */}
            {dataList.map((number, index) => (
              <MenuItem
                key={index}
                value={number}
                className={classes.selectOptions}
              >
                {number}
              </MenuItem>
            ))}
          </Select>
          <span className={classes.horizontalSpacing}>{t("COUNT")}</span>
        </div>
      </FormControl>
    </div>
  );
};
