import React from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import useStyles from "./HeightStyle";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { INCHES as inches, FEET as feet } from "../../../Constants";

export const Selector = ({ heightData, handleOnChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.selectorContainer}>
      <FormControl margin="dense" size="small" variant="outlined">
        <div>
          <Select
            IconComponent={(props) => (
              <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
            disableunderline
            name="feet"
            displayEmpty
            value={heightData?.height_feet_temp}
            onChange={(e) =>
              handleOnChange({ name: e.target.name, value: e.target.value })
            }
          >
            <MenuItem disabled className={classes.selectOptions}>
              -
            </MenuItem>
            {feet.map((feet, index) => (
              <MenuItem
                key={index}
                value={feet}
                className={classes.selectOptions}
              >
                {feet}
              </MenuItem>
            ))}
          </Select>
          <span className={classes.horizontalSpacing}>{t("FEET")}</span>
        </div>
      </FormControl>

      <FormControl margin="dense" size="small" variant="outlined">
        <div>
          <Select
            IconComponent={(props) => (
              <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              getContentAnchorEl: null
            }}
            disableunderline
            name="inches"
            value={heightData?.height_inches_temp}
            displayEmpty
            onChange={(e) =>
              handleOnChange({ name: e.target.name, value: e.target.value })
            }
          >
            <MenuItem disabled className={classes.selectOptions}>
              -
            </MenuItem>
            {inches.map((inche, index) => (
              <MenuItem
                key={index}
                value={inche}
                className={classes.selectOptions}
              >
                {inche}
              </MenuItem>
            ))}
          </Select>
          <span className={classes.horizontalSpacing}>{t("INCHES")}</span>
        </div>
      </FormControl>
    </div>
  );
};
