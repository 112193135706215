import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ProgressBar from "./ProgressBar/ProgressBar.component";
import CallHelpPannel from "./CallHelpPannel/CallHelpPannel.component";

const useStyles = makeStyles((theme) => ({
  barHelperContainer: { transform: "translate(0,-25px)" },
  show: { display: "block" },
  hide: { display: "none" }
}));

const BarHelperContainer = ({ percentDone, phoneNumber, showPhone }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.barHelperContainer}>
      <Grid item xs={12}>
        <ProgressBar completed={percentDone} />
      </Grid>
      <Grid item xs={12} className={showPhone ? classes.show : classes.hide}>
        <CallHelpPannel phoneNumber={phoneNumber} />
      </Grid>
    </Grid>
  );
};

export default BarHelperContainer;
