import reducer from "./reducer";

import * as autoInsuranceSelectors from "./selectors";
import * as autoInsuranceOperations from "./operations";
import * as autoInsuranceActions from "./actions";
import * as autoInsuranceTypes from "./types";

export {
  autoInsuranceSelectors,
  autoInsuranceOperations,
  autoInsuranceActions,
  autoInsuranceTypes
};

export default reducer;
