import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  textSize: {
    fontSize: "18px",
    opacity: 1,
    fontWeight: theme.typography.weights.medium,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px"
    }
  },
  labelText: {
    color: theme.palette.primary.labelGrey,
    fontSize: "12px",
    opacity: 1
  },
  progressBarContainer: {
    width: "80%",
    maxWidth: "760px",
    margin: "auto",
    padding: "0 10px 8px 10px",
    borderRadius: "20px",
    boxShadow: `0px 3px 16px ${theme.palette.primary.shadow}`,
    backgroundColor: theme.palette.neutral[100],
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: "360px"
    }
  },
  progressText: {
    color: theme.palette.primary.darkText,
    fontSize: "24px",
    textAlign: "left",
    fontWeight: theme.typography.weights.medium,
    marginBottom: "2px",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "10px"
    }
  },
  progressTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0 8px"
    }
  },
  progressBar: {
    height: "12px",
    backgroundColor: theme.palette.primary.disabledGrey,
    opacity: 1,

    margin: "8px auto",
    borderRadius: "22px"
  },
  progressDone: ({ done }) => ({
    position: "relative",
    borderRadius: "22px",
    height: "20px",
    width: `${done}%`,
    maxWidth: "100%",
    backgroundColor: theme.palette.primary.main,
    transform: "translate(-1px,-6px)",
    boxShadow: `0px 4px 6px ${theme.palette.primary.light}`
  }),
  progressValue: {
    color: theme.palette.neutral[100],
    fontSize: "9px",
    position: "absolute",
    height: "23px",
    width: "23px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    right: 0,
    border: `dashed 2px ${theme.palette.neutral[100]}`
  },
  zeroProgressValue: { right: "-26px" }
}));
