import * as types from "./types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.ADD_ALERT: {
      if (action.alert && !action.alert.message) {
        return state;
      }
      // find alert with same message and type
      const isAlertExists = state.find((el) => {
        return (
          el.message === action.alert.message && el.type === action.alert.type
        );
      });

      // return same state if alert already exists
      if (isAlertExists) {
        return state;
      }

      return [...state, action.alert];
    }
    case types.REMOVE_ALERT:
      return state.filter((alert) => alert.key !== action.key);
    case types.REMOVE_ALL_ALERT:
      return [];
    default:
      return state;
  }
};

export default reducer;
