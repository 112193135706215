import React, { useState, useEffect } from "react";
import HeroComponent from "../../components/HeroComponent/Hero.component";
import ProgressbarHelperContainer from "../../components/ProgressbarHelperContainer";
import { useTranslation } from "react-i18next";
import AutoFormComponent from "./Forms/AutoInsuranceForm";
import HomeFormComponent from "./Forms/HomeForm";
import MedicareForm from "./Forms/MedicareForm";
import SolarForm from "./Forms/SolarForm";
import LifeInsuranceForm from "./Forms/LifeInsuranceForm";
import HealthInsuranceForm from "./Forms/HealthInsuranceForm";
import {
  saveSolarForm,
  saveHomeForm,
  saveAutoInsuranceForm,
  saveMedicareForm,
  saveLifeInsuranceForm,
  saveHealthInsurance
} from "../../services/api";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  INSURANCE_COMPANY as insurance_company_list,
  SOLAR_PARTNERS_LIST as solar_partner_list,
  MEDICARE_PARTNERS_LIST as medicare_partner_list,
  HOME_PARTNERS_LIST as home_partner_list,
  LIFE_INSURANCE_PARTNERS_LIST as life_insurance_partner_list,
  HEALTH_INSURANCE_PARTNERS_LIST as health_insurance_partner_list
} from "../../Constants";
import { FindIndexValue, trimHtml } from "../../services/utility";
import {
  initialVehicleVaule,
  initialDriverVaule,
  initialRequestedPolicy,
  initialCurrentPolicy
} from "./InitialState/Initial_auto_Insurance_fields";
import {
  initialHomeClaim,
  initialHomePersonalVaule,
  initialHomePropertiesValue,
  initialHomeRequestedPolicy,
  initialHomeCurrentPolicy
} from "./InitialState/Initial_home";
import {
  initialLifeRequestedPolicy,
  initialLifeCurrentPolicy,
  initialLifePerson
} from "./InitialState/Initial_life_insurance";
import {
  initialHealthCurrentPolicy,
  initialHealthMedicalData,
  initialHealthSpouseChildData
} from "./InitialState/Initial_health_insurance";
import {
  initialContactFields,
  initialSolarField,
  initailMedicareFields
} from "./InitialState/Initial_Contact_Solar_Medicare";
import ModalPopUp from "./../../components/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { alertOperations } from "../../store/alerts";
// import useJornayaScript from "./JornayaTokenGenerationScript";

const useStyles = makeStyles(() => ({
  formStyles: { marginTop: -15 }
}));
const MainPage = (props) => {
  let params = useParams();
  const bannerImagePath = props.bannerImagePath;
  const [completed, setCompleted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [solarConfig, setSolarConfig] = useState();
  const [autoConfig, setAutoConfig] = useState();
  const [medicareConfig, setMedicareConfig] = useState();
  const [homeConfig, setHomeConfig] = useState();
  const [lifeConfig, setLifeConfig] = useState();
  const [healthConfig, setHealthConfig] = useState();
  const [driversCount, setDriversCount] = useState({ drivers_count: "" });
  const [homeClaimCount, setHomeClaimCount] = useState({ claim_count: "" });
  const [enableThanks, setEnableThanks] = useState(false);
  const [vertical, setVertical] = useState(props.vertical); // sets vertical matching url query parameter
  const [showPartnerList, setShowPartnerList] = useState(false); // partner popup handler
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dataFields, setDataFields] = useState(initialSolarField);
  const [medicareFields, setMedicareFields] = useState(initailMedicareFields);

  const { t } = useTranslation();
  const [configData, setConfigData] = useState();
  const [contactFields, setContactFields] = useState(initialContactFields);

  const [questions, setQuestions] = useState([]);
  const [autoInsuranceData, setAutoInsuranceData] = useState({});
  const [section, setSection] = useState("vehicle");
  const [homeSection, setHomeSection] = useState("home personal"); // initail section for home insurance
  const [healthSection, setHealthSection] = useState("health medical data"); // initail section for health insurance
  const [lifeInsuranceSection, setLifeInsuranceSection] =
    useState("life person count"); // initail section for home insurance

  const handleSetSection = (section) => setSection(section);
  const handleSetHomeSection = (homeSection) => setHomeSection(homeSection);
  const handleSetLifeSection = (lifeSection) =>
    setLifeInsuranceSection(lifeSection);

  const handleSetHealthSection = (healthSection) =>
    setHealthSection(healthSection);
  // navigates form using url slug
  useEffect(() => {
    if (
      params?.vertical === "solar" ||
      params?.vertical === "medicare" ||
      params?.vertical === "auto_insurance" ||
      params?.vertical === "home" ||
      params?.vertical === "life_insurance" ||
      params?.vertical === "health_insurance"
    ) {
      setVertical(params?.vertical);
    }
  }, [params?.vertical, vertical]);

  useEffect(() => {
    if (
      solarConfig ||
      autoConfig ||
      medicareConfig ||
      homeConfig ||
      lifeConfig ||
      healthConfig
    ) {
      if (vertical === "health_insurance") {
        healthConfig?.vertical && setConfigData(healthConfig);
      }
      if (vertical === "auto_insurance") {
        autoConfig?.vertical && setConfigData(autoConfig);
      }
      if (vertical === "solar") {
        solarConfig?.vertical && setConfigData(solarConfig);
      }
      if (vertical === "medicare") {
        medicareConfig?.vertical && setConfigData(medicareConfig);
      }
      if (vertical === "home") {
        homeConfig?.vertical && setConfigData(homeConfig);
      }
      if (vertical === "life_insurance") {
        lifeConfig?.vertical && setConfigData(lifeConfig);
      }
    }
  }, [
    params?.vertical,
    vertical,
    solarConfig,
    autoConfig,
    medicareConfig,
    homeConfig,
    lifeConfig,
    healthConfig
  ]);

  var element = document.querySelector("#approot");
  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.type === "attributes") {
        const solarAttri = element.getAttribute("solar");
        const autoAttri = element.getAttribute("auto");
        const medicareAttri = element.getAttribute("medicare");
        const homeAttri = element.getAttribute("home");
        const lifeAttri = element.getAttribute("life");
        const healthAttri = element.getAttribute("health");
        if (solarAttri) {
          const json = JSON.parse(solarAttri);
          setSolarConfig(json);
        }
        if (autoAttri) {
          const json = JSON.parse(autoAttri);
          setAutoConfig(json);
        }
        if (medicareAttri) {
          const json = JSON.parse(medicareAttri);
          setMedicareConfig(json);
        }
        if (homeAttri) {
          const json = JSON.parse(homeAttri);
          setHomeConfig(json);
        }
        if (lifeAttri) {
          const json = JSON.parse(lifeAttri);
          setLifeConfig(json);
        }
        if (healthAttri) {
          const json = JSON.parse(healthAttri);
          setHealthConfig(json);
        }
      }
    });
  });
  if (element) {
    observer.observe(element, {
      attributes: true //configure it to listen to attribute changes
    });
  }

  // additional questions which were not in auto.config file
  // initial vehicle question
  const vehicleAdditional = {
    id: "vehicleAdditional",
    title: "Would you like to add a 2nd vehicle?",
    type: "yesno"
  };
  // initial current policy question
  const currentPolicyAdditional = [
    {
      id: "currentPolicyAdditional",
      title: "Do you have car insurance?",
      type: "yesno"
    },
    {
      id: "no_insurance_reason",
      title: "What is the reason you don't currently have insurance?",
      type: "radio",
      values: [
        "I don't currently have a car",
        "Policy lapsed",
        "Suspended license",
        "Tickets",
        "Accidents"
      ]
    }
  ];

  // vehicle section
  const [vehicleFields, setVehicleFields] = useState(initialVehicleVaule);
  const resetVehicleSection = () => setVehicleFields(initialVehicleVaule);

  // ticket and driver section
  const [driverTicketSection, setDriverTicketSection] =
    useState(initialDriverVaule);
  const resetDriverSection = () => setDriverTicketSection(initialDriverVaule);

  // requested policy section
  const [requestedPolicySection, setRequestedPolicySection] = useState(
    initialRequestedPolicy
  );
  const resetRequestedPolicySection = () =>
    setRequestedPolicySection(initialRequestedPolicy);

  // current policy section
  const [currentPolicySection, setCurrentPolicySection] =
    useState(initialCurrentPolicy);
  const resetCurrentPolicySection = () =>
    setCurrentPolicySection(initialCurrentPolicy);

  // home form section
  // home claim section
  const [totalHomeClaim, setTotalHomeClaim] = useState([]);
  const [homeClaimSection, setHomeClaimSection] = useState(initialHomeClaim);
  const resethomeClaimSection = () => setHomeClaimSection(initialHomeClaim);

  // home personal section
  const [homePersonalSection, setHomePersonalSection] = useState(
    initialHomePersonalVaule
  );
  // const resetHomePersonalSection = () =>
  //   setHomePersonalSection(initialHomePersonalVaule);

  // home properties section
  const [homePropertiesSection, setHomePropertiesSection] = useState(
    initialHomePropertiesValue
  );
  // const resetHomePropertiesSection = () =>
  //   setHomePropertiesSection(initialHomePropertiesValue);

  // home requested policy section
  const [homeRequestedPolicySection, setHomeRequestedPolicySection] = useState(
    initialHomeRequestedPolicy
  );
  // const resetHomeRequestedPolicySection = () =>
  //   setHomeRequestedPolicySection(initialHomeRequestedPolicy);

  // home current policy section
  const [homeCurrentPolicySection, setHomeCurrentPolicySection] = useState(
    initialHomeCurrentPolicy
  );
  const resetHomeCurrentPolicySection = () =>
    setHomeCurrentPolicySection({
      ...initialHomeCurrentPolicy,
      current_insurance_check: "No"
    });

  // Life Insurnace field section
  const [lifePersonArr, setLifePersonArr] = useState([]);
  const [lifePersonCount, setLifePersonCount] = useState({ person_count: 0 });
  const [lifePersonSection, setLifePersonSection] = useState(initialLifePerson);
  const resetLifePersonSection = () =>
    setLifePersonSection({ ...initialLifePerson, medicine_detail: [] });

  const [lifeCurrentPolicySection, setLifeCurrentPolicySection] = useState(
    initialLifeCurrentPolicy
  );
  const [lifeRequestedPolicySection, setLifeRequestedPolicySection] = useState(
    initialLifeRequestedPolicy
  );

  // Health Insurance field section
  const [healthChildArr, setHealthChildArr] = useState([]);
  const [healthCurrentPolicySection, setHealthCurrentPolicySection] = useState(
    initialHealthCurrentPolicy
  );

  const resetHealtCurrentPolicy = () =>
    setHealthCurrentPolicySection({
      ...initialHealthCurrentPolicy,
      current_insurance_check:
        healthCurrentPolicySection?.current_insurance_check
    });
  const resetHealtSpouseSection = () =>
    setHealthSpouseSection({
      ...initialHealthSpouseChildData,
      spouse_check: healthSpouseSection?.spouse_check
    });

  const resetHealtChildSection = () =>
    setHealthChildData(initialHealthSpouseChildData);

  const [healthMedicalDataSection, setHealthMedicalDataSection] = useState(
    initialHealthMedicalData
  );
  const [healthSpouseSection, setHealthSpouseSection] = useState(
    initialHealthSpouseChildData
  );
  const [healthChildData, setHealthChildData] = useState(
    initialHealthSpouseChildData
  );
  const [healthChildCount, setHealthChildCount] = useState({
    children_count: null
  });

  // medicare submit
  const getMedicareQuotes = (e) => {
    e.preventDefault();
    let contactData = contactFields;
    delete contactData.maskedPhone;
    let medicareFields_obj = medicareFields;
    medicareFields_obj.height =
      medicareFields.height_feet_temp * 12 + medicareFields.height_inches_temp;

    medicareFields_obj.tobacco =
      medicareFields.tobacco === "No" || medicareFields.tobacco === false
        ? false
        : true;
    medicareFields_obj.gender = medicareFields.gender.charAt(0);
    if (medicareFields_obj?.spouse_first_name === null) {
      delete medicareFields_obj.spouse_first_name;
      delete medicareFields_obj.spouse_last_name;
      delete medicareFields_obj.spouse_birth_date;
    }
    const asArray = Object.entries(medicareFields_obj);
    const filtered = asArray.filter(([key, value]) => !key.includes("_temp"));
    const formattedData = Object.fromEntries(filtered);

    setLoading(true);
    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;
    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: formattedData
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };

    saveMedicareForm(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);

        if (result?.data?.status === "success") {
          window.submission = postData;
          //reset screen
          setEnableThanks(true);
        } else {
          console.error("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // solar submit
  const getQuotes = (e) => {
    e.preventDefault();
    let contactData = contactFields;
    delete contactData.maskedPhone;

    setLoading(true);

    const formatYesNo = (value) =>
      value === "No" || value === false ? false : true;

    let formattedDataFields = dataFields;
    formattedDataFields.own_property = formatYesNo(
      formattedDataFields.own_property
    );

    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;

    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: formattedDataFields
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };

    saveSolarForm(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);

        if (result?.data?.status === "success") {
          window.submission = postData;
          setEnableThanks(true);
        } else {
          console.error("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((response) => {
        setLoading(false);
      });
  };
  // home insurance submit
  const homeInsuranceSubmit = (e) => {
    e.preventDefault();
    let contactData = contactFields;
    delete contactData.maskedPhone;

    const formattedClaimArray = totalHomeClaim.map((claim) => {
      const claim_obj = Object.entries(claim);
      const filter_claim_obj = claim_obj.filter(
        ([key, value]) => !key.includes("_temp")
      );
      return Object.fromEntries(filter_claim_obj);
    });

    // removing development variables from object
    const personalDataAsArray = Object.entries(homePersonalSection);
    const filteredPersonalData = personalDataAsArray.filter(
      ([key, value]) => !key.includes("_temp")
    );
    let formattedPersonalData = Object.fromEntries(filteredPersonalData);
    formattedPersonalData.gender = formattedPersonalData.gender.charAt(0);
    const propertiesDataAsArray = Object.entries(homePropertiesSection);
    const filteredPropertiesData = propertiesDataAsArray.filter(
      ([key, value]) => !key.includes("_temp")
    );
    const formattedPropertiesData = Object.fromEntries(filteredPropertiesData);

    const currentPolicyDataFormat = () => {
      if (
        homeCurrentPolicySection?.current_insurance_check?.toLocaleLowerCase() ===
        "yes"
      ) {
        let formatCurrentPolicyData = Object.entries(homeCurrentPolicySection);
        const filteredCurrentPolicyData = formatCurrentPolicyData.filter(
          ([key, value]) => !key.includes("_temp")
        );
        formatCurrentPolicyData = Object.fromEntries(filteredCurrentPolicyData);
        delete formatCurrentPolicyData.current_insurance_check;
        return formatCurrentPolicyData;
      } else {
        return {};
      }
    };

    const propertiesFormattedValue = formattedClaimArray?.length
      ? [
          {
            ...formattedPropertiesData,
            claims: formattedClaimArray
          }
        ]
      : [
          {
            ...formattedPropertiesData
          }
        ];
    const dataFields = {
      ...formattedPersonalData,
      properties: propertiesFormattedValue,
      requested_policy: {
        ...homeRequestedPolicySection
      },

      current_policy: currentPolicyDataFormat()
    };
    setLoading(true);
    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;

    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: dataFields
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };

    saveHomeForm(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);
        if (result?.data?.status === "success") {
          window.submission = postData;
          //reset screen
          setEnableThanks(true);
        } else {
          console.log("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // auto insurance submit
  const submitAutoInsurance = (e) => {
    e.preventDefault();
    let policy = currentPolicySection;
    let copyAutoInsuranceData = autoInsuranceData;
    let driversArr = copyAutoInsuranceData.drivers.map((driver) => {
      // removing developer used variables
      delete driver.birth_date_day_temp;
      delete driver.birth_date_month_temp;
      delete driver.birth_date_year_temp;
      driver.gender = driver.gender.charAt(0);
      driver.license_state = contactFields.state;
      driver.license_ever_suspended =
        driver.license_ever_suspended.toString().toLowerCase() === "yes" ||
        driver.license_ever_suspended.toString().toLowerCase() === "true"
          ? true
          : false;
      return driver;
    });
    let contactData = contactFields;
    // removing developer used variables
    delete contactData.maskedPhone;

    delete policy.no_insurance_reason;

    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;
    const data = {
      drivers: driversArr,
      vehicles: autoInsuranceData.vehicle,
      current_policy: policy,
      requested_policy: requestedPolicySection
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };
    setLoading(true);
    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: data
    };
    saveAutoInsuranceForm(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);

        if (result?.data?.status === "success") {
          window.submission = postData;
          //reset screen
          setEnableThanks(true);
        } else {
          console.error("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // life insurance submit
  const lifeInsuranceSubmit = (e) => {
    e.preventDefault();

    let contactData = contactFields;
    // removing developer used variables
    delete contactData.maskedPhone;
    const formatYesNo = (value) =>
      value === "No" || value === false ? false : true;

    const duplicateMedicationsArr = lifePersonArr.slice(0);
    const medications = duplicateMedicationsArr
      .map((data) => data?.medicine_detail)
      .flat();

    const duplicatePersonArr = lifePersonArr
      .map((data) => {
        data.height = data.height_feet_temp * 12 + data.height_inches_temp;
        data.gender = data.gender.charAt(0);
        data.student = formatYesNo(data.student);
        data.expectant_parent = formatYesNo(data.expectant_parent);
        data.relative_heart = formatYesNo(data.relative_heart);
        data.tobacco = formatYesNo(data.tobacco);
        data.relative_cancer = formatYesNo(data.relative_cancer);
        data.hospitalized = formatYesNo(data.hospitalized);
        data.ongoing_medical_treatment = formatYesNo(
          data.ongoing_medical_treatment
        );
        data.dui = formatYesNo(data.dui);
        data.previously_denied = formatYesNo(data.previously_denied);
        data.hazard_pilot = formatYesNo(data.hazard_pilot);
        data.hazard_felony = formatYesNo(data.hazard_felony);
        data.hazardous_activity = formatYesNo(data.hazardous_activity);
        data.hazard_other = formatYesNo(data.hazard_other);

        return data;
      })
      .filter((data) => {
        delete data.medicine_detail;
        delete data.birth_date_day_temp;
        delete data.birth_date_month_temp;
        delete data.birth_date_year_temp;
        delete data.height_feet_temp;
        delete data.height_inches_temp;
        delete data.comment;
        delete data.dosage;
        delete data.frequency;
        delete data.medicine_count;
        return data;
      });

    const currentPolicyDataAsArray = Object.entries(lifeCurrentPolicySection);
    const filteredCurrentPolicyData = currentPolicyDataAsArray.filter(
      ([key, value]) => !key.includes("_temp")
    );
    const formattedCurrentPolicyData = Object.fromEntries(
      filteredCurrentPolicyData
    );

    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;

    const data = {
      persons: duplicatePersonArr,
      medication: medications,
      current_policy: formattedCurrentPolicyData,
      requested_policy: lifeRequestedPolicySection
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };
    setLoading(true);
    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: data
    };
    saveLifeInsuranceForm(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);

        if (result?.data?.status === "success") {
          window.submission = postData;
          //reset screen
          setEnableThanks(true);
        } else {
          console.error("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // health insurance submit
  const healthInsuranceSubmit = (e) => {
    e.preventDefault();

    let contactData = contactFields;
    // removing developer used variables
    delete contactData.maskedPhone;
    const formatYesNo = (value) =>
      value === "No" || value === false ? false : true;

    let duplicateHealthMedicalData = healthMedicalDataSection;
    duplicateHealthMedicalData.gender =
      healthMedicalDataSection?.gender?.charAt(0);
    duplicateHealthMedicalData.height =
      healthMedicalDataSection?.height_feet_temp * 12 +
      healthMedicalDataSection?.height_inches_temp;
    duplicateHealthMedicalData.bmi = Math.round(
      (healthMedicalDataSection?.weight * 703) /
        (duplicateHealthMedicalData?.height *
          duplicateHealthMedicalData?.height)
    );
    duplicateHealthMedicalData.currently_employed =
      duplicateHealthMedicalData?.currently_employed
        ? formatYesNo(duplicateHealthMedicalData?.currently_employed)
        : null;
    duplicateHealthMedicalData.hospitalized =
      duplicateHealthMedicalData?.hospitalized
        ? formatYesNo(duplicateHealthMedicalData?.hospitalized)
        : null;
    duplicateHealthMedicalData.ongoing_medical_treatment =
      duplicateHealthMedicalData?.ongoing_medical_treatment
        ? formatYesNo(duplicateHealthMedicalData?.ongoing_medical_treatment)
        : null;
    duplicateHealthMedicalData.prescriptions =
      duplicateHealthMedicalData?.prescriptions
        ? formatYesNo(duplicateHealthMedicalData?.prescriptions)
        : null;
    duplicateHealthMedicalData.previously_denied =
      duplicateHealthMedicalData?.previously_denied
        ? formatYesNo(duplicateHealthMedicalData?.previously_denied)
        : null;
    duplicateHealthMedicalData.student = duplicateHealthMedicalData?.student
      ? formatYesNo(duplicateHealthMedicalData?.student)
      : null;
    duplicateHealthMedicalData.tobacco = duplicateHealthMedicalData?.tobacco
      ? formatYesNo(duplicateHealthMedicalData?.tobacco)
      : null;

    duplicateHealthMedicalData.number_in_household =
      1 + healthSpouseSection?.spouse_check?.toLowerCase() === "no"
        ? 0
        : 1 + healthChildArr?.length;

    delete duplicateHealthMedicalData?.birth_date_day_temp;
    delete duplicateHealthMedicalData?.birth_date_month_temp;
    delete duplicateHealthMedicalData?.birth_date_year_temp;
    delete duplicateHealthMedicalData?.height_feet_temp;
    delete duplicateHealthMedicalData?.height_inches_temp;

    let duplicateCurrenctPolicy =
      healthCurrentPolicySection?.current_insurance_check?.toLowerCase() ===
      "yes"
        ? healthCurrentPolicySection
        : {};

    if (
      healthCurrentPolicySection?.current_insurance_check?.toLowerCase() ===
      "yes"
    ) {
      delete duplicateCurrenctPolicy?.expiration_date_day_temp;
      delete duplicateCurrenctPolicy?.expiration_date_month_temp;
      delete duplicateCurrenctPolicy?.expiration_date_year_temp;

      delete duplicateCurrenctPolicy?.insured_since_day_temp;
      delete duplicateCurrenctPolicy?.insured_since_month_temp;
      delete duplicateCurrenctPolicy?.insured_since_year_temp;
    }
    delete duplicateCurrenctPolicy?.current_insurance_check;

    let duplicateSpouseSection =
      healthSpouseSection?.spouse_check?.toLowerCase() === "yes"
        ? healthSpouseSection
        : {};
    if (healthSpouseSection?.spouse_check?.toLowerCase() === "yes") {
      duplicateSpouseSection.height =
        healthSpouseSection?.height_feet_temp * 12 +
        healthSpouseSection?.height_inches_temp;
      duplicateSpouseSection.gender = duplicateSpouseSection?.gender?.charAt(0);
      duplicateSpouseSection.student = formatYesNo(
        duplicateSpouseSection?.student
      );
      duplicateSpouseSection.tobacco = formatYesNo(
        duplicateSpouseSection?.tobacco
      );
      delete duplicateSpouseSection?.birth_date_day_temp;
      delete duplicateSpouseSection?.birth_date_month_temp;
      delete duplicateSpouseSection?.birth_date_year_temp;
      delete duplicateSpouseSection?.height_feet_temp;
      delete duplicateSpouseSection?.height_inches_temp;
      delete duplicateSpouseSection?.spouse_check;
    }

    const duplicateChildArr = healthChildArr?.length
      ? healthChildArr
          .map((data) => {
            data.height =
              data?.height_feet_temp * 12 + data?.height_inches_temp;
            data.gender = data?.gender.charAt(0);
            data.student = formatYesNo(data?.student);
            data.tobacco = formatYesNo(data?.tobacco);
            return data;
          })
          .filter((data) => {
            delete data?.birth_date_day_temp;
            delete data?.birth_date_month_temp;
            delete data?.birth_date_year_temp;
            delete data?.height_feet_temp;
            delete data?.height_inches_temp;
            return data;
          })
      : [];

    const trusted_form_url_value = document.querySelector(
      "#xxTrustedFormCertUrl_0"
    ).value;

    const data = {
      ...duplicateHealthMedicalData,
      spouse: duplicateSpouseSection,
      children: duplicateChildArr,
      current_policy: duplicateCurrenctPolicy
    };
    const baseURL = configData?.url?.base_url || "https://api.jangl.com/";
    const headers = {
      Authorization: configData?.auth_token
    };
    setLoading(true);
    const postData = {
      meta: {
        trusted_form_cert_url: trusted_form_url_value,
        lead_id_code: window.jornayaToken,
        landing_page_url: window.location.href,
        tcpa_compliant: true,
        tcpa_consent_text: window.tcpa_consent_text
      },
      contact: contactData,
      data: data
    };
    saveHealthInsurance(postData, baseURL, headers)
      .then((result) => {
        setLoading(false);
        if (result?.data?.status === "success") {
          window.submission = postData;
          //reset screen
          setEnableThanks(true);
        } else {
          console.error("error_response:", result?.data?.errors?.data);
          dispatch(alertOperations.addAlert("Form data error", "error"));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    let visibleQuestions = [];
    let vehicleSectionArr = [];
    let ticketAccidentArr = [];
    let currentPolicySectionArr = [];
    let requestedPolicySectionArr = [];
    let driverSectionArr = [];
    if (configData && configData.sections) {
      vertical === "auto_insurance"
        ? configData.sections.forEach((item) => {
            if (item.id !== "contact_1") {
              if (item.id === "vehicle") {
                if (item?.enabled && item?.questions?.length) {
                  const part = item?.questions?.filter(
                    (question) => question.visible
                  );
                  vehicleSectionArr = vehicleSectionArr.concat(part);
                }
              }

              if (item.id === "current policy") {
                if (item?.enabled && item?.questions?.length) {
                  const part = item?.questions?.filter(
                    (question) => question.visible
                  );
                  currentPolicySectionArr =
                    currentPolicySectionArr.concat(part);
                }
              }
              if (item.id === "ticket" || item.id === "major violation") {
                if (item?.enabled && item?.questions?.length) {
                  const part = item?.questions?.filter(
                    (question) => question.visible
                  );
                  ticketAccidentArr = ticketAccidentArr.concat(part);
                }
              }

              if (item.id === "driver") {
                if (item?.enabled && item?.questions?.length) {
                  const part = item?.questions?.filter(
                    (question) => question.visible
                  );
                  driverSectionArr = driverSectionArr.concat(part);
                }
              }
              if (item.id === "requested policy") {
                if (item?.enabled && item?.questions?.length) {
                  const part = item?.questions?.filter(
                    (question) => question.visible
                  );
                  requestedPolicySectionArr =
                    requestedPolicySectionArr.concat(part);
                }
              }
            }
          })
        : configData.sections.forEach((item) => {
            if (item?.id !== "contact_1") {
              if (item?.enabled && item?.questions?.length) {
                const part = item.questions?.filter(
                  (question) => question.visible
                );
                visibleQuestions = part.length
                  ? visibleQuestions.concat(part)
                  : visibleQuestions;
              }
            }
          });
    }

    //combining all auto-insurance question in a single question array
    if (vertical === "auto_insurance") {
      ticketAccidentArr = ticketAccidentArr.concat(driverSectionArr);
      requestedPolicySectionArr =
        requestedPolicySectionArr.concat(ticketAccidentArr);
      currentPolicySectionArr = currentPolicySectionArr.concat(
        requestedPolicySectionArr
      );
      vehicleSectionArr = vehicleSectionArr.concat(currentPolicySectionArr);
      visibleQuestions = visibleQuestions.concat(vehicleSectionArr);

      visibleQuestions.splice(
        FindIndexValue(visibleQuestions, "annual_miles") + 1,
        0,
        vehicleAdditional
      );
      visibleQuestions.splice(
        FindIndexValue(visibleQuestions, "insurance_company"),
        0,
        ...currentPolicyAdditional
      );
    }
    setQuestions(visibleQuestions);
  }, [configData]);

  // choose local states as per section for Auto
  const selectSectionFieldData = (section) => {
    if (section === "vehicle") return vehicleFields;
    if (section === "current policy") return currentPolicySection;
    if (section === "requested policy") return requestedPolicySection;
    if (section === "driver") return driverTicketSection;
    if (section === "drivers count section") return driversCount;
  };

  // choose local states handler as per section for Auto
  const selectSectionFieldHandler = (section) => {
    if (section === "vehicle") return setVehicleFields;
    if (section === "current policy") return setCurrentPolicySection;
    if (section === "requested policy") return setRequestedPolicySection;
    if (section === "driver") return setDriverTicketSection;
    if (section === "drivers count section") return setDriversCount;
  };

  // choose local states as per section for Home
  const selectHomeSectionFieldData = (homeSection) => {
    if (homeSection === "home claim count") return homeClaimCount;
    if (homeSection === "home claim") return homeClaimSection;
    if (homeSection === "home personal") return homePersonalSection;
    if (homeSection === "home properties") return homePropertiesSection;
    if (homeSection === "home current policy") return homeCurrentPolicySection;
    if (homeSection === "home requested policy")
      return homeRequestedPolicySection;
  };

  // choose local states handler as per section for Home
  const selectHomeSectionFieldHandler = (homeSection) => {
    if (homeSection === "home claim count") return setHomeClaimCount;
    if (homeSection === "home claim") return setHomeClaimSection;
    if (homeSection === "home personal") return setHomePersonalSection;
    if (homeSection === "home properties") return setHomePropertiesSection;
    if (homeSection === "home current policy")
      return setHomeCurrentPolicySection;
    if (homeSection === "home requested policy")
      return setHomeRequestedPolicySection;
  };

  // choose local states as per section for Life insurance
  const selectLifeSectionFieldData = (lifeInsuranceSection) => {
    if (lifeInsuranceSection === "life person count") return lifePersonCount;
    if (lifeInsuranceSection === "life person") return lifePersonSection;
    if (lifeInsuranceSection === "life current policy")
      return lifeCurrentPolicySection;
    if (lifeInsuranceSection === "life requested policy")
      return lifeRequestedPolicySection;
  };

  // choose local states handler as per section for Home
  const selectLifeSectionFieldHandler = (lifeInsuranceSection) => {
    if (lifeInsuranceSection === "life person count") return setLifePersonCount;
    if (lifeInsuranceSection === "life person") return setLifePersonSection;
    if (lifeInsuranceSection === "life current policy")
      return setLifeCurrentPolicySection;
    if (lifeInsuranceSection === "life requested policy")
      return setLifeRequestedPolicySection;
  };

  // choose local states as per section for Health insurance
  const selectHealthSectionFieldData = (healthSection) => {
    if (healthSection === "health current policy")
      return healthCurrentPolicySection;
    if (healthSection === "health medical data")
      return healthMedicalDataSection;
    if (healthSection === "health spouse data") return healthSpouseSection;
    if (healthSection === "health child data") return healthChildData;
    if (healthSection === "health child count") return healthChildCount;
  };

  // choose local states handler as per section for health insurance
  const selectHealthSectionFieldHandler = (healthSection) => {
    if (healthSection === "health current policy")
      return setHealthCurrentPolicySection;
    if (healthSection === "health medical data")
      return setHealthMedicalDataSection;
    if (healthSection === "health spouse data") return setHealthSpouseSection;
    if (healthSection === "health child data") return setHealthChildData;
    if (healthSection === "health child count") return setHealthChildCount;
  };

  const handleSubmit = (e) => {
    vertical === "solar" && getQuotes(e);
    vertical === "medicare" && getMedicareQuotes(e);
    vertical === "auto_insurance" && submitAutoInsurance(e);
    vertical === "home" && homeInsuranceSubmit(e);
    vertical === "life_insurance" && lifeInsuranceSubmit(e);
    vertical === "health_insurance" && healthInsuranceSubmit(e);
  };

  const fetchPartnerDataList = (vertical) => {
    if (vertical === "solar")
      return configData?.url?.partner_companies || solar_partner_list;
    if (vertical === "medicare")
      return configData?.url?.partner_companies || medicare_partner_list;
    if (vertical === "auto_insurance")
      return configData?.url?.partner_companies || insurance_company_list;
    if (vertical === "home")
      return configData?.url?.partner_companies || home_partner_list;
    if (vertical === "life_insurance")
      return configData?.url?.partner_companies || life_insurance_partner_list;
    if (vertical === "health_insurance")
      return (
        configData?.url?.partner_companies || health_insurance_partner_list
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <HeroComponent
        title={configData?.title}
        vertical={configData?.vertical}
        bannerImagePath={bannerImagePath}
      />
      {!enableThanks && (
        <>
          <ModalPopUp
            title={t("PARTNER_COMPANIES")}
            dataList={fetchPartnerDataList(vertical)}
            open={showPartnerList}
            closeHandler={() => setShowPartnerList(false)}
          />

          <ProgressbarHelperContainer
            percentDone={completed}
            phoneNumber={configData?.phone}
            showPhone={configData?.showPhone}
          />
          <form
            onSubmit={handleSubmit}
            className={classes.formStyles}
            id="formElement"
          >
            {questions.length && vertical === "solar" && (
              <SolarForm
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                questionFieldValues={dataFields}
                questionFieldHandler={setDataFields}
                clientName={configData?.clientName}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                vertical={"solar"}
                showPartnersList={() => setShowPartnerList(true)}
              />
            )}
            {questions.length && vertical === "health_insurance" && (
              <HealthInsuranceForm
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                questionFieldValues={selectHealthSectionFieldData(
                  healthSection
                )}
                questionFieldHandler={selectHealthSectionFieldHandler(
                  healthSection
                )}
                spouseDetail={healthSpouseSection}
                clientName={configData?.clientName}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                vertical={"health_insurance"}
                sectionName={healthSection}
                currentPolicyData={healthCurrentPolicySection}
                setSectionName={handleSetHealthSection}
                childCount={healthChildCount?.children_count}
                childDataArr={healthChildArr}
                handleHealthChildArr={setHealthChildArr}
                showPartnersList={() => setShowPartnerList(true)}
                handleResetHealthCurrentPolicy={resetHealtCurrentPolicy}
                handleResetHealthSpouseSection={resetHealtSpouseSection}
                handleResetHealthChildSection={resetHealtChildSection}
              />
            )}
            {questions.length && vertical === "life_insurance" && (
              <LifeInsuranceForm
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                sectionName={lifeInsuranceSection}
                questionFieldValues={selectLifeSectionFieldData(
                  lifeInsuranceSection
                )}
                questionFieldHandler={selectLifeSectionFieldHandler(
                  lifeInsuranceSection
                )}
                personDataArr={lifePersonArr}
                personCount={lifePersonCount?.person_count}
                handlePersonDataArr={setLifePersonArr}
                handleResetPersonSection={resetLifePersonSection}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                clientName={configData?.clientName}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                vertical={"life_insurance"}
                setSectionName={handleSetLifeSection}
                showPartnersList={() => setShowPartnerList(true)}
              />
            )}
            {questions.length && vertical === "medicare" && (
              <MedicareForm
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                questionFieldValues={medicareFields}
                questionFieldHandler={setMedicareFields}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                clientName={configData?.clientName}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                vertical={"medicare"}
                showPartnersList={() => setShowPartnerList(true)}
              />
            )}
            {questions.length && vertical === "home" && (
              <HomeFormComponent
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                questionFieldValues={selectHomeSectionFieldData(homeSection)}
                questionFieldHandler={selectHomeSectionFieldHandler(
                  homeSection
                )}
                totalHomeClaimArray={totalHomeClaim}
                totalHomeClaimHandler={setTotalHomeClaim}
                clientName={configData?.clientName}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                vertical={"home"}
                count={homeClaimCount}
                showPartnersList={() => setShowPartnerList(true)}
                setSectionName={handleSetHomeSection}
                resetHandlerhomeClaimSection={resethomeClaimSection}
                resetHandlerCurrentPolicySection={resetHomeCurrentPolicySection}
              />
            )}
            {questions.length && vertical === "auto_insurance" && (
              <AutoFormComponent
                contactFieldValues={contactFields}
                contactFieldHandler={setContactFields}
                questionFieldValues={selectSectionFieldData(section)}
                questionFieldHandler={selectSectionFieldHandler(section)}
                sectionName={section}
                setSectionName={handleSetSection}
                autoInsuranceDataHandler={setAutoInsuranceData}
                autoInsurance={autoInsuranceData}
                handleVehicleReset={resetVehicleSection}
                handleCurrentPolicyReset={resetCurrentPolicySection}
                handleRequestedPolicyReset={resetRequestedPolicySection}
                handleDriverSectionReset={resetDriverSection}
                jornayaCampaignID={configData?.jornaya_campaign_id}
                clientName={configData?.clientName}
                questions={questions}
                setCompleted={setCompleted}
                loading={loading}
                url={{
                  ...configData?.url,
                  tcpa_consent_text: configData?.tcpa_consent_text
                }}
                vehicleFields={vehicleFields}
                driversCount={driversCount}
                vertical={"auto_insurance"}
                showPartnersList={() => setShowPartnerList(true)}
              />
            )}
          </form>
        </>
      )}
      {enableThanks && (
        <>
          <h5>
            <b>
              {configData?.thanksMessage ? configData.thanksMessage : "Thanks"}
            </b>
          </h5>
          {configData?.thanksHtml && (
            <iframe
              style={{ width: "100%", height: "100%", border: "none" }}
              srcDoc={trimHtml(configData.thanksHtml)}
            ></iframe>
          )}
        </>
      )}
    </div>
  );
};

export default MainPage;
