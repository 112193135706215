import React, { useEffect, useState } from "react";
import AddressQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/AddressQuestion.component";
import ZipPhoneNumber from "../../../components/QuestionTypes/TextInputTypeComponent/ZipPhoneNumber.component";
import NameField from "../../../components/QuestionTypes/TextInputTypeComponent/NameField.component";
import EmailSubmit from "../../../components/QuestionTypes/TextInputTypeComponent/EmailSubmit.component";
import DOB from "../../../components/QuestionTypes/DOB/Dob.component";
import YesNoQuestion from "../../../components/QuestionTypes/YesNoQuestion/YesNoQuestion.component";
import UtilityField from "../../../components/QuestionTypes/TextInputTypeComponent/UtilityField.component.";
import MultichoiceQuestion from "../../../components/MultichoiceQuestion/MultichoiceQuestion";
import TextFieldQuestion from "../../../components/QuestionTypes/TextInputTypeComponent/TextField.component";
import {
  FindIndexValue,
  FetchTodaysDate3YearsAgo
} from "../../../services/utility";
import { commonSelectors, commonOperations } from "../../../store/common";
import { useDispatch } from "react-redux";
import { UTILITY_COMPANY_LIST } from "../../../services/UtilityProviders";
import useTrustedFormScript from "../TrustedFormScript";
import {
  autoInsuranceSelectors,
  autoInsuranceOperations
} from "../../../store/autoInsurance";
import { MAX_MAKE_ID } from "../../../Constants";
const AutoInsuranceForm = ({
  jornayaCampaignID,
  url,
  contactFieldValues,
  contactFieldHandler,
  questions,
  setCompleted,
  questionFieldValues,
  questionFieldHandler,
  autoInsuranceDataHandler,
  autoInsurance,
  handleVehicleReset,
  handleDriverSectionReset,
  handleCurrentPolicyReset,
  handleRequestedPolicyReset,
  submit,
  loading,
  vehicleMakeData,
  setSectionName,
  sectionName,
  vehicleFields,
  driversCount,
  vertical,
  showPartnersList
}) => {
  const [active, setActive] = useState("zip");
  const [index, setIndex] = useState(0);
  const totalQuestions = 5 + questions.length;
  const [overAllIndex, setOverAllIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const dispatch = useDispatch();

  // filters utility provider according to zip code
  const UtilitiesProvider = UTILITY_COMPANY_LIST.filter(
    (utility) => utility[contactFieldValues.state]
  );

  //sets city and state value in contact field and moves to next question
  const updateCityState = async (res) =>
    await contactFieldHandler(
      {
        ...contactFieldValues,
        city: res?.city,
        state: res?.state
      },
      setActive("question")
    );

  const getLocationDetail = () => {
    dispatch(
      commonOperations.fetchLocation(
        contactFieldValues?.zip_code,
        updateCityState
      )
    );
    dispatch(autoInsuranceOperations.fetchAllMakers(MAX_MAKE_ID));
  };

  // fetches total number vehicles been added
  const fetchVehicleCount = (value1, value2) => {
    return (
      (Boolean(value1?.vehicle?.length) ? value1?.vehicle?.length : 0) +
      (value2.year.length ? 1 : 0)
    );
  };
  // fetches total number drivers been added
  const fetchDriversDetailCount = (value1) =>
    Boolean(value1?.drivers?.length) ? value1?.drivers?.length + 1 : 1;

  useEffect(() => {
    if (active !== "question" || index === 0) {
      if (!answeredQuestions.includes(active)) {
        setAnsweredQuestions(answeredQuestions.concat(active));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
    }
  }, [active]);

  const setNextQuestion = (nextIndex) => {
    if (nextIndex >= 0 && nextIndex <= questions.length - 1) {
      if (!answeredQuestions.includes(index)) {
        setAnsweredQuestions(answeredQuestions.concat(index));
        setOverAllIndex(overAllIndex + 1);
        if (active === "email") {
          setCompleted(98);
        } else {
          Math.floor(overAllIndex * (115 / totalQuestions)) > 95
            ? setCompleted(95)
            : setCompleted(Math.floor(overAllIndex * (115 / totalQuestions)));
        }
      }
      setIndex(nextIndex);
    } else {
      setActive("zip");
    }
    if (nextIndex === questions.length) {
      setActive("name");
    }
  };

  const setPreviousQuestion = (index) => {
    if (index >= 0 && index <= questions.length) {
      setIndex(index);
    } else {
      setActive("zip");
    }
    if (index === questions.length) {
      setActive("name");
    }
  };

  // yesno component yes button handler
  const yesHandler = () => {
    //vehicle section handler
    if (questions[index].id === "vehicleAdditional") {
      if (questionFieldValues?.year?.length > 0) {
        autoInsuranceDataHandler({
          vehicle: autoInsurance?.vehicle
            ? autoInsurance.vehicle.concat(questionFieldValues)
            : [questionFieldValues]
        });
        handleVehicleReset();
      }
      setSectionName("vehicle");
      setNextQuestion(0);
    }
    // current policy section
    if (questions[index].id === "currentPolicyAdditional") {
      handleCurrentPolicyReset();
      setNextQuestion(index + 2);
    }
    // requested policy section
    if (questions[index].id === "state_min") {
      handleRequestedPolicyReset();
      questionFieldHandler({
        coverage_type: "State Minimum",
        bodily_injury: "50K/100K",
        property_damage: "50000",
        comprehensive_deductible: "1000",
        collision_deductible: "1000"
      });
      setSectionName("driver");
      setNextQuestion(FindIndexValue(questions, "ticket_date"));
    }
    // ticket section
    if (questions[index].id === "ticket_date") {
      questionFieldHandler({
        ...questionFieldValues,
        tickets: [
          {
            ticket_date: FetchTodaysDate3YearsAgo(),
            description: "Careless driving"
          }
        ]
      });
      setNextQuestion(index + 1);
    }
    if (questions[index].id === "violation_date") {
      questionFieldHandler({
        ...questionFieldValues,
        major_violations: [
          {
            violation_date: FetchTodaysDate3YearsAgo(),
            description: "Drunk Driving - no injury",
            state: "fetch from zip api"
          }
        ]
      });
      setNextQuestion(index + 1);
    }
    if (questions[index].id === "requires_sr22") {
      questionFieldHandler({
        ...questionFieldValues,
        requires_sr22: true
      });
      setNextQuestion(index + 1);
    }
  };

  // yesno component no button handler
  const noHandler = () => {
    // vehicle flow response action
    if (questions[index].id === "vehicleAdditional") {
      if (questionFieldValues?.year?.length > 0) {
        autoInsuranceDataHandler({
          vehicle: autoInsurance?.vehicle
            ? autoInsurance.vehicle.concat(questionFieldValues)
            : [questionFieldValues]
        });
        handleVehicleReset();
      }
      setSectionName("current policy");
    }

    // requested policy flow response action
    if (questions[index].id === "state_min") {
      handleRequestedPolicyReset();
      setSectionName("requested policy");
    }

    // current policy flow response action
    if (questions[index].id === "currentPolicyAdditional") {
      handleCurrentPolicyReset();
    }

    // ticket flow response action
    if (questions[index].id === "ticket_date") {
      delete questionFieldValues.tickets;
      delete questionFieldValues.major_violations;
      questionFieldHandler({
        ...questionFieldValues,
        requires_sr22: false,
        license_ever_suspended: "no"
      });
    }
    if (questions[index].id === "violation_date") {
      delete questionFieldValues.major_violations;
      questionFieldHandler({
        ...questionFieldValues
      });
    }

    if (questions[index].id === "requires_sr22") {
      questionFieldHandler({
        ...questionFieldValues,
        requires_sr22: false
      });
    }
    if (questions[index].id === "ticket_date") {
      fetchDriversDetailCount(autoInsurance) === 1
        ? setNextQuestion(FindIndexValue(questions, "relationship") + 1)
        : setNextQuestion(FindIndexValue(questions, "relationship"));
    } else {
      setNextQuestion(index + 1);
    }
  };

  // multichoice component next button handler
  const multichoiceNextQuestionHandler = () => {
    if (questions[index].id === "no_insurance_reason") {
      if (
        questionFieldValues?.no_insurance_reason === "Suspended license" ||
        questionFieldValues?.no_insurance_reason === "Tickets" ||
        questionFieldValues?.no_insurance_reason === "Accidents"
      ) {
        setSectionName("drivers count section");
        index !== questions.length &&
          setNextQuestion(FindIndexValue(questions, "drivers_count"));
      } else if (
        questionFieldValues?.no_insurance_reason === "Policy lapsed" ||
        questionFieldValues?.no_insurance_reason ===
          "I don't currently have a car"
      ) {
        setSectionName("drivers count section");
        index !== questions.length && setNextQuestion(index + 3);
      } else {
        index !== questions.length && setNextQuestion(index + 1);
      }
    } else if (questions[index].id === "coverage_type") {
      // current policy coverage_type separated from requested policy coverage_type
      if (sectionName === "current policy") {
        setSectionName("drivers count section");
      }
      setNextQuestion(index + 1);
    } else if (questions[index].id === "license_ever_suspended") {
      fetchDriversDetailCount(autoInsurance) === 1
        ? setNextQuestion(FindIndexValue(questions, "relationship") + 1)
        : setNextQuestion(FindIndexValue(questions, "relationship"));
    } else {
      index !== questions.length && setNextQuestion(index + 1);
    }
  };

  // multichoice component previous button handler
  const multichoicePreviousQuestionHandler = () => {
    if (questions[index].id === "insurance_company") {
      setPreviousQuestion(index - 2);
    } else if (questions[index].id === "relationship") {
      !Boolean(questionFieldValues?.tickets)
        ? setPreviousQuestion(FindIndexValue(questions, "ticket_date"))
        : setPreviousQuestion(index - 1);
    } else if (questions[index].id === "gender") {
      if (fetchDriversDetailCount(autoInsurance) === 1) {
        !Boolean(questionFieldValues?.tickets)
          ? setPreviousQuestion(FindIndexValue(questions, "ticket_date"))
          : setPreviousQuestion(index - 2);
      } else {
        setPreviousQuestion(index - 1);
      }
    } else if (
      questions[index].id === "year" &&
      autoInsurance?.vehicle?.length
    ) {
      handleVehicleReset();
      setPreviousQuestion(FindIndexValue(questions, "vehicleAdditional"));
    } else {
      setPreviousQuestion(index - 1);
    }
  };

  // textfield component next button handler
  const textFieldNextHandler = () => {
    if (questions[index].id === "drivers_count") {
      setSectionName("requested policy");
    }
    if (questions[index].id === "collision_deductible") {
      setSectionName("driver");
    }
    setNextQuestion(index + 1);
  };

  // textfield component previous button handler
  const textFieldPreviousHandler = () => {
    if (
      questions[index].id === "drivers_count" &&
      !Boolean(questionFieldValues?.insurance_company)
    ) {
      setSectionName("current policy");
      setPreviousQuestion(FindIndexValue(questions, "currentPolicyAdditional"));
    } else {
      setPreviousQuestion(index - 1);
    }
  };

  // first name and last name component next button handler
  const handleNameComponentNext = () => {
    if (fetchDriversDetailCount(autoInsurance) === 1) {
      let updatedDriverDetail = questionFieldValues;
      updatedDriverDetail.relationship = "Self";

      contactFieldHandler({
        ...contactFieldValues,
        first_name: questionFieldValues.first_name,
        last_name: questionFieldValues.last_name
      });

      autoInsuranceDataHandler({
        ...autoInsurance,
        drivers: [updatedDriverDetail]
      });
    } else {
      autoInsuranceDataHandler({
        ...autoInsurance,
        drivers: autoInsurance.drivers.concat(questionFieldValues)
      });
    }

    if (fetchDriversDetailCount(autoInsurance) < driversCount.drivers_count) {
      handleDriverSectionReset();
      setActive("question");
      setNextQuestion(FindIndexValue(questions, "ticket_date"));
    } else {
      setActive("address");
    }
  };

  //auto-insurance yesno component previous button handler
  const yesnoPreviousHandler = () => {
    if (questions[index].id === "state_min") {
      setSectionName("drivers count section");
      setPreviousQuestion(index - 1);
    } else if (questions[index].id === "ticket_date") {
      // checks the added drivers detail and updates as it needed by adding and removing
      if (fetchDriversDetailCount(autoInsurance) === 1) {
        if (autoInsurance?.drivers?.length > 0) {
          questionFieldHandler(autoInsurance.drivers[0]);
          autoInsuranceDataHandler({
            ...autoInsurance,
            drivers: []
          });
        } else {
          autoInsuranceDataHandler({
            ...autoInsurance,
            drivers: []
          });
          handleDriverSectionReset();
        }
        handleRequestedPolicyReset();
        setSectionName("requested policy");
        setPreviousQuestion(FindIndexValue(questions, "state_min"));
      } else {
        questionFieldHandler(
          autoInsurance.drivers[autoInsurance.drivers.length - 1]
        );
        autoInsuranceDataHandler({
          ...autoInsurance,
          drivers: autoInsurance.drivers.slice(
            0,
            autoInsurance.drivers.length - 1
          )
        });
        setActive("name");
      }
    } else {
      // common previous button handler block
      setPreviousQuestion(index - 1);
    }
  };

  useTrustedFormScript(); // trusted form called
  const renderComponent = () => {
    switch (active) {
      case "zip":
        return (
          <ZipPhoneNumber
            dialogHandler={showPartnersList}
            configURL={url}
            dataHandler={contactFieldHandler}
            data={contactFieldValues}
            fetchLocationDetail={getLocationDetail}
          />
        );
      case "name":
        return (
          <NameField
            handleNext={() => handleNameComponentNext()}
            handlePrevious={() => {
              setActive("question");
              setPreviousQuestion(FindIndexValue(questions, "birth_date"));
            }}
            data={questionFieldValues}
            dataHandler={questionFieldHandler}
            addedDriverCount={fetchDriversDetailCount(autoInsurance)}
          />
        );

      case "address":
        return (
          <AddressQuestion
            handlePrevious={() => {
              setActive("name");
              autoInsuranceDataHandler({
                ...autoInsurance,
                drivers: autoInsurance.drivers.slice(
                  0,
                  autoInsurance.drivers.length - 1
                )
              });
            }}
            handleNext={() => setActive("email")}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
          />
        );
      case "email":
        return (
          <EmailSubmit
            configURL={url}
            data={contactFieldValues}
            dataHandler={contactFieldHandler}
            handleNext={() => submit()}
            handlePrevious={() => setActive("address")}
            loading={loading}
            dialogHandler={showPartnersList}
            jornayaID={jornayaCampaignID}
          />
        );
      case "question":
        if (questions[index]) {
          if (questions[index].type === "radio") {
            return (
              <MultichoiceQuestion
                data={questions[index]}
                nextQuestionHandler={() => multichoiceNextQuestionHandler()}
                handlePrevious={() => multichoicePreviousQuestionHandler()}
                vehicleMakeData={vehicleMakeData}
                showAutoComplete={
                  questions[index].id === "year" ||
                  questions[index].id === "make" ||
                  questions[index].id === "model" ||
                  questions[index].id === "insurance_company"
                    ? true
                    : false
                }
                showRadio={
                  questions[index].id === "insurance_company" ? false : true
                }
                questionFieldValues={questionFieldValues}
                dataHandler={questionFieldHandler}
                addedDriverCount={fetchDriversDetailCount(autoInsurance)}
                vertical={vertical}
              />
            );
          } else if (questions[index].type === "yesno") {
            if (
              questions[index].id === "vehicleAdditional" ||
              questions[index].id === "currentPolicyAdditional" ||
              questions[index].id === "ticket_date" ||
              questions[index].id === "violation_date" ||
              questions[index].id === "requires_sr22" ||
              questions[index].id === "state_min"
            ) {
              return (
                <YesNoQuestion
                  handleYes={yesHandler}
                  handleNo={noHandler}
                  question={questions[index]}
                  vehicleCount={
                    questions[index].id === "vehicleAdditional" &&
                    fetchVehicleCount(autoInsurance, vehicleFields)
                  }
                  handlePrevious={() => yesnoPreviousHandler()}
                  addedDriverCount={fetchDriversDetailCount(autoInsurance)}
                  show={
                    questions[index].id === "vehicleAdditional" ? false : true
                  }
                />
              );
            } else {
              return (
                <YesNoQuestion
                  handleYes={() => setNextQuestion(index + 1)}
                  handleNo={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setPreviousQuestion(index - 1)}
                  question={questions[index]}
                  questionFieldValues={questionFieldValues}
                  dataHandler={questionFieldHandler}
                />
              );
            }
          } else if (questions[index].type === "TEXT_INPUT")
            if (questions[index].id !== "utility_provider") {
              return (
                <TextFieldQuestion
                  data={questions[index]}
                  questionFieldValues={questionFieldValues}
                  handleNext={() => textFieldNextHandler()}
                  handlePrevious={() => textFieldPreviousHandler()}
                  dataHandler={questionFieldHandler}
                  addedDriverCount={fetchDriversDetailCount(autoInsurance)}
                  vertical={vertical}
                />
              );
            } else {
              return (
                <UtilityField
                  data={questions[index]}
                  handleNext={() => setNextQuestion(index + 1)}
                  handlePrevious={() => setPreviousQuestion(index - 1)}
                  dataHandler={questionFieldHandler}
                  questionFieldValues={questionFieldValues}
                  utilityOptions={
                    Boolean(UtilitiesProvider?.length)
                      ? UtilitiesProvider[0][contactFieldValues.state]
                      : []
                  }
                />
              );
            }
          else if (questions[index].type === "dropdown") {
            if (questions[index].id === "birth_date") {
              return (
                <DOB
                  showHint={true}
                  handlePrevious={() => setNextQuestion(index - 1)}
                  handleNext={() => {
                    questionFieldHandler({
                      ...questionFieldValues,
                      [questions[index].id]: `${
                        questionFieldValues[
                          `${[questions[index]?.id]}_year_temp`
                        ]
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_month_temp`
                        ].id
                      }-${
                        questionFieldValues[
                          `${[questions[index]?.id]}_day_temp`
                        ]
                      }`
                    });
                    setNextQuestion(index + 1);
                  }}
                  question={questions[index]}
                  data={questionFieldValues}
                  dataHandler={questionFieldHandler}
                  addedDriverCount={fetchDriversDetailCount(autoInsurance)}
                  vertical={vertical}
                />
              );
            }
          }
        }
      default:
        return <> </>;
    }
  };
  return <>{renderComponent()}</>;
};

export default AutoInsuranceForm;
