import { combineReducers } from "redux";
import * as types from "./types";

const initialState = {
  location: {}
};

/**
 * Location reducer
 * @param {*} state
 * @param {*} action
 */
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOCATION_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

const reducer = combineReducers({
  location: commonReducer
});

export default reducer;
