// returns array of years
const date = new Date();
export const CompareCurrentDate = (dateValue) => {
  let currentDate = new Date(
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
  let compareDate = new Date(dateValue);

  return compareDate <= currentDate ? true : false;
};
export const CompareCurrentYear = (year) => {
  const currentYear = date.getFullYear();
  return year <= currentYear ? true : false;
};
export const trimHtml = (x) => {
  return x.replace(/^\s+|\s+$/gm, "");
};
export const FetchAboveAgeLimitYears = (startYear, ageLimit) => {
  let endYear = date.getFullYear() - ageLimit;
  let years = [];
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

export const FetchFutureYears = (yearLimit) => {
  let startYear = date.getFullYear();
  let years = [];
  let i = 0;
  while (i < yearLimit) {
    years.push(startYear++);
    i++;
  }

  return years;
};

export const FetchPastYears = (endYear = 0) => {
  let startYear = date.getFullYear();
  let years = [];
  let i = 0;
  let yearLimit = endYear === 0 ? 10 : startYear - endYear;
  while (i < yearLimit) {
    years.push(startYear--);
    i++;
  }
  return years;
};

export const FetchYears = () => {
  let startYear = date.getFullYear() + 10;
  let endYear = date.getFullYear() - 10;
  let years = [];
  while (endYear <= startYear) {
    years.push(endYear++);
  }
  return years;
};

export const FetchDays = (limit = 31) => {
  let day = 1;
  let days = [];
  while (day <= limit) {
    days.push((day < 10 ? `0${day++}` : day++).toString());
  }
  return days;
};

export const FetchDateStartOfNextMonth = () => {
  const currentMonth = date.getMonth() + 1 + 1;
  return `${date.getFullYear()}-${
    currentMonth === 13 ? 1 : currentMonth
  }-${date.getDate()}`;
};

export const FetchDateBefore2Year = () => {
  const currentMonth = date.getMonth() + 1;
  return `${date.getFullYear() - 2}-${
    currentMonth === 13 ? 1 : currentMonth
  }-${date.getDate()}`;
};

export const FetchTodaysDate3YearsAgo = () => {
  const currentMonth = date.getMonth() + 1;
  return `${date.getFullYear() - 3}-${
    currentMonth === 13 ? 1 : currentMonth
  }-${date.getDate()}`;
};

export const FindIndexValue = (array, keyValue) =>
  array.findIndex((value) => value.id === keyValue);

//driver section
export const FormatQuestion = (
  t,
  questionID,
  title,
  driverCount,
  vehicleCount
) => {
  const formatTitleVehicle = (title, vehicleCount) => {
    const checkNumber = (count) => {
      switch (count) {
        case 1:
          return title;
        case 2:
          return `${title.replace("2nd", "3rd")}`;
        case 3:
          return `${title.replace("2nd", "4th")}`;
        case 4:
          return `${title.replace("2nd", "5th")}`;
        case 5:
          return `${title.replace("2nd", "6th")}`;
        case 6:
          return `${title.replace("2nd", "7th")}`;
        case 7:
          return `${title.replace("2nd", "8th")}`;
        default:
          return `${title.replace("a 2nd", "an another")}`;
      }
    };
    return checkNumber(vehicleCount);
  };

  const formatDriverSectionTitle = (title, driverCount) => {
    const checkNumber = (count) => {
      switch (count) {
        case 3:
          return `${title.replace("second", "third")}`;
        case 4:
          return `${title.replace("second", "fourth")}`;
        case 5:
          return `${title.replace("second", "fifth")}`;
        default:
          return title;
      }
    };
    return checkNumber(driverCount);
  };

  switch (questionID) {
    case "vehicleAdditional":
      return formatTitleVehicle(title, vehicleCount);
    case "ticket_date":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_TICKET"),
        driverCount
      );
    case "violation_date":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_MAJOR_VIOLATION"),
        driverCount
      );
    case "requires_sr22":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_SR-22"),
        driverCount
      );
    case "license_ever_suspended":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_LICENSE_SUSPENDED"),
        driverCount
      );
    case "gender":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_GENDER"),
        driverCount
      );
    case "marital_status":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_MARRIED"),
        driverCount
      );
    case "residence_type":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_HOMEOWNER"),
        driverCount
      );
    case "education":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_EDUCATION"),
        driverCount
      );
    case "occupation":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_OCCUPATION"),
        driverCount
      );
    case "birth_date":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_DOB"),
        driverCount
      );
    case "name":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_NAME"),
        driverCount
      );
    case "relationship":
      return formatDriverSectionTitle(
        driverCount < 2 ? title : t("ADDITIONAL_DRIVER_RELATIONSHIP"),
        driverCount
      );
    default:
      return title;
  }
};

export const FormatHomeClaimSectionTitle = (title, claimCount) => {
  const checkNumber = (count) => {
    switch (count) {
      case 1:
        return title.includes("claim")
          ? `${title.replace("claim", "second claim")}`
          : `${title.replace("paid?", "paid? (second claim)")}`;
      case 2:
        return title.includes("claim")
          ? `${title.replace("claim", "third claim")}`
          : `${title.replace("paid?", "paid? (third claim)")}`;
      case 3:
        return title.includes("claim")
          ? `${title.replace("claim", "fourth claim")}`
          : `${title.replace("paid?", "paid? (fourth claim)")}`;
      case 4:
        return title.includes("claim")
          ? `${title.replace("claim", "fifth claim")}`
          : `${title.replace("paid?", "paid? (fifth claim)")}`;
      case 5:
        return title.includes("claim")
          ? `${title.replace("claim", "sixth claim")}`
          : `${title.replace("paid?", "paid? (sixth claim)")}`;
      default:
        return title;
    }
  };
  return checkNumber(claimCount);
};

export const ChangeTitle = (title, count, replaceWord) => {
  const checkNumber = (countNumber) => {
    switch (countNumber) {
      case 0:
        return title.replace(replaceWord, `first ${replaceWord}`);
      case 1:
        return title.replace(replaceWord, `second ${replaceWord}`);
      case 2:
        return title.replace(replaceWord, `third ${replaceWord}`);
      case 3:
        return title.replace(replaceWord, `fourth ${replaceWord}`);
      case 4:
        return title.replace(replaceWord, `fifth ${replaceWord}`);
      case 5:
        return title.replace(replaceWord, `sixth ${replaceWord}`);
      case 6:
        return title.replace(replaceWord, `seventh ${replaceWord}`);
      case 7:
        return title.replace(replaceWord, `eighth ${replaceWord}`);
      case 8:
        return title.replace(replaceWord, `ninth ${replaceWord}`);
      case 9:
        return title.replace(replaceWord, `tenth ${replaceWord}`);
      default:
        return title;
    }
  };
  return checkNumber(count);
};

export const FormatMedicineSectionTitle = (title, medicineCount, personCount) =>
  title.replace(
    "X?",
    `${medicineCount + 1} ? ` + `(person\u00A0${personCount + 1})`
  );
