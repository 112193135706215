import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyle = makeStyles((theme) => ({
  spaceVertical: { marginTop: 15, marginBottom: 30 },
  whiteColor: { color: theme.palette.neutral[100] },
  fullLengthContainer: {
    height: "45px",
    width: "370px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  },
  prevBtn: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.booleanButton,
    border: `1px solid ${theme.palette.primary.borderColor}`,
    borderRadius: "5px",
    boxShadow: "none",
    opacity: 1,
    marginBottom: 15
  },
  nxtBtn: {
    fontSize: "15px",
    textTransform: "capitalize",
    color: theme.palette.neutral[100],
    marginBottom: 15,

    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  spaceTopNxtBtn: {
    marginTop: 10
  },
  btn: {
    fontSize: "15px",
    textTransform: "capitalize",
    borderRadius: "5px",
    height: "45px",
    width: "175px",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      height: "40px",
      width: "140px"
    }
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px auto 30px auto"
  }
}));

// only next button
export function NextButton({
  disabledValue,
  onClickNext,
  nextLabel = "NEXT",
  loading
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabledValue}
      variant="contained"
      color="primary"
      className={`${classes.nxtBtn} ${classes.fullLengthContainer} ${classes.spaceTopNxtBtn}`}
      endIcon={<ChevronRightIcon />}
      onClick={onClickNext}
    >
      {t(nextLabel)}
    </Button>
  );
}
// only previous button
export function PreviousButton({ onClickPrev }) {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      className={`${classes.prevBtn} ${classes.fullLengthContainer}`}
      startIcon={<ChevronLeftIcon />}
      onClick={onClickPrev}
    >
      {t("PREVIOUS")}
    </Button>
  );
}

// both previous and next button
export function NavigationButton({
  disabledValue,
  onClickNext,
  onClickPrev,
  nextLabel = "NEXT",
  loading = false,
  enableSubmitLabel
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <div
      className={`${classes.spaceVertical} ${classes.fullLengthContainer} ${classes.btnWrapper}`}
    >
      <Button
        variant="contained"
        className={`${classes.btn} ${classes.prevBtn}`}
        startIcon={<ChevronLeftIcon />}
        onClick={onClickPrev}
      >
        {t("PREVIOUS")}
      </Button>
      {enableSubmitLabel ? (
        <Button
          disabled={disabledValue}
          variant="contained"
          color="primary"
          className={`${classes.btn} ${classes.nxtBtn}`}
          endIcon={<ChevronRightIcon />}
          type="submit"
        >
          {loading ? (
            <CircularProgress size={16} className={classes.whiteColor} />
          ) : (
            t(nextLabel)
          )}
        </Button>
      ) : (
        <Button
          disabled={disabledValue}
          variant="contained"
          color="primary"
          className={`${classes.btn} ${classes.nxtBtn}`}
          endIcon={<ChevronRightIcon />}
          onClick={onClickNext}
        >
          {loading ? (
            <CircularProgress size={16} className={classes.whiteColor} />
          ) : (
            t(nextLabel)
          )}
        </Button>
      )}
    </div>
  );
}
