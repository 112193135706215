import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import { NextButton } from "../../CustomButton/Button";
const ZipPhoneNumber = ({
  configURL,
  dataHandler,
  data,
  fetchLocationDetail,
  dialogHandler
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { zip_code, phone } = data;
  const [error, setError] = useState({
    zip: false,
    phone: false
  });

  const maskInput = (value) => {
    //Filter only numbers from the input
    let input = value.replace(/\D/g, "");
    let size = input.length;
    if (size > 3) {
      input = "(" + input.slice(0, 3) + ") " + input.slice(3, 10);
    }
    if (size > 6) {
      input = input.slice(0, 9) + "-" + input.slice(9);
    }
    dataHandler({
      ...data,
      maskedPhone: input,
      phone: input.replace(/[()\ \s-]+/g, "") //removes parentheses,space,hyphen
    });
    setError({ ...error, phone: false });
  };
  const verifyZip = async () => {
    if (zip_code.length < 4 || phone.length !== 10) {
      setError({
        ...error,
        zip: zip_code.length < 4 && true,
        phone: phone.length !== 10 && true
      });
    } else {
      fetchLocationDetail();
    }
  };
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{t("ZIP_PHONE_TITLE")}</Typography>
      <TextField
        error={error.zip}
        id="outlined-basic"
        placeholder="Enter Zip"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={`${classes.spaceBottom} ${classes.spaceTop} ${classes.fs10}`}
        helperText={error.zip && "Invalid zip code."}
        value={zip_code}
        onChange={(e) => {
          let input = e.target.value.slice(0, 5);
          if (
            !input ||
            (input[input.length - 1].match("[0-9]") && input[0].match("[0-9]"))
          ) {
            dataHandler({ ...data, zip_code: input });
            setError({ ...error, zip: false });
          }
        }}
      />
      <TextField
        error={error.phone}
        id="outlined-basic"
        placeholder="Enter Phone Number"
        variant="outlined"
        InputProps={{
          className: classes.textInputField
        }}
        className={classes.spaceBottom}
        value={data?.maskedPhone ? data?.maskedPhone : ""}
        helperText={error.phone && "Invalid phone number."}
        onChange={(e) => maskInput(e.target.value)}
      />

      <Typography
        className={`${classes.termsText} ${classes.inputTextProperty} ${classes.spaceBottom}`}
      >
        {t("PHONE_SUBMISSION_PART1")}
        <a
          href={
            configURL && configURL?.terms_and_conditions
              ? configURL?.terms_and_conditions
              : null
          }
          className={classes.termsLink}
          target="_blank"
        >
          {t("TERMS_LINK")}
        </a>{" "}
        and{" "}
        <a
          href={
            configURL && configURL?.privacy_policy?.length
              ? configURL?.privacy_policy
              : null
          }
          className={classes.termsLink}
          target={"_blank"}
        >
          {t("PRIVACY_POLICY")}
        </a>
        {t("PHONE_SUBMISSION_PART2")}
        {configURL?.partner?.length ? (
          <a
            href={
              configURL && configURL?.partner?.length
                ? configURL?.partner
                : null
            }
            className={classes.termsLink}
            target={"_blank"}
          >
            {t("PARTNERS")},{" "}
          </a>
        ) : (
          <a onClick={dialogHandler} className={classes.termsLink}>
            {t("PARTNERS")},{" "}
          </a>
        )}
        {t("PHONE_SUBMISSION_PART3")}
      </Typography>
      <NextButton
        disabledValue={!zip_code?.length || !phone?.length}
        onClickNext={() => verifyZip()}
      />
    </div>
  );
};

export default ZipPhoneNumber;
