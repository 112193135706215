import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: "1.5rem",

    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.25rem",
      margin: "20px 20px"
    }
  },
  spaceTop: { marginTop: 10 },
  spaceBottom: { marginBottom: 20 },
  dobContainer: {
    height: "46px",
    border: `1px solid ${theme.palette.primary.borderColor}`,
    borderRadius: "5px",
    opacity: 1,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "370px",
    marginTop: 10,
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  },
  divider: {
    height: "30px",
    borderRight: `1px solid ${theme.palette.primary.borderColor}`,
    opacity: 1
  },
  arrowIcon: { fontSize: "24px", paddingRight: "5px" },
  selectComponent: {
    borderBottom: "3px",
    textAlign: "left",
    fontSize: "15px",
    color: theme.palette.primary.darkText
  },
  daySelect: { width: "25%", paddingLeft: "3%" },
  monthYearSelect: {
    width: "35%",
    paddingLeft: "5%"
  },
  selectOptions: { fontSize: "15px", color: theme.palette.primary.darkText },
  toolTipWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textInputField: {
    fontSize: "15px",
    height: "45px",
    width: "370px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  }
}));
