import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider } from "@material-ui/core";
import useStyles from "./Dob.style";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Months } from "../../../Constants";
import { FetchDays, FetchYears } from "../../../services/utility";

export const DateSelector = ({
  questionType,
  handleOnChange,
  day,
  month,
  year,
  years = null
}) => {
  
  const yearData = years === null ? FetchYears() : years;
  const [days, setDays] = useState(FetchDays());
 
  const classes = useStyles();
  const currentDate = new Date();

  useEffect(() => {
    if (month?.value?.toLowerCase() === "february") {
      if (year && year % 4 === 0) {
        setDays(FetchDays(29));
        day > 29 && handleOnChange({ name: "day", value: "" });
      } else {
        setDays(FetchDays(28));
        day > 28 && handleOnChange({ name: "day", value: "" });
      }
    } else if (
      month?.value?.toLowerCase() === "april" ||
      month?.value?.toLowerCase() === "june" ||
      month?.value?.toLowerCase() === "september" ||
      month?.value?.toLowerCase() === "november"
    ) {
      setDays(FetchDays(30));
      day > 30 && handleOnChange({ name: "day", value: "" });
    } else {
      setDays(FetchDays(31));
    }
    if (questionType.includes("expiration_date")) {
      if (year === currentDate.getFullYear()) {
        if (parseInt(month?.id) < currentDate.getMonth() + 1) {
          handleOnChange({ name: "month", value: "" });
        }
        if (
          parseInt(month?.id) === currentDate.getMonth() + 1 &&
          !(parseInt(day) > currentDate.getDate())
        ) {
          handleOnChange({ name: "day", value: "" });
        }
      }
    }
    if (questionType.includes("insured_since")) {
      if (year === currentDate.getFullYear()) {
        if (parseInt(month?.id) > currentDate.getMonth() + 1) {
          handleOnChange({ name: "month", value: "" });
        }
        if (
          parseInt(month?.id) === currentDate.getMonth() + 1 &&
          !(parseInt(day) < currentDate.getDate())
        ) {
          handleOnChange({ name: "day", value: "" });
        }
      }
    }
  }, [month, year]);

  const checkMonthDisabled = (monthNumber) => {
    if (
      questionType.includes("expiration_date") &&
      year === currentDate.getFullYear()
    ) {
      return monthNumber < currentDate.getMonth() ? true : false;
    }
    if (
      questionType.includes("insured_since") &&
      year === currentDate.getFullYear()
    ) {
      return monthNumber > currentDate.getMonth() ? true : false;
    }
  };

  const checkDayDisabled = (dayNumber) => {
    if (
      questionType.includes("expiration_date") &&
      year === currentDate.getFullYear() &&
      parseInt(month?.id) === currentDate.getMonth() + 1
    ) {
      return dayNumber + 1 > currentDate.getDate() ? false : true;
    }
    if (
      questionType.includes("insured_since") &&
      year === currentDate.getFullYear() &&
      parseInt(month?.id) === currentDate.getMonth() + 1
    ) {
      return dayNumber + 1 < currentDate.getDate() ? false : true;
    }
  };
  return (
    <div className={classes.dobContainer}>
      <Select
        IconComponent={(props) => (
          <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          getContentAnchorEl: null
        }}
        disableUnderline
        className={`${classes.selectComponent} ${classes.daySelect}`}
        value={Boolean(day) ? day : ""}
        placeholder="Day"
        name="day"
        displayEmpty
        onChange={(e) =>
          handleOnChange({ name: e.target.name, value: e.target.value })
        }
      >
        <MenuItem value="" className={classes.selectOptions} disabled>
          Day
        </MenuItem>
        {days.map((day, index) => (
          <MenuItem
            disabled={checkDayDisabled(index)}
            key={index}
            value={day}
            className={classes.selectOptions}
          >
            {day}
          </MenuItem>
        ))}
      </Select>

      <Divider orientation="vertical" className={classes.divider} />
      <Select
        autoWidth
        disableUnderline
        displayEmpty
        value={Boolean(month) ? month : ""}
        className={`${classes.selectComponent} ${classes.monthYearSelect}`}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          getContentAnchorEl: null
        }}
        name="month"
        onChange={(e) =>
          handleOnChange({ name: e.target.name, value: e.target.value })
        }
      >
        <MenuItem value="" className={classes.selectOptions} disabled>
          Month
        </MenuItem>
        {Months.map((month, index) => (
          <MenuItem
            disabled={checkMonthDisabled(index)}
            key={index}
            value={month}
            className={classes.selectOptions}
          >
            {month.value}
          </MenuItem>
        ))}
      </Select>
      <Divider orientation="vertical" className={classes.divider} />
      <Select
        IconComponent={(props) => (
          <KeyboardArrowDownIcon className={classes.arrowIcon} {...props} />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          getContentAnchorEl: null
        }}
        disableUnderline
        className={`${classes.selectComponent} ${classes.monthYearSelect}`}
        value={Boolean(year) ? year : ""}
        displayEmpty
        name="year"
        onChange={(e) =>
          handleOnChange({ name: e.target.name, value: e.target.value })
        }
      >
        <MenuItem value="" className={classes.selectOptions} disabled>
          Year
        </MenuItem>
        {yearData.map((year, index) => (
          <MenuItem key={index} value={year} className={classes.selectOptions}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
