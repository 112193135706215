import React from "react";
import { Typography } from "@material-ui/core";
import CALL_ICON from "../../../assets/Icon_call@2x.png";
import useStyles from "./CallHelpPannel.style";
import { useTranslation } from "react-i18next";

const CallHelpPannel = ({ phoneNumber }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const maskedNumber = (phone) => {
    const num = phone?.toString();
    return `(${num?.substr(0, 3)}) ${num?.substr(3, 3)}-${num?.substr(6, 4)}`;
  };

  return (
    <>
      <div className={classes.callHelpContainerXs}>
        <img
          src={CALL_ICON}
          alt="phone call icon"
          className={classes.callIcon}
        />
        <Typography className={classes.helperText}>
          {t("CALL")}&ensp;
          <span className={classes.phoneNumber}>
          <a className={classes.phoneNumber} href={"tel:"+phoneNumber}>{maskedNumber(phoneNumber)}</a>
          </span>
          {t("HELPER_TEXT")}
        </Typography>
      </div>
    </>
  );
};

export default CallHelpPannel;
