import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  horizontalSpacing: { marginLeft: 10, marginRight: 10 },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  selectorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: "1.5rem",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.25rem"
    }
  },
  // spaceTop: { marginTop: 10 },
  // spaceBottom: { marginBottom: 10 },

  arrowIcon: { fontSize: "24px", paddingRight: "5px" },
  selectComponent: {
    borderBottom: "3px",
    textAlign: "left",
    fontSize: "15px",
    color: theme.palette.primary.darkText
  },

  selectOptions: { fontSize: "15px", color: theme.palette.primary.darkText },
  toolTipWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textInputField: {
    fontSize: "15px",
    height: "45px",
    width: "370px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  }
}));
