export const UTILITY_COMPANY_LIST = [
  {
    AK: [
      {
        name: "Alaska Villages Electric Cooperative",
        value: "AK - Alaska Villages Electric Cooperative"
      },
      {
        name: "Chugach Electric Association",
        value: "AK - Chugach Electric Association"
      },
      {
        name: "Copper Valley Electric Association",
        value: "AK - Copper Valley Electric Association"
      },
      {
        name: "Golden Valley Electric Association",
        value: "AK - Golden Valley Electric Association"
      },
      {
        name: "Kodiak Electric Association",
        value: "AK - Kodiak Electric Association"
      },
      {
        name: "Municipal Light & Power",
        value: "AK - Municipal Light & Power"
      },
      { name: "Other", value: "AK - Other" }
    ]
  },
  {
    AL: [
      {
        name: "AECO {Alabama Electric Company}",
        value: "AL - AECO {Alabama Electric Company}"
      },
      { name: "Alabama Power", value: "AL - Alabama Power" },
      {
        name: "Alabama Rural Electric Association",
        value: "AL - Alabama Rural Electric Association"
      },
      { name: "Baldwin EMC", value: "AL - Baldwin EMC" },
      {
        name: "Central Alabama Electric Co-Op",
        value: "AL - Central Alabama Electric Co-Op"
      },
      {
        name: "Cherokee Electric Cooperative",
        value: "AL - Cherokee Electric Cooperative"
      },
      {
        name: "City of Athens Utilities",
        value: "AL - City of Athens Utilities"
      },
      { name: "City of Bessemer", value: "AL - City of Bessemer" },
      {
        name: "City of Bessemer Electric and Water Service",
        value: "AL - City of Bessemer Electric and Water Service"
      },
      {
        name: "Covington Electric Cooperative",
        value: "AL - Covington Electric Cooperative"
      },
      { name: "Huntsville Utilities", value: "AL - Huntsville Utilities" },
      {
        name: "North Alabama Electric Coop",
        value: "AL - North Alabama Electric Coop"
      },
      {
        name: "PowerSouth Energy Cooperative AL  Inc.",
        value: "AL - PowerSouth Energy Cooperative AL  Inc."
      },
      { name: "Riviera Utilities", value: "AL - Riviera Utilities" },
      {
        name: "Sand Mountain Electric Co-Op",
        value: "AL - Sand Mountain Electric Co-Op"
      },
      { name: "Sheffield Utilities", value: "AL - Sheffield Utilities" },
      {
        name: "Srd Electrical Services",
        value: "AL - Srd Electrical Services"
      },
      {
        name: "Tennessee Valley Authority",
        value: "AL - Tennessee Valley Authority"
      },
      {
        name: "Wiregrass Electric Cooperative",
        value: "AL - Wiregrass Electric Cooperative"
      },
      { name: "Other", value: "AL - Other" }
    ]
  },
  {
    AR: [
      {
        name: "Arkansas Valley Electric Cooperative",
        value: "AR - Arkansas Valley Electric Cooperative"
      },
      {
        name: "C and L Electric Co-Op Corporation",
        value: "AR - C and L Electric Co-Op Corporation"
      },
      {
        name: "Carroll Electric Cooperative Corporation",
        value: "AR - Carroll Electric Cooperative Corporation"
      },
      { name: "Entergy", value: "AR - Entergy" },
      {
        name: "First Electric Cooperative",
        value: "AR - First Electric Cooperative"
      },
      {
        name: "North Arkansas Electric Cooperative",
        value: "AR - North Arkansas Electric Cooperative"
      },
      {
        name: "North Little Rock Electric",
        value: "AR - North Little Rock Electric"
      },
      {
        name: "Ozarks Electric Cooperative",
        value: "AR - Ozarks Electric Cooperative"
      },
      {
        name: "Paragould Light Water and Cable {PLWC}",
        value: "AR - Paragould Light Water and Cable {PLWC}"
      },
      {
        name: "Petit Jean Electric Cooperative",
        value: "AR - Petit Jean Electric Cooperative"
      },
      { name: "Spark Energy", value: "AR - Spark Energy" },
      { name: "Other", value: "AR - Other" }
    ]
  },
  {
    AZ: [
      { name: "Amanti Electric", value: "AZ - Amanti Electric" },
      { name: "Arizona Public Service", value: "AZ - Arizona Public Service" },
      { name: "City of Mesa Utilities", value: "AZ - City of Mesa Utilities" },
      {
        name: "City of Safford Electric Department",
        value: "AZ - City of Safford Electric Department"
      },
      {
        name: "Duncan Valley Electric Cooperative",
        value: "AZ - Duncan Valley Electric Cooperative"
      },
      {
        name: "Electrical District No. 2",
        value: "AZ - Electrical District No. 2"
      },
      {
        name: "Electrical District No. 3",
        value: "AZ - Electrical District No. 3"
      },
      {
        name: "Graham County Utilities",
        value: "AZ - Graham County Utilities"
      },
      { name: "Mojave Electric Co-op", value: "AZ - Mojave Electric Co-op" },
      {
        name: "Navopache Electric Cooperative",
        value: "AZ - Navopache Electric Cooperative"
      },
      { name: "Salt River Project", value: "AZ - Salt River Project" },
      { name: "SRP/Salt River Project", value: "AZ - SRP/Salt River Project" },
      {
        name: "Sulphur Springs Valley Electric Cooperative",
        value: "AZ - Sulphur Springs Valley Electric Cooperative"
      },
      {
        name: "Trico Electric Cooperative",
        value: "AZ - Trico Electric Cooperative"
      },
      { name: "Tucson Electric Power", value: "AZ - Tucson Electric Power" },
      { name: "Unisource", value: "AZ - Unisource" },
      { name: "Xcel Energy", value: "AZ - Xcel Energy" },
      { name: "Other", value: "AZ - Other" }
    ]
  },
  {
    CA: [
      {
        name: "Alameda Municipal Power",
        value: "CA - Alameda Municipal Power"
      },
      { name: "Ambit Energy", value: "CA - Ambit Energy" },
      {
        name: "Anaheim Public Utilities",
        value: "CA - Anaheim Public Utilities"
      },
      { name: "Anza Electric Co-Op", value: "CA - Anza Electric Co-Op" },
      { name: "Azusa Light & Water", value: "CA - Azusa Light & Water" },
      { name: "Bear Valley Electric", value: "CA - Bear Valley Electric" },
      { name: "Burbank Water & Power", value: "CA - Burbank Water & Power" },
      {
        name: "California Public Utilities Commission",
        value: "CA - California Public Utilities Commission"
      },
      {
        name: "City & County of San Francisco",
        value: "CA - City & County of San Francisco"
      },
      { name: "City of Banning", value: "CA - City of Banning" },
      {
        name: "City of Palo Alto Utilities",
        value: "CA - City of Palo Alto Utilities"
      },
      {
        name: "City of Shasta Lake Electric Utility",
        value: "CA - City of Shasta Lake Electric Utility"
      },
      {
        name: "Colton Public Utilities and Public Service Department",
        value: "CA - Colton Public Utilities and Public Service Department"
      },
      {
        name: "Colton Public Utilities Glendale Public Service Department",
        value: "CA - Colton Public Utilities Glendale Public Service Department"
      },
      { name: "El Cerrito Electric", value: "CA - El Cerrito Electric" },
      {
        name: "Glendale Water and Power",
        value: "CA - Glendale Water and Power"
      },
      {
        name: "Gridley Municipal Utilities",
        value: "CA - Gridley Municipal Utilities"
      },
      {
        name: "Healdsburg Municipal Electric Department",
        value: "CA - Healdsburg Municipal Electric Department"
      },
      { name: "IGS Energy", value: "CA - IGS Energy" },
      {
        name: "Imperial Irrigation District",
        value: "CA - Imperial Irrigation District"
      },
      { name: "Lodi Electric Utility", value: "CA - Lodi Electric Utility" },
      {
        name: "Los Angeles Department of Water and Power",
        value: "CA - Los Angeles Department of Water and Power"
      },
      { name: "Marin Energy Authority", value: "CA - Marin Energy Authority" },
      {
        name: "Modesto Irrigation District",
        value: "CA - Modesto Irrigation District"
      },
      {
        name: "Moreno Valley Electric Utility",
        value: "CA - Moreno Valley Electric Utility"
      },
      { name: "Mountain Utilities", value: "CA - Mountain Utilities" },
      { name: "Pacific Gas & Electric", value: "CA - Pacific Gas & Electric" },
      { name: "PacifiCorp", value: "CA - PacifiCorp" },
      { name: "Pasadena Water & Power", value: "CA - Pasadena Water & Power" },
      {
        name: "Redding Electric Utility {REU}",
        value: "CA - Redding Electric Utility {REU}"
      },
      {
        name: "Riverside Public Utilities",
        value: "CA - Riverside Public Utilities"
      },
      { name: "Roseville Electric", value: "CA - Roseville Electric" },
      {
        name: "Sacramento Municipal Utility District",
        value: "CA - Sacramento Municipal Utility District"
      },
      {
        name: "San Diego Gas & Electric",
        value: "CA - San Diego Gas & Electric"
      },
      { name: "Sierra-Pacific Power", value: "CA - Sierra-Pacific Power" },
      { name: "Silicon Valley Power", value: "CA - Silicon Valley Power" },
      {
        name: "Southern California Edison",
        value: "CA - Southern California Edison"
      },
      {
        name: "Southern California Public Power Authority",
        value: "CA - Southern California Public Power Authority"
      },
      { name: "Surprise Valley Power", value: "CA - Surprise Valley Power" },
      { name: "Trinity PUD", value: "CA - Trinity PUD" },
      {
        name: "Turlock Irrigation District",
        value: "CA - Turlock Irrigation District"
      },
      {
        name: "Valley Electric Association",
        value: "CA - Valley Electric Association"
      },
      { name: "Vernon Light & Power", value: "CA - Vernon Light & Power" },
      { name: "Other", value: "CA - Other" }
    ]
  },
  {
    CO: [
      { name: "Bedell Electric", value: "CO - Bedell Electric" },
      { name: "Black Hills Energy", value: "CO - Black Hills Energy" },
      {
        name: "City of Fort Collins Utilities",
        value: "CO - City of Fort Collins Utilities"
      },
      {
        name: "City of Loveland Water and Power",
        value: "CO - City of Loveland Water and Power"
      },
      {
        name: "City of Wauchula Electric",
        value: "CO - City of Wauchula Electric"
      },
      {
        name: "Colorado Springs Utilities",
        value: "CO - Colorado Springs Utilities"
      },
      {
        name: "Delta-Montrose Electric Association",
        value: "CO - Delta-Montrose Electric Association"
      },
      { name: "Grand Valley Power", value: "CO - Grand Valley Power" },
      {
        name: "GreyStone Power Corporation",
        value: "CO - GreyStone Power Corporation"
      },
      {
        name: "Gunnison County Electric Company",
        value: "CO - Gunnison County Electric Company"
      },
      {
        name: "Highline Electric Association",
        value: "CO - Highline Electric Association"
      },
      {
        name: "Holy Cross Energy Company",
        value: "CO - Holy Cross Energy Company"
      },
      {
        name: "Intermountain Rural Electric Association",
        value: "CO - Intermountain Rural Electric Association"
      },
      {
        name: "Kit Carson Electric Cooperative",
        value: "CO - Kit Carson Electric Cooperative"
      },
      {
        name: "La Plata Electric Association",
        value: "CO - La Plata Electric Association"
      },
      { name: "Lamar Light and Power", value: "CO - Lamar Light and Power" },
      { name: "Moon Lake Electric", value: "CO - Moon Lake Electric" },
      {
        name: "Morgan County Rural Electric Association",
        value: "CO - Morgan County Rural Electric Association"
      },
      {
        name: "Mountain View Electric Association",
        value: "CO - Mountain View Electric Association"
      },
      {
        name: "Poudre Valley Rural Electric Association",
        value: "CO - Poudre Valley Rural Electric Association"
      },
      {
        name: "San Isabel Electric Association",
        value: "CO - San Isabel Electric Association"
      },
      {
        name: "San Luis Valley Rural Electric",
        value: "CO - San Luis Valley Rural Electric"
      },
      {
        name: "San Miguel Power Association",
        value: "CO - San Miguel Power Association"
      },
      {
        name: "Southeast Colorado Power Association",
        value: "CO - Southeast Colorado Power Association"
      },
      { name: "United Power Inc", value: "CO - United Power Inc" },
      { name: "Xcel Energy", value: "CO - Xcel Energy" },
      {
        name: "Yampa Valley Electric Association",
        value: "CO - Yampa Valley Electric Association"
      },
      { name: "Other", value: "CO - Other" }
    ]
  },
  {
    CT: [
      { name: "Abest Power & Gas", value: "CT - Abest Power & Gas" },
      { name: "Ambit Energy", value: "CT - Ambit Energy" },
      { name: "Bozrah Light and Power", value: "CT - Bozrah Light and Power" },
      {
        name: "Connecticut Light & Power",
        value: "CT - Connecticut Light & Power"
      },
      {
        name: "Connecticut Natural Gas",
        value: "CT - Connecticut Natural Gas"
      },
      { name: "Groton Utilities", value: "CT - Groton Utilities" },
      { name: "Northeast Utilities", value: "CT - Northeast Utilities" },
      { name: "United Illuminating", value: "CT - United Illuminating" },
      { name: "Wallingford Electric", value: "CT - Wallingford Electric" },
      { name: "Other", value: "CT - Other" }
    ]
  },
  {
    DC: [
      { name: "Ambit Energy", value: "DC - Ambit Energy" },
      { name: "PEPCO", value: "DC - PEPCO" },
      { name: "Other", value: "DC - Other" }
    ]
  },
  {
    DE: [
      { name: "Calpine", value: "DE - Calpine" },
      {
        name: "City of Dover Electric Department",
        value: "DE - City of Dover Electric Department"
      },
      {
        name: "City of Milford Electric Department",
        value: "DE - City of Milford Electric Department"
      },
      {
        name: "Delaware Electric Cooperative",
        value: "DE - Delaware Electric Cooperative"
      },
      { name: "Delmarva Power", value: "DE - Delmarva Power" },
      { name: "NRG Energy", value: "DE - NRG Energy" },
      {
        name: "Town of Middletown Utilities",
        value: "DE - Town of Middletown Utilities"
      },
      { name: "Town of Smyrna", value: "DE - Town of Smyrna" },
      { name: "Other", value: "DE - Other" }
    ]
  },
  {
    FL: [
      { name: "Baugh Southeast Co-Op", value: "FL - Baugh Southeast Co-Op" },
      {
        name: "Choctawhatchee Electric Co-Op",
        value: "FL - Choctawhatchee Electric Co-Op"
      },
      { name: "City of Leesburg", value: "FL - City of Leesburg" },
      {
        name: "City of Tallahassee Utilities",
        value: "FL - City of Tallahassee Utilities"
      },
      { name: "City of Vero Beach", value: "FL - City of Vero Beach" },
      { name: "Clay Electric Co-op", value: "FL - Clay Electric Co-op" },
      { name: "Duke Energy", value: "FL - Duke Energy" },
      {
        name: "Florida Keys Electric Cooperative",
        value: "FL - Florida Keys Electric Cooperative"
      },
      {
        name: "Florida Municipal Power Agency",
        value: "FL - Florida Municipal Power Agency"
      },
      { name: "Florida Power & Light", value: "FL - Florida Power & Light" },
      {
        name: "Florida Public Utility Company Palm Beach",
        value: "FL - Florida Public Utility Company Palm Beach"
      },
      {
        name: "Gainesville Regional Utilities",
        value: "FL - Gainesville Regional Utilities"
      },
      {
        name: "Glades Electric Cooperative",
        value: "FL - Glades Electric Cooperative"
      },
      { name: "Gulf Power", value: "FL - Gulf Power" },
      { name: "JEA", value: "FL - JEA" },
      {
        name: "Kissimmee Utility Authority",
        value: "FL - Kissimmee Utility Authority"
      },
      { name: "Lake Worth Utilities", value: "FL - Lake Worth Utilities" },
      { name: "Lakeland Electric", value: "FL - Lakeland Electric" },
      { name: "LCEC", value: "FL - LCEC" },
      { name: "Ocala Electric", value: "FL - Ocala Electric" },
      {
        name: "Okefenokee Rural Electric {OREMC}",
        value: "FL - Okefenokee Rural Electric {OREMC}"
      },
      {
        name: "Orlando Utilities Commission",
        value: "FL - Orlando Utilities Commission"
      },
      { name: "Paige Electric", value: "FL - Paige Electric" },
      {
        name: "Peace River Electric Cooperative",
        value: "FL - Peace River Electric Cooperative"
      },
      {
        name: "Progress Energy Florida",
        value: "FL - Progress Energy Florida"
      },
      { name: "SECO Energy", value: "FL - SECO Energy" },
      {
        name: "St. Petersburg Utility Department",
        value: "FL - St. Petersburg Utility Department"
      },
      {
        name: "Talquin Electric Cooperative",
        value: "FL - Talquin Electric Cooperative"
      },
      { name: "Tampa Electric", value: "FL - Tampa Electric" },
      { name: "TECO", value: "FL - TECO" },
      {
        name: "West Florida Electric Cooperative",
        value: "FL - West Florida Electric Cooperative"
      },
      {
        name: "Withlacoochee River Electric Cooperative",
        value: "FL - Withlacoochee River Electric Cooperative"
      },
      { name: "Other", value: "FL - Other" }
    ]
  },
  {
    GA: [
      {
        name: "Amicalola Electric Membership Corporation",
        value: "GA - Amicalola Electric Membership Corporation"
      },
      {
        name: "Blue Ridge Mountain EMC",
        value: "GA - Blue Ridge Mountain EMC"
      },
      {
        name: "Charles E Hill Electric Co",
        value: "GA - Charles E Hill Electric Co"
      },
      {
        name: "City of LaGrange Utilities",
        value: "GA - City of LaGrange Utilities"
      },
      {
        name: "City of Sylvester Utilities",
        value: "GA - City of Sylvester Utilities"
      },
      { name: "Cobb EMC", value: "GA - Cobb EMC" },
      {
        name: "Colquitt Electric Corporation",
        value: "GA - Colquitt Electric Corporation"
      },
      { name: "Coweta Fayette EMC", value: "GA - Coweta Fayette EMC" },
      { name: "Diverse Power", value: "GA - Diverse Power" },
      { name: "Fitzgerald Utilities", value: "GA - Fitzgerald Utilities" },
      { name: "Flint Energies", value: "GA - Flint Energies" },
      { name: "Georgia EMC", value: "GA - Georgia EMC" },
      { name: "Georgia Power", value: "GA - Georgia Power" },
      { name: "Habersham EMC", value: "GA - Habersham EMC" },
      { name: "Hart EMC", value: "GA - Hart EMC" },
      { name: "Jackson EMC", value: "GA - Jackson EMC" },
      { name: "Rowell Electric Co", value: "GA - Rowell Electric Co" },
      { name: "Sawnee EMC", value: "GA - Sawnee EMC" },
      { name: "Snapping Shoals EMC", value: "GA - Snapping Shoals EMC" },
      { name: "Southern Rivers Energy", value: "GA - Southern Rivers Energy" },
      {
        name: "Tennessee Valley Authority",
        value: "GA - Tennessee Valley Authority"
      },
      { name: "Tri-State Electric", value: "GA - Tri-State Electric" },
      { name: "Walton EMC", value: "GA - Walton EMC" },
      { name: "Washington EMC", value: "GA - Washington EMC" },
      { name: "Other", value: "GA - Other" }
    ]
  },
  {
    HI: [
      {
        name: "Hawaiian Electric Industries",
        value: "HI - Hawaiian Electric Industries"
      },
      {
        name: "Hawaiian Electric Company {HECO}",
        value: "HI - Hawaiian Electric Company {HECO}"
      },
      {
        name: "Kauai Island Utility Cooperative {KIUC}",
        value: "HI - Kauai Island Utility Cooperative {KIUC}"
      },
      { name: "Maui Electric Company", value: "HI - Maui Electric Company" },
      { name: "Other", value: "HI - Other" }
    ]
  },
  {
    IA: [
      { name: "Alliant Energy", value: "IA - Alliant Energy" },
      {
        name: "Chariton Valley Electric Cooperative",
        value: "IA - Chariton Valley Electric Cooperative"
      },
      {
        name: "City of Denver Electric {Iowa}",
        value: "IA - City of Denver Electric {Iowa}"
      },
      {
        name: "Interstate Power and Light Company",
        value: "IA - Interstate Power and Light Company"
      },
      { name: "MidAmerican Energy", value: "IA - MidAmerican Energy" },
      { name: "Midland Power Co-Op", value: "IA - Midland Power Co-Op" },
      {
        name: "Muscatine Power and Water",
        value: "IA - Muscatine Power and Water"
      },
      {
        name: "Southwest Iowa Rural Electric Cooperative",
        value: "IA - Southwest Iowa Rural Electric Cooperative"
      },
      {
        name: "Tip Rural Electric Co-Op",
        value: "IA - Tip Rural Electric Co-Op"
      },
      { name: "Other", value: "IA - Other" }
    ]
  },
  {
    ID: [
      {
        name: "City of Rupert Electric",
        value: "ID - City of Rupert Electric"
      },
      { name: "City of Weiser", value: "ID - City of Weiser" },
      { name: "Clearwater Power", value: "ID - Clearwater Power" },
      { name: "IDACORP", value: "ID - IDACORP" },
      { name: "Idaho Power", value: "ID - Idaho Power" },
      {
        name: "Kootenai Electric Cooperative",
        value: "ID - Kootenai Electric Cooperative"
      },
      {
        name: "Northern Lights Electric Cooperative",
        value: "ID - Northern Lights Electric Cooperative"
      },
      {
        name: "PacifiCorp {Rocky Mountain Power}",
        value: "ID - PacifiCorp {Rocky Mountain Power}"
      },
      { name: "Riverside Electric Co", value: "ID - Riverside Electric Co" },
      { name: "Other", value: "ID - Other" }
    ]
  },
  {
    IL: [
      { name: "Ambit Energy", value: "IL - Ambit Energy" },
      { name: "Ameren", value: "IL - Ameren" },
      {
        name: "Batavia Municipal Electric",
        value: "IL - Batavia Municipal Electric"
      },
      {
        name: "City of Princeton Electric",
        value: "IL - City of Princeton Electric"
      },
      { name: "City of St. Charles", value: "IL - City of St. Charles" },
      { name: "ComEd Electric Company", value: "IL - ComEd Electric Company" },
      {
        name: "Corn Belt Energy Corporation",
        value: "IL - Corn Belt Energy Corporation"
      },
      { name: "Gexpro Electric", value: "IL - Gexpro Electric" },
      { name: "IGS Energy", value: "IL - IGS Energy" },
      { name: "Jo-Carroll Energy", value: "IL - Jo-Carroll Energy" },
      {
        name: "Norris Electric Cooperative",
        value: "IL - Norris Electric Cooperative"
      },
      {
        name: "Red Bud Utilities Plant",
        value: "IL - Red Bud Utilities Plant"
      },
      {
        name: "Springfield City Water  Light & Power",
        value: "IL - Springfield City Water  Light & Power"
      },
      { name: "Tara Energy", value: "IL - Tara Energy" },
      {
        name: "Village of Hanover Park Municipal Light and Power",
        value: "IL - Village of Hanover Park Municipal Light and Power"
      },
      { name: "Other", value: "IL - Other" }
    ]
  },
  {
    IN: [
      {
        name: "American Electric Power",
        value: "IN - American Electric Power"
      },
      { name: "Duke Energy Indiana", value: "IN - Duke Energy Indiana" },
      {
        name: "Garrett Electric Utilities",
        value: "IN - Garrett Electric Utilities"
      },
      {
        name: "Hendricks Power Cooperative",
        value: "IN - Hendricks Power Cooperative"
      },
      { name: "Henry County REMC", value: "IN - Henry County REMC" },
      { name: "IGS Energy", value: "IN - IGS Energy" },
      { name: "Indiana Michigan Power", value: "IN - Indiana Michigan Power" },
      {
        name: "Indianapolis Power & Light",
        value: "IN - Indianapolis Power & Light"
      },
      { name: "Jackson County REMC", value: "IN - Jackson County REMC" },
      { name: "Lebanon Utilities", value: "IN - Lebanon Utilities" },
      { name: "Meade Electric Company", value: "IN - Meade Electric Company" },
      { name: "Mishawaka Utilities", value: "IN - Mishawaka Utilities" },
      {
        name: "Northern Indiana Public Service Company",
        value: "IN - Northern Indiana Public Service Company"
      },
      {
        name: "South Central Indiana REMC",
        value: "IN - South Central Indiana REMC"
      },
      {
        name: "Southeastern Indiana REMC",
        value: "IN - Southeastern Indiana REMC"
      },
      { name: "Whitewater Valley REMC", value: "IN - Whitewater Valley REMC" },
      { name: "Other", value: "IN - Other" }
    ]
  },
  {
    KS: [
      { name: "Bluestem Electric", value: "KS - Bluestem Electric" },
      {
        name: "Caney Valley Elec Co-Op Association",
        value: "KS - Caney Valley Elec Co-Op Association"
      },
      {
        name: "Kansas City Board of Public Utilities",
        value: "KS - Kansas City Board of Public Utilities"
      },
      {
        name: "Kansas City Power & Light",
        value: "KS - Kansas City Power & Light"
      },
      { name: "Midwest Energy", value: "KS - Midwest Energy" },
      {
        name: "Rolling Hills Electric Cooperative",
        value: "KS - Rolling Hills Electric Cooperative"
      },
      { name: "Westar Energy", value: "KS - Westar Energy" },
      { name: "Other", value: "KS - Other" }
    ]
  },
  {
    KY: [
      {
        name: "American Electric Power",
        value: "KY - American Electric Power"
      },
      {
        name: "Berea Municipal Utilities",
        value: "KY - Berea Municipal Utilities"
      },
      {
        name: "Big Rivers Electric Corporation",
        value: "KY - Big Rivers Electric Corporation"
      },
      { name: "Blue Grass Energy", value: "KY - Blue Grass Energy" },
      {
        name: "Bowling Green Municipal Utilities",
        value: "KY - Bowling Green Municipal Utilities"
      },
      {
        name: "Clark Energy Cooperative",
        value: "KY - Clark Energy Cooperative"
      },
      { name: "Duke Energy Kentucky", value: "KY - Duke Energy Kentucky" },
      {
        name: "Fleming-Mason Energy Cooperative",
        value: "KY - Fleming-Mason Energy Cooperative"
      },
      { name: "Frankfort Plant Board", value: "KY - Frankfort Plant Board" },
      {
        name: "Grayson Rural Electric Electric Cooperative",
        value: "KY - Grayson Rural Electric Electric Cooperative"
      },
      {
        name: "Henderson Municipal Power and Light",
        value: "KY - Henderson Municipal Power and Light"
      },
      { name: "IGS Energy", value: "KY - IGS Energy" },
      { name: "Kenergy Corp", value: "KY - Kenergy Corp" },
      { name: "Kentucky Utilities", value: "KY - Kentucky Utilities" },
      {
        name: "Louisville Gas & Electric",
        value: "KY - Louisville Gas & Electric"
      },
      {
        name: "Nolin Rural Electric Cooperative",
        value: "KY - Nolin Rural Electric Cooperative"
      },
      { name: "Owen Electric", value: "KY - Owen Electric" },
      {
        name: "Owensboro Municipal Utilities",
        value: "KY - Owensboro Municipal Utilities"
      },
      {
        name: "Pennyrile Rural Electric Co-Op",
        value: "KY - Pennyrile Rural Electric Co-Op"
      },
      { name: "Salt River Electric", value: "KY - Salt River Electric" },
      {
        name: "Shelby Energy Cooperative",
        value: "KY - Shelby Energy Cooperative"
      },
      {
        name: "Tennessee Valley Authority",
        value: "KY - Tennessee Valley Authority"
      },
      {
        name: "West Kentucky Rural Electric Cooperative Corporation",
        value: "KY - West Kentucky Rural Electric Cooperative Corporation"
      },
      { name: "Other", value: "KY - Other" }
    ]
  },
  {
    LA: [
      {
        name: "Beauregard Electric CO-OP",
        value: "LA - Beauregard Electric CO-OP"
      },
      { name: "CLECO", value: "LA - CLECO" },
      {
        name: "Concordia Electric Co-Op",
        value: "LA - Concordia Electric Co-Op"
      },
      { name: "DEMCO", value: "LA - DEMCO" },
      {
        name: "East-Central Iowa Rural Electric Cooperative",
        value: "LA - East-Central Iowa Rural Electric Cooperative"
      },
      { name: "Entergy", value: "LA - Entergy" },
      { name: "Etheredge Electric Co", value: "LA - Etheredge Electric Co" },
      {
        name: "Heartland Rural Electric Cooperative",
        value: "LA - Heartland Rural Electric Cooperative"
      },
      {
        name: "Jefferson Davis Electric Co-Op",
        value: "LA - Jefferson Davis Electric Co-Op"
      },
      {
        name: "Lafayette Utilities System",
        value: "LA - Lafayette Utilities System"
      },
      {
        name: "Pointe Coupee Electric Membership",
        value: "LA - Pointe Coupee Electric Membership"
      },
      { name: "Puckett Electric", value: "LA - Puckett Electric" },
      { name: "SLECMO", value: "LA - SLECMO" },
      { name: "SWEPCO", value: "LA - SWEPCO" },
      {
        name: "Town of Vinton Electric",
        value: "LA - Town of Vinton Electric"
      },
      {
        name: "Washington - St. Tammany Electric Cooperative",
        value: "LA - Washington - St. Tammany Electric Cooperative"
      },
      { name: "Other", value: "LA - Other" }
    ]
  },
  {
    MA: [
      { name: "Ambit Energy", value: "MA - Ambit Energy" },
      {
        name: "Belmont Electric Light Department",
        value: "MA - Belmont Electric Light Department"
      },
      { name: "Berkshire Company", value: "MA - Berkshire Company" },
      {
        name: "Braintree Electric Light Department",
        value: "MA - Braintree Electric Light Department"
      },
      {
        name: "Chicopee Electric Light Department",
        value: "MA - Chicopee Electric Light Department"
      },
      { name: "City of Fitchburg", value: "MA - City of Fitchburg" },
      {
        name: "Hingham Light Department",
        value: "MA - Hingham Light Department"
      },
      {
        name: "Holden Municipal Light Department",
        value: "MA - Holden Municipal Light Department"
      },
      {
        name: "Holyoke Gas and Electric",
        value: "MA - Holyoke Gas and Electric"
      },
      { name: "Hudson Light and Power", value: "MA - Hudson Light and Power" },
      {
        name: "Ipswich Utility Department",
        value: "MA - Ipswich Utility Department"
      },
      {
        name: "Mansfield Municipal Electric",
        value: "MA - Mansfield Municipal Electric"
      },
      {
        name: "Marblehead Electric Light Department",
        value: "MA - Marblehead Electric Light Department"
      },
      { name: "Massachusetts Electric", value: "MA - Massachusetts Electric" },
      {
        name: "Massachusetts Municipal Wholesale Electric Company {MMWEC}",
        value: "MA - Massachusetts Municipal Wholesale Electric Company {MMWEC}"
      },
      {
        name: "Middleborough Gas and Electric",
        value: "MA - Middleborough Gas and Electric"
      },
      { name: "Nantucket Electric", value: "MA - Nantucket Electric" },
      { name: "National Grid", value: "MA - National Grid" },
      {
        name: "North Attleborough Electric Department",
        value: "MA - North Attleborough Electric Department"
      },
      { name: "Northeast Utilities", value: "MA - Northeast Utilities" },
      {
        name: "Norwood Municipal Light and Power",
        value: "MA - Norwood Municipal Light and Power"
      },
      { name: "NSTAR", value: "MA - NSTAR" },
      {
        name: "Paxton Municipal Light Department",
        value: "MA - Paxton Municipal Light Department"
      },
      {
        name: "Peabody Municipal Light Plant",
        value: "MA - Peabody Municipal Light Plant"
      },
      {
        name: "Reading Municipal Light Department",
        value: "MA - Reading Municipal Light Department"
      },
      {
        name: "Rowley Electric Light Department",
        value: "MA - Rowley Electric Light Department"
      },
      { name: "SELCO", value: "MA - SELCO" },
      {
        name: "Sterling Municipal Light Department",
        value: "MA - Sterling Municipal Light Department"
      },
      {
        name: "Taunton Municipal Lighting Plant {TMLP}",
        value: "MA - Taunton Municipal Lighting Plant {TMLP}"
      },
      {
        name: "Templeton Municipal Light Co",
        value: "MA - Templeton Municipal Light Co"
      },
      { name: "Viridian", value: "MA - Viridian" },
      {
        name: "Wakefield Municipal Gas and Light Department",
        value: "MA - Wakefield Municipal Gas and Light Department"
      },
      {
        name: "Western Massachusetts Electric",
        value: "MA - Western Massachusetts Electric"
      },
      {
        name: "Westfield Gas and Electric Department",
        value: "MA - Westfield Gas and Electric Department"
      },
      { name: "Other", value: "MA - Other" }
    ]
  },
  {
    MD: [
      { name: "Allegheny Power", value: "MD - Allegheny Power" },
      { name: "Ambit Energy", value: "MD - Ambit Energy" },
      {
        name: "Baltimore Gas & Electric",
        value: "MD - Baltimore Gas & Electric"
      },
      {
        name: "Choptank Electric Cooperative",
        value: "MD - Choptank Electric Cooperative"
      },
      { name: "Constellation Energy", value: "MD - Constellation Energy" },
      { name: "Delmarva Power", value: "MD - Delmarva Power" },
      { name: "IGS Energy", value: "MD - IGS Energy" },
      { name: "Kleppinger Electric Co", value: "MD - Kleppinger Electric Co" },
      { name: "PEPCO", value: "MD - PEPCO" },
      {
        name: "Potomoc Edison {FirstEnergy}",
        value: "MD - Potomoc Edison {FirstEnergy}"
      },
      {
        name: "Southern Maryland Electric Cooperative",
        value: "MD - Southern Maryland Electric Cooperative"
      },
      { name: "Other", value: "MD - Other" }
    ]
  },
  {
    ME: [
      { name: "Bangor Hydro Electric", value: "ME - Bangor Hydro Electric" },
      { name: "Central Maine Power", value: "ME - Central Maine Power" },
      {
        name: "Eastern Maine Electric Cooperative",
        value: "ME - Eastern Maine Electric Cooperative"
      },
      {
        name: "Kennebunk Light and Power",
        value: "ME - Kennebunk Light and Power"
      },
      {
        name: "Maine Public Utilities Commission",
        value: "ME - Maine Public Utilities Commission"
      },
      { name: "Other", value: "ME - Other" }
    ]
  },
  {
    MI: [
      {
        name: "American Electric Power",
        value: "MI - American Electric Power"
      },
      { name: "Consumers Energy", value: "MI - Consumers Energy" },
      {
        name: "DTE Energy {Detroit Edison}",
        value: "MI - DTE Energy {Detroit Edison}"
      },
      { name: "Excel Energy", value: "MI - Excel Energy" },
      {
        name: "Holland Board of Public Works",
        value: "MI - Holland Board of Public Works"
      },
      { name: "IGS Energy", value: "MI - IGS Energy" },
      { name: "Indiana Michigan Power", value: "MI - Indiana Michigan Power" },
      {
        name: "Lansing Board of Water & Light",
        value: "MI - Lansing Board of Water & Light"
      },
      {
        name: "Midwest Energy Cooperative",
        value: "MI - Midwest Energy Cooperative"
      },
      { name: "TC Electric", value: "MI - TC Electric" },
      {
        name: "Upper Peninsula Power Company",
        value: "MI - Upper Peninsula Power Company"
      },
      {
        name: "Wakefield Municipal Gas and Light",
        value: "MI - Wakefield Municipal Gas and Light"
      },
      { name: "We Energies", value: "MI - We Energies" },
      {
        name: "Wyandotte Municipal Services",
        value: "MI - Wyandotte Municipal Services"
      },
      { name: "Other", value: "MI - Other" }
    ]
  },
  {
    MN: [
      { name: "Alliant Energy", value: "MN - Alliant Energy" },
      {
        name: "Basin Electric Power Cooperative",
        value: "MN - Basin Electric Power Cooperative"
      },
      { name: "Connexus Energy", value: "MN - Connexus Energy" },
      {
        name: "Dakota Electric Association",
        value: "MN - Dakota Electric Association"
      },
      { name: "East Central Energy", value: "MN - East Central Energy" },
      {
        name: "East Grand Forks Electric and Water",
        value: "MN - East Grand Forks Electric and Water"
      },
      {
        name: "East River Electric Power Co-op",
        value: "MN - East River Electric Power Co-op"
      },
      {
        name: "Goodhue County Co-Op Electric",
        value: "MN - Goodhue County Co-Op Electric"
      },
      { name: "Great River Energy", value: "MN - Great River Energy" },
      {
        name: "Hutchinson Utilities Commission",
        value: "MN - Hutchinson Utilities Commission"
      },
      { name: "ITC Midwest", value: "MN - ITC Midwest" },
      { name: "L&O Power Co-op", value: "MN - L&O Power Co-op" },
      { name: "Lake Country Power", value: "MN - Lake Country Power" },
      {
        name: "Marshall Municipal Utilities",
        value: "MN - Marshall Municipal Utilities"
      },
      {
        name: "Mille Lacs Energy Co-Op",
        value: "MN - Mille Lacs Energy Co-Op"
      },
      { name: "Minnesota Power", value: "MN - Minnesota Power" },
      {
        name: "Minnesota Valley Electric Cooperative",
        value: "MN - Minnesota Valley Electric Cooperative"
      },
      {
        name: "Minnkota Power Cooperative",
        value: "MN - Minnkota Power Cooperative"
      },
      {
        name: "Missouri River Energy Services",
        value: "MN - Missouri River Energy Services"
      },
      {
        name: "Moorhead City Public Services Department",
        value: "MN - Moorhead City Public Services Department"
      },
      {
        name: "Otter Tail Power Company",
        value: "MN - Otter Tail Power Company"
      },
      { name: "People", value: "MN - People" },
      {
        name: "Rochester Public Utilities",
        value: "MN - Rochester Public Utilities"
      },
      {
        name: "Shakopee Public Utilities",
        value: "MN - Shakopee Public Utilities"
      },
      { name: "Tri-County Electric", value: "MN - Tri-County Electric" },
      { name: "Xcel Energy", value: "MN - Xcel Energy" },
      { name: "Other", value: "MN - Other" }
    ]
  },
  {
    MO: [
      { name: "Ameren", value: "MO - Ameren" },
      {
        name: "Barton County Electric Cooperative",
        value: "MO - Barton County Electric Cooperative"
      },
      {
        name: "Black River Electric Cooperative",
        value: "MO - Black River Electric Cooperative"
      },
      {
        name: "Boone Electric Cooperative",
        value: "MO - Boone Electric Cooperative"
      },
      {
        name: "Callaway Electric Cooperative",
        value: "MO - Callaway Electric Cooperative"
      },
      {
        name: "Citizens Electric Corporation",
        value: "MO - Citizens Electric Corporation"
      },
      {
        name: "City of Waynesville Electric",
        value: "MO - City of Waynesville Electric"
      },
      {
        name: "City Utilities of Springfield",
        value: "MO - City Utilities of Springfield"
      },
      { name: "Coast Electric", value: "MO - Coast Electric" },
      { name: "Cuivre River Electric", value: "MO - Cuivre River Electric" },
      {
        name: "Empire District Electric Company",
        value: "MO - Empire District Electric Company"
      },
      {
        name: "Gascosage Electric Co-Op",
        value: "MO - Gascosage Electric Co-Op"
      },
      {
        name: "Hannibal Board of Public Works",
        value: "MO - Hannibal Board of Public Works"
      },
      { name: "Harrisonville Electric", value: "MO - Harrisonville Electric" },
      {
        name: "Howard Electric Cooperative",
        value: "MO - Howard Electric Cooperative"
      },
      {
        name: "Howell-Oregon Electric Co-Op",
        value: "MO - Howell-Oregon Electric Co-Op"
      },
      {
        name: "Independence Power and Light",
        value: "MO - Independence Power and Light"
      },
      {
        name: "Intercounty Electric Cooperative",
        value: "MO - Intercounty Electric Cooperative"
      },
      {
        name: "Kansas City Power & Light",
        value: "MO - Kansas City Power & Light"
      },
      {
        name: "Laclede Electric Cooperative",
        value: "MO - Laclede Electric Cooperative"
      },
      { name: "New-Mac Electric", value: "MO - New-Mac Electric" },
      { name: "Nixa Utilities", value: "MO - Nixa Utilities" },
      {
        name: "Ozark Border Electric Cooperative",
        value: "MO - Ozark Border Electric Cooperative"
      },
      {
        name: "Platte-Clay Electric Cooperative",
        value: "MO - Platte-Clay Electric Cooperative"
      },
      {
        name: "SAC Osage Electric Co-Op",
        value: "MO - SAC Osage Electric Co-Op"
      },
      {
        name: "Webster Electric Cooperative",
        value: "MO - Webster Electric Cooperative"
      },
      {
        name: "West Central Electric Cooperative",
        value: "MO - West Central Electric Cooperative"
      },
      {
        name: "White Valley River Valley Electric Cooperative",
        value: "MO - White Valley River Valley Electric Cooperative"
      },
      { name: "Other", value: "MO - Other" }
    ]
  },
  {
    MS: [
      { name: "Entergy", value: "MS - Entergy" },
      { name: "Magnolia Electric", value: "MS - Magnolia Electric" },
      {
        name: "Mississippi Power Company",
        value: "MS - Mississippi Power Company"
      },
      {
        name: "Southern Pine Electric Power Association",
        value: "MS - Southern Pine Electric Power Association"
      },
      {
        name: "Southwest Mississippi Electric Power Association",
        value: "MS - Southwest Mississippi Electric Power Association"
      },
      {
        name: "Tennessee Valley Authority",
        value: "MS - Tennessee Valley Authority"
      },
      {
        name: "Tombigbee Electric Power Association",
        value: "MS - Tombigbee Electric Power Association"
      },
      { name: "Other", value: "MS - Other" }
    ]
  },
  {
    MT: [
      {
        name: "Central Montana Electric Power Cooperative",
        value: "MT - Central Montana Electric Power Cooperative"
      },
      {
        name: "Flathead Electric Co-Op",
        value: "MT - Flathead Electric Co-Op"
      },
      { name: "MDU", value: "MT - MDU" },
      {
        name: "Montana Electric Cooperatives' Association",
        value: "MT - Montana Electric Cooperatives' Association"
      },
      { name: "Northwestern Energy", value: "MT - Northwestern Energy" },
      { name: "Park Electric Co-Op", value: "MT - Park Electric Co-Op" },
      { name: "Ravalli Electric Co-Op", value: "MT - Ravalli Electric Co-Op" },
      {
        name: "Vigilante Electric Co-Op",
        value: "MT - Vigilante Electric Co-Op"
      },
      { name: "Other", value: "MT - Other" }
    ]
  },
  {
    NC: [
      {
        name: "Albemarle Electric Membership Corporation",
        value: "NC - Albemarle Electric Membership Corporation"
      },
      {
        name: "Ashley Smith Electric Co",
        value: "NC - Ashley Smith Electric Co"
      },
      {
        name: "Blue Ridge Electrical Membership Corp.",
        value: "NC - Blue Ridge Electrical Membership Corp."
      },
      { name: "Brunswick EMC", value: "NC - Brunswick EMC" },
      {
        name: "Cape Hatteras Electric Corporation",
        value: "NC - Cape Hatteras Electric Corporation"
      },
      {
        name: "Carteret-Craven Electric Cooperative",
        value: "NC - Carteret-Craven Electric Cooperative"
      },
      {
        name: "Central Electric Membership Corporation",
        value: "NC - Central Electric Membership Corporation"
      },
      { name: "City of Albemarle", value: "NC - City of Albemarle" },
      {
        name: "City of Kinston Electric",
        value: "NC - City of Kinston Electric"
      },
      {
        name: "City of Lexington Utilities",
        value: "NC - City of Lexington Utilities"
      },
      {
        name: "City of New Bern Electric",
        value: "NC - City of New Bern Electric"
      },
      {
        name: "City of Shelby Utilities",
        value: "NC - City of Shelby Utilities"
      },
      { name: "Dobbins Electric Co", value: "NC - Dobbins Electric Co" },
      {
        name: "Dominion North Carolina Power",
        value: "NC - Dominion North Carolina Power"
      },
      { name: "Duke Energy NC", value: "NC - Duke Energy NC" },
      {
        name: "Edgecombe-Martin County EMC",
        value: "NC - Edgecombe-Martin County EMC"
      },
      { name: "ElectriCities", value: "NC - ElectriCities" },
      { name: "EnergyUnited Electric", value: "NC - EnergyUnited Electric" },
      {
        name: "Farmville Utilities Department",
        value: "NC - Farmville Utilities Department"
      },
      {
        name: "Four County Electric Membership",
        value: "NC - Four County Electric Membership"
      },
      {
        name: "French Broad Electric Membership Corporation",
        value: "NC - French Broad Electric Membership Corporation"
      },
      {
        name: "Greenville Utilities Commission",
        value: "NC - Greenville Utilities Commission"
      },
      {
        name: "Halifax Electric Membership",
        value: "NC - Halifax Electric Membership"
      },
      { name: "Haywood EMC", value: "NC - Haywood EMC" },
      {
        name: "High Point Electric Department",
        value: "NC - High Point Electric Department"
      },
      { name: "Jones-Onslow EMC", value: "NC - Jones-Onslow EMC" },
      { name: "Lumbee River EMC", value: "NC - Lumbee River EMC" },
      { name: "Lumberton Power", value: "NC - Lumberton Power" },
      {
        name: "New River Light and Power",
        value: "NC - New River Light and Power"
      },
      {
        name: "North Carolina Electric Membership Corp.",
        value: "NC - North Carolina Electric Membership Corp."
      },
      { name: "Piedmont Electric", value: "NC - Piedmont Electric" },
      {
        name: "Progress Energy Carolinas",
        value: "NC - Progress Energy Carolinas"
      },
      { name: "PWC Fayetteville", value: "NC - PWC Fayetteville" },
      {
        name: "Randolph Electric Membership Corp",
        value: "NC - Randolph Electric Membership Corp"
      },
      { name: "Roanoke Electric Co-Op", value: "NC - Roanoke Electric Co-Op" },
      { name: "Rutherford EMC", value: "NC - Rutherford EMC" },
      { name: "Storm Electric", value: "NC - Storm Electric" },
      { name: "Surry-Yadkin EMC", value: "NC - Surry-Yadkin EMC" },
      { name: "Tideland EMC", value: "NC - Tideland EMC" },
      {
        name: "Town of Forest City Utilities",
        value: "NC - Town of Forest City Utilities"
      },
      {
        name: "Union Power Cooperative",
        value: "NC - Union Power Cooperative"
      },
      { name: "Wake Electric", value: "NC - Wake Electric" },
      {
        name: "Washington Electric Utilities",
        value: "NC - Washington Electric Utilities"
      },
      { name: "Wesco Electric", value: "NC - Wesco Electric" },
      { name: "Williams Electric Co", value: "NC - Williams Electric Co" },
      { name: "WIlson Energy", value: "NC - WIlson Energy" },
      {
        name: "Wilson Utilities Department",
        value: "NC - Wilson Utilities Department"
      },
      { name: "Other", value: "NC - Other" }
    ]
  },
  {
    ND: [
      {
        name: "Basin Electric Power Cooperative",
        value: "ND - Basin Electric Power Cooperative"
      },
      {
        name: "Central Power Electric Cooperative",
        value: "ND - Central Power Electric Cooperative"
      },
      { name: "MDU", value: "ND - MDU" },
      {
        name: "Minnkota Power Cooperative",
        value: "ND - Minnkota Power Cooperative"
      },
      {
        name: "Otter Tail Power Company",
        value: "ND - Otter Tail Power Company"
      },
      {
        name: "Upper Missouri G&T Cooperative",
        value: "ND - Upper Missouri G&T Cooperative"
      },
      {
        name: "Verendrye Electric Cooperative",
        value: "ND - Verendrye Electric Cooperative"
      },
      { name: "Xcel Energy", value: "ND - Xcel Energy" },
      { name: "Other", value: "ND - Other" }
    ]
  },
  {
    NE: [
      { name: "ABC Electric Co", value: "NE - ABC Electric Co" },
      {
        name: "Fairbury Light and Water",
        value: "NE - Fairbury Light and Water"
      },
      {
        name: "Nebraska Public Power District",
        value: "NE - Nebraska Public Power District"
      },
      {
        name: "Omaha Public Power District",
        value: "NE - Omaha Public Power District"
      },
      { name: "Other", value: "NE - Other" }
    ]
  },
  {
    NH: [
      { name: "Granite State Electric", value: "NH - Granite State Electric" },
      { name: "Liberty Utilities", value: "NH - Liberty Utilities" },
      { name: "National Grid", value: "NH - National Grid" },
      { name: "Northeast Utilities", value: "NH - Northeast Utilities" },
      { name: "Public Service of NH", value: "NH - Public Service of NH" },
      { name: "Unitil Corporation", value: "NH - Unitil Corporation" },
      { name: "Other", value: "NH - Other" }
    ]
  },
  {
    NJ: [
      { name: "Ambit Energy", value: "NJ - Ambit Energy" },
      {
        name: "Asbury Park Electric Supply Co",
        value: "NJ - Asbury Park Electric Supply Co"
      },
      { name: "Atlantic City Electric", value: "NJ - Atlantic City Electric" },
      { name: "FirstEnergy", value: "NJ - FirstEnergy" },
      {
        name: "Gateway Energy Services",
        value: "NJ - Gateway Energy Services"
      },
      {
        name: "Jersey Central Power and Light Company",
        value: "NJ - Jersey Central Power and Light Company"
      },
      { name: "Milltown Boro Electric", value: "NJ - Milltown Boro Electric" },
      { name: "Northeast Utilities", value: "NJ - Northeast Utilities" },
      { name: "Orange & Rockland", value: "NJ - Orange & Rockland" },
      {
        name: "Powers Electric Company",
        value: "NJ - Powers Electric Company"
      },
      {
        name: "Public Service Electric and Gas Company {PSE&G}",
        value: "NJ - Public Service Electric and Gas Company {PSE&G}"
      },
      {
        name: "Sussex Rural Electric Cooperative",
        value: "NJ - Sussex Rural Electric Cooperative"
      },
      {
        name: "Vineland Municipal Electric Utility",
        value: "NJ - Vineland Municipal Electric Utility"
      },
      { name: "Other", value: "NJ - Other" }
    ]
  },
  {
    NM: [
      {
        name: "Central New Mexico Electric Cooperative",
        value: "NM - Central New Mexico Electric Cooperative"
      },
      {
        name: "Columbus Electric Co-Op",
        value: "NM - Columbus Electric Co-Op"
      },
      { name: "El Paso Electric", value: "NM - El Paso Electric" },
      {
        name: "Farmington Electric Utility System",
        value: "NM - Farmington Electric Utility System"
      },
      {
        name: "Jemez Mountains Electric Cooperative",
        value: "NM - Jemez Mountains Electric Cooperative"
      },
      { name: "Kit Carson Electric", value: "NM - Kit Carson Electric" },
      {
        name: "Lea County Electric Cooperative",
        value: "NM - Lea County Electric Cooperative"
      },
      {
        name: "Navajo Tribal Utility Authority",
        value: "NM - Navajo Tribal Utility Authority"
      },
      {
        name: "Northern Rio Arriba Electric Cooperative",
        value: "NM - Northern Rio Arriba Electric Cooperative"
      },
      { name: "PNM", value: "NM - PNM" },
      {
        name: "Public Service Company of New Mexico",
        value: "NM - Public Service Company of New Mexico"
      },
      { name: "Other", value: "NM - Other" }
    ]
  },
  {
    NV: [
      {
        name: "Lincoln County Power District Number 1",
        value: "NV - Lincoln County Power District Number 1"
      },
      { name: "Nevada Power", value: "NV - Nevada Power" },
      { name: "NV Energy", value: "NV - NV Energy" },
      {
        name: "Overton Power District No 5",
        value: "NV - Overton Power District No 5"
      },
      { name: "Sierra Pacific Power", value: "NV - Sierra Pacific Power" },
      {
        name: "Surprise Valley Electric",
        value: "NV - Surprise Valley Electric"
      },
      { name: "Other", value: "NV - Other" }
    ]
  },
  {
    NY: [
      { name: "Ambit Energy", value: "NY - Ambit Energy" },
      {
        name: "Azure Mountain Power Co",
        value: "NY - Azure Mountain Power Co"
      },
      {
        name: "Central Hudson Gas & Electric",
        value: "NY - Central Hudson Gas & Electric"
      },
      { name: "CH Energy Group", value: "NY - CH Energy Group" },
      {
        name: "Citizens Choice Energy Jamestown BPU",
        value: "NY - Citizens Choice Energy Jamestown BPU"
      },
      {
        name: "Consolidated Edison Company of New York {Con Edison}",
        value: "NY - Consolidated Edison Company of New York {Con Edison}"
      },
      { name: "Freeport Electric", value: "NY - Freeport Electric" },
      {
        name: "Green Island Power Authority",
        value: "NY - Green Island Power Authority"
      },
      { name: "IGS Energy", value: "NY - IGS Energy" },
      {
        name: "Jamestown Board of Public Utilities",
        value: "NY - Jamestown Board of Public Utilities"
      },
      {
        name: "Long Island Power Authority {LIPA}",
        value: "NY - Long Island Power Authority {LIPA}"
      },
      {
        name: "Massena Electric Department",
        value: "NY - Massena Electric Department"
      },
      { name: "National Grid", value: "NY - National Grid" },
      {
        name: "New York Power Authority {NYPA}",
        value: "NY - New York Power Authority {NYPA}"
      },
      {
        name: "New York State Electric & Gas {NYSEG}",
        value: "NY - New York State Electric & Gas {NYSEG}"
      },
      { name: "Ocean Electric", value: "NY - Ocean Electric" },
      { name: "Orange & Rockland", value: "NY - Orange & Rockland" },
      {
        name: "Plattsburgh Municipal Lighting Department",
        value: "NY - Plattsburgh Municipal Lighting Department"
      },
      {
        name: "PSEG Long Island formerly LIPA",
        value: "NY - PSEG Long Island formerly LIPA"
      },
      {
        name: "Public Service Electric and Gas Company {PSE&G}",
        value: "NY - Public Service Electric and Gas Company {PSE&G}"
      },
      {
        name: "Rochester Gas & Electric",
        value: "NY - Rochester Gas & Electric"
      },
      {
        name: "Salamanca Public Utilities",
        value: "NY - Salamanca Public Utilities"
      },
      {
        name: "Sherburne Municipal Electric",
        value: "NY - Sherburne Municipal Electric"
      },
      {
        name: "Tupper Lake Municipal Electric Department",
        value: "NY - Tupper Lake Municipal Electric Department"
      },
      {
        name: "Village of Akron Electric",
        value: "NY - Village of Akron Electric"
      },
      { name: "Village of Lake Placid", value: "NY - Village of Lake Placid" },
      { name: "Other", value: "NY - Other" }
    ]
  },
  {
    OH: [
      {
        name: "American Electric Power",
        value: "OH - American Electric Power"
      },
      {
        name: "Butler Rural Electric Cooperative",
        value: "OH - Butler Rural Electric Cooperative"
      },
      { name: "Cincinnati Bell Energy", value: "OH - Cincinnati Bell Energy" },
      {
        name: "Cleveland Electric Illuminating Company",
        value: "OH - Cleveland Electric Illuminating Company"
      },
      {
        name: "Consolidated Electric Cooperative",
        value: "OH - Consolidated Electric Cooperative"
      },
      { name: "Dayton Power & Light", value: "OH - Dayton Power & Light" },
      { name: "Duke Energy Ohio", value: "OH - Duke Energy Ohio" },
      {
        name: "Firelands Electric Cooperative",
        value: "OH - Firelands Electric Cooperative"
      },
      { name: "FirstEnergy", value: "OH - FirstEnergy" },
      {
        name: "Guernsey-Muskingum Electric Cooperative",
        value: "OH - Guernsey-Muskingum Electric Cooperative"
      },
      {
        name: "Hamilton Utility Services Department",
        value: "OH - Hamilton Utility Services Department"
      },
      {
        name: "Hancock Wood Electric Cooperative",
        value: "OH - Hancock Wood Electric Cooperative"
      },
      {
        name: "Holmes-Wayne Electric Cooperative",
        value: "OH - Holmes-Wayne Electric Cooperative"
      },
      { name: "IGS Energy", value: "OH - IGS Energy" },
      { name: "Jess Howard Electric", value: "OH - Jess Howard Electric" },
      {
        name: "Logan County Electric Cooperative",
        value: "OH - Logan County Electric Cooperative"
      },
      {
        name: "Lorain Medina Rural Electric Cooperative",
        value: "OH - Lorain Medina Rural Electric Cooperative"
      },
      { name: "Ohio Edison", value: "OH - Ohio Edison" },
      {
        name: "Paulding Putnam Electric Cooperative",
        value: "OH - Paulding Putnam Electric Cooperative"
      },
      {
        name: "South Central Power Company",
        value: "OH - South Central Power Company"
      },
      { name: "The Energy Coop", value: "OH - The Energy Coop" },
      { name: "Toledo Edison", value: "OH - Toledo Edison" },
      { name: "Other", value: "OH - Other" }
    ]
  },
  {
    OK: [
      {
        name: "American Electric Power",
        value: "OK - American Electric Power"
      },
      {
        name: "Caddo Electric Cooperative",
        value: "OK - Caddo Electric Cooperative"
      },
      {
        name: "City Of Waynoka Utility",
        value: "OK - City Of Waynoka Utility"
      },
      {
        name: "Compton Electric Company",
        value: "OK - Compton Electric Company"
      },
      { name: "Cotton Electric Co-Op", value: "OK - Cotton Electric Co-Op" },
      {
        name: "Kiamichi Electric Co-Op",
        value: "OK - Kiamichi Electric Co-Op"
      },
      {
        name: "Oklahoma Gas & Electric",
        value: "OK - Oklahoma Gas & Electric"
      },
      {
        name: "Public Service Company of Oklahoma",
        value: "OK - Public Service Company of Oklahoma"
      },
      { name: "Other", value: "OK - Other" }
    ]
  },
  {
    OR: [
      { name: "Ameren", value: "OR - Ameren" },
      { name: "Ashland Electric", value: "OR - Ashland Electric" },
      { name: "Canby Electric", value: "OR - Canby Electric" },
      {
        name: "City of Forest Grove Light and Power",
        value: "OR - City of Forest Grove Light and Power"
      },
      {
        name: "Columbia River Public Utility District",
        value: "OR - Columbia River Public Utility District"
      },
      {
        name: "Coos-Curry Electric Co-Op",
        value: "OR - Coos-Curry Electric Co-Op"
      },
      { name: "Emerald PUD", value: "OR - Emerald PUD" },
      {
        name: "Eugene Water & Electric Board",
        value: "OR - Eugene Water & Electric Board"
      },
      { name: "Idaho Power", value: "OR - Idaho Power" },
      {
        name: "PacifiCorp {Pacific Power}",
        value: "OR - PacifiCorp {Pacific Power}"
      },
      {
        name: "Portland General Electric",
        value: "OR - Portland General Electric"
      },
      { name: "Salem Electric", value: "OR - Salem Electric" },
      { name: "Tillamook PUD", value: "OR - Tillamook PUD" },
      {
        name: "Umatilla Electric Cooperative",
        value: "OR - Umatilla Electric Cooperative"
      },
      { name: "Wasco Electric", value: "OR - Wasco Electric" },
      {
        name: "West Oregon Electric Cooperative",
        value: "OR - West Oregon Electric Cooperative"
      },
      { name: "Other", value: "OR - Other" }
    ]
  },
  {
    PA: [
      {
        name: "Adams Electric Cooperative",
        value: "PA - Adams Electric Cooperative"
      },
      { name: "Allegheny Power", value: "PA - Allegheny Power" },
      { name: "Ambit Energy", value: "PA - Ambit Energy" },
      {
        name: "Borough of Ellwood City",
        value: "PA - Borough of Ellwood City"
      },
      {
        name: "Borough of Ellwood City Electric",
        value: "PA - Borough of Ellwood City Electric"
      },
      {
        name: "Citizens Electric of Lewisburg",
        value: "PA - Citizens Electric of Lewisburg"
      },
      { name: "Claverack REC", value: "PA - Claverack REC" },
      { name: "Duquesne Light", value: "PA - Duquesne Light" },
      { name: "FirstEnergy", value: "PA - FirstEnergy" },
      { name: "IGS Energy", value: "PA - IGS Energy" },
      {
        name: "Lansdale Borough Electric",
        value: "PA - Lansdale Borough Electric"
      },
      { name: "Met-Ed", value: "PA - Met-Ed" },
      { name: "PECO", value: "PA - PECO" },
      { name: "Penelec", value: "PA - Penelec" },
      { name: "Penn Power", value: "PA - Penn Power" },
      {
        name: "Pike County Light & Power Company",
        value: "PA - Pike County Light & Power Company"
      },
      {
        name: "Powers Electric Company",
        value: "PA - Powers Electric Company"
      },
      { name: "PPL", value: "PA - PPL" },
      {
        name: "Rural Valley Electric Co.",
        value: "PA - Rural Valley Electric Co."
      },
      { name: "Superior Plus Utility", value: "PA - Superior Plus Utility" },
      { name: "UGI Utilities  Inc.", value: "PA - UGI Utilities  Inc." },
      { name: "Warren Electric Co-Op", value: "PA - Warren Electric Co-Op" },
      {
        name: "Wellsboro Electric Company",
        value: "PA - Wellsboro Electric Company"
      },
      { name: "West Penn Power", value: "PA - West Penn Power" },
      { name: "Other", value: "PA - Other" }
    ]
  },
  {
    RI: [
      { name: "Narragansett Electric", value: "RI - Narragansett Electric" },
      { name: "National Grid", value: "RI - National Grid" },
      { name: "Other", value: "RI - Other" }
    ]
  },
  {
    SC: [
      {
        name: "Berkeley Electric Cooperative",
        value: "SC - Berkeley Electric Cooperative"
      },
      {
        name: "Blue Ridge Electric Cooperative",
        value: "SC - Blue Ridge Electric Cooperative"
      },
      {
        name: "Broad River Electric Cooperative",
        value: "SC - Broad River Electric Cooperative"
      },
      {
        name: "Central Electric Power Cooperative Inc.",
        value: "SC - Central Electric Power Cooperative Inc."
      },
      { name: "City of Rock Hill", value: "SC - City of Rock Hill" },
      { name: "Duke Energy SC", value: "SC - Duke Energy SC" },
      { name: "Horry Electric Co-Op", value: "SC - Horry Electric Co-Op" },
      {
        name: "Laurens Electric Cooperative",
        value: "SC - Laurens Electric Cooperative"
      },
      {
        name: "Little River Electric Co-Op",
        value: "SC - Little River Electric Co-Op"
      },
      {
        name: "Newberry Electric Cooperative",
        value: "SC - Newberry Electric Cooperative"
      },
      {
        name: "Palmetto Electric Cooperative",
        value: "SC - Palmetto Electric Cooperative"
      },
      {
        name: "Pee Dee Electric Cooperative",
        value: "SC - Pee Dee Electric Cooperative"
      },
      {
        name: "Progress Energy Carolinas",
        value: "SC - Progress Energy Carolinas"
      },
      { name: "Santee Cooper", value: "SC - Santee Cooper" },
      {
        name: "South Carolina Electric & Gas Company",
        value: "SC - South Carolina Electric & Gas Company"
      },
      { name: "Other", value: "SC - Other" }
    ]
  },
  {
    SD: [
      { name: "Black Hills Power", value: "SD - Black Hills Power" },
      {
        name: "East River Electric Cooperative",
        value: "SD - East River Electric Cooperative"
      },
      {
        name: "Montana-Dakota Utilities",
        value: "SD - Montana-Dakota Utilities"
      },
      { name: "Northwestern Energy", value: "SD - Northwestern Energy" },
      {
        name: "Otter Tail Power Company",
        value: "SD - Otter Tail Power Company"
      },
      {
        name: "Rushmore Electric Cooperative",
        value: "SD - Rushmore Electric Cooperative"
      },
      {
        name: "West River Electric Association",
        value: "SD - West River Electric Association"
      },
      { name: "Xcel Energy", value: "SD - Xcel Energy" },
      { name: "Other", value: "SD - Other" }
    ]
  },
  {
    TN: [
      {
        name: "Alcoa Electric Department",
        value: "TN - Alcoa Electric Department"
      },
      { name: "Athens Utilities Board", value: "TN - Athens Utilities Board" },
      {
        name: "Bill Dower's Sons Electric Co",
        value: "TN - Bill Dower's Sons Electric Co"
      },
      {
        name: "Chickasaw Electric Co-Op",
        value: "TN - Chickasaw Electric Co-Op"
      },
      {
        name: "Citizens Utilities Board",
        value: "TN - Citizens Utilities Board"
      },
      {
        name: "City of Lebanon Utilities",
        value: "TN - City of Lebanon Utilities"
      },
      {
        name: "Clarksville Department of Electricity",
        value: "TN - Clarksville Department of Electricity"
      },
      {
        name: "Clinton Utilities Board",
        value: "TN - Clinton Utilities Board"
      },
      {
        name: "Columbia Power and Water Systems",
        value: "TN - Columbia Power and Water Systems"
      },
      {
        name: "Cumberland Electric Membership Corporation",
        value: "TN - Cumberland Electric Membership Corporation"
      },
      {
        name: "Dickson Electric System",
        value: "TN - Dickson Electric System"
      },
      { name: "Duck River EMC", value: "TN - Duck River EMC" },
      { name: "Electric Power Board", value: "TN - Electric Power Board" },
      {
        name: "Elizabethton Electric Department",
        value: "TN - Elizabethton Electric Department"
      },
      { name: "Forked Deer Electric", value: "TN - Forked Deer Electric" },
      {
        name: "Fort Loudoun Electric Cooperative",
        value: "TN - Fort Loudoun Electric Cooperative"
      },
      {
        name: "Gibson Electric Membership Corporation",
        value: "TN - Gibson Electric Membership Corporation"
      },
      { name: "Jackson", value: "TN - Jackson" },
      {
        name: "Kingsport Power {Appalachian Power}",
        value: "TN - Kingsport Power {Appalachian Power}"
      },
      {
        name: "Knoxville Utilities Board",
        value: "TN - Knoxville Utilities Board"
      },
      {
        name: "Landon Electric Company",
        value: "TN - Landon Electric Company"
      },
      {
        name: "Lawrenceburg Utility Department",
        value: "TN - Lawrenceburg Utility Department"
      },
      {
        name: "Lenoir City Utilities Board",
        value: "TN - Lenoir City Utilities Board"
      },
      {
        name: "McMinnville Electric System",
        value: "TN - McMinnville Electric System"
      },
      {
        name: "Memphis Light  Gas and Water",
        value: "TN - Memphis Light  Gas and Water"
      },
      {
        name: "Middle Tennessee Electric Membership Corporation {MTEMC}",
        value: "TN - Middle Tennessee Electric Membership Corporation {MTEMC}"
      },
      {
        name: "Mountain Electric Co-Op",
        value: "TN - Mountain Electric Co-Op"
      },
      {
        name: "Nashville Electric Service",
        value: "TN - Nashville Electric Service"
      },
      { name: "Rains Electric Company", value: "TN - Rains Electric Company" },
      {
        name: "Sequachee Valley Electric Cooperative",
        value: "TN - Sequachee Valley Electric Cooperative"
      },
      {
        name: "Sevier County Electric System",
        value: "TN - Sevier County Electric System"
      },
      {
        name: "Sewell Electric Company",
        value: "TN - Sewell Electric Company"
      },
      {
        name: "Tennessee Jackson Energy Authority",
        value: "TN - Tennessee Jackson Energy Authority"
      },
      {
        name: "Tennessee Valley Authority",
        value: "TN - Tennessee Valley Authority"
      },
      {
        name: "Upper Cumberland Electric Membership Corporation {UCEMC}",
        value: "TN - Upper Cumberland Electric Membership Corporation {UCEMC}"
      },
      {
        name: "Volunteer Electric Cooperative",
        value: "TN - Volunteer Electric Cooperative"
      },
      {
        name: "Weakley County Municipal Electric System",
        value: "TN - Weakley County Municipal Electric System"
      },
      { name: "Other", value: "TN - Other" }
    ]
  },
  {
    TX: [
      { name: "4 Change", value: "TX - 4 Change" },
      { name: "Acacia", value: "TX - Acacia" },
      { name: "Ambit", value: "TX - Ambit" },
      {
        name: "American Electric Power",
        value: "TX - American Electric Power"
      },
      {
        name: "American Light and Power",
        value: "TX - American Light and Power"
      },
      { name: "Amigo Energy", value: "TX - Amigo Energy" },
      { name: "AP G&E", value: "TX - AP G&E" },
      { name: "Apna", value: "TX - Apna" },
      { name: "Austin Energy", value: "TX - Austin Energy" },
      {
        name: "Bandera Electric Cooperative",
        value: "TX - Bandera Electric Cooperative"
      },
      {
        name: "Bartlett Electric Cooperative",
        value: "TX - Bartlett Electric Cooperative"
      },
      { name: "Blackstone Electric Co", value: "TX - Blackstone Electric Co" },
      {
        name: "Bluebonnet Electric Cooperative",
        value: "TX - Bluebonnet Electric Cooperative"
      },
      { name: "Bounce Energy", value: "TX - Bounce Energy" },
      {
        name: "Bowie Cass Electric Co-op",
        value: "TX - Bowie Cass Electric Co-op"
      },
      { name: "Breeze", value: "TX - Breeze" },
      { name: "Brilliant", value: "TX - Brilliant" },
      {
        name: "Brownsville Public Utilities Board",
        value: "TX - Brownsville Public Utilities Board"
      },
      { name: "Bryan Texas Utilities", value: "TX - Bryan Texas Utilities" },
      { name: "CenterPoint Energy", value: "TX - CenterPoint Energy" },
      {
        name: "Central Texas Electric Co-Op",
        value: "TX - Central Texas Electric Co-Op"
      },
      {
        name: "Champion Energy Services",
        value: "TX - Champion Energy Services"
      },
      {
        name: "Cherokee County Electric Cooperative {CCECA}",
        value: "TX - Cherokee County Electric Cooperative {CCECA}"
      },
      { name: "Cirro", value: "TX - Cirro" },
      {
        name: "City of Austin Utilities",
        value: "TX - City of Austin Utilities"
      },
      {
        name: "City of Coleman Utilities",
        value: "TX - City of Coleman Utilities"
      },
      {
        name: "City of Gastonia Electric",
        value: "TX - City of Gastonia Electric"
      },
      {
        name: "City of Sanger Utilities",
        value: "TX - City of Sanger Utilities"
      },
      { name: "Clear View", value: "TX - Clear View" },
      {
        name: "College Station Utilities",
        value: "TX - College Station Utilities"
      },
      {
        name: "Comanche Electric Cooperative",
        value: "TX - Comanche Electric Cooperative"
      },
      { name: "Coserv Electric", value: "TX - Coserv Electric" },
      { name: "CPS Energy", value: "TX - CPS Energy" },
      {
        name: "Deep East Texas Electric Coop",
        value: "TX - Deep East Texas Electric Coop"
      },
      {
        name: "Denton Municipal Eletric",
        value: "TX - Denton Municipal Eletric"
      },
      { name: "Direct Energy", value: "TX - Direct Energy" },
      { name: "dPi Energy", value: "TX - dPi Energy" },
      { name: "Eagle", value: "TX - Eagle" },
      { name: "Eagle Energy", value: "TX - Eagle Energy" },
      { name: "El Paso Electric", value: "TX - El Paso Electric" },
      {
        name: "Electric Database Publishing",
        value: "TX - Electric Database Publishing"
      },
      { name: "Energy Plus Company", value: "TX - Energy Plus Company" },
      { name: "Entergy", value: "TX - Entergy" },
      { name: "Entrust Energy", value: "TX - Entrust Energy" },
      { name: "Everything", value: "TX - Everything" },
      {
        name: "Fannin County Electric Co",
        value: "TX - Fannin County Electric Co"
      },
      {
        name: "Farmers Electrical Cooperative",
        value: "TX - Farmers Electrical Cooperative"
      },
      { name: "First Choice", value: "TX - First Choice" },
      {
        name: "First Texas Energy Corporation",
        value: "TX - First Texas Energy Corporation"
      },
      {
        name: "Floresville Electric Light and Power System",
        value: "TX - Floresville Electric Light and Power System"
      },
      { name: "Frontier", value: "TX - Frontier" },
      {
        name: "Garland Power and Light",
        value: "TX - Garland Power and Light"
      },
      {
        name: "Georgetown Utility Services {GUS}",
        value: "TX - Georgetown Utility Services {GUS}"
      },
      { name: "GEUS Electric", value: "TX - GEUS Electric" },
      { name: "Gexa Energy", value: "TX - Gexa Energy" },
      { name: "Green Mountain Energy", value: "TX - Green Mountain Energy" },
      {
        name: "Guadalupe Valley Electric Cooperative",
        value: "TX - Guadalupe Valley Electric Cooperative"
      },
      { name: "GVEC", value: "TX - GVEC" },
      {
        name: "Hamilton County Electric Cooperative",
        value: "TX - Hamilton County Electric Cooperative"
      },
      { name: "Hilco Electric Co-Op", value: "TX - Hilco Electric Co-Op" },
      { name: "Hino", value: "TX - Hino" },
      {
        name: "Houston County Electric Cooperative",
        value: "TX - Houston County Electric Cooperative"
      },
      { name: "IGS energy", value: "TX - IGS energy" },
      { name: "infintite", value: "TX - infintite" },
      {
        name: "International Electric Power {IEP}",
        value: "TX - International Electric Power {IEP}"
      },
      { name: "Jackson EMC", value: "TX - Jackson EMC" },
      {
        name: "Jasper-Newton Electric Cooperative",
        value: "TX - Jasper-Newton Electric Cooperative"
      },
      { name: "Just Energy", value: "TX - Just Energy" },
      { name: "Karnes Electric Co-Op", value: "TX - Karnes Electric Co-Op" },
      {
        name: "Kerrville Public Utility Board {KPUB}",
        value: "TX - Kerrville Public Utility Board {KPUB}"
      },
      { name: "Kona", value: "TX - Kona" },
      {
        name: "Lea County Electric Cooperative",
        value: "TX - Lea County Electric Cooperative"
      },
      { name: "Limunant", value: "TX - Limunant" },
      {
        name: "Lower Colorado River Authority",
        value: "TX - Lower Colorado River Authority"
      },
      {
        name: "Lubbock Power and Light",
        value: "TX - Lubbock Power and Light"
      },
      { name: "Luminant", value: "TX - Luminant" },
      { name: "Lyntegar Electric", value: "TX - Lyntegar Electric" },
      { name: "Magic Valley", value: "TX - Magic Valley" },
      { name: "McCaffety Electric", value: "TX - McCaffety Electric" },
      {
        name: "Medina Electric Cooperative",
        value: "TX - Medina Electric Cooperative"
      },
      { name: "Mid-South Synergy", value: "TX - Mid-South Synergy" },
      { name: "Mission Power", value: "TX - Mission Power" },
      {
        name: "Navarro County Electric Cooperative",
        value: "TX - Navarro County Electric Cooperative"
      },
      {
        name: "Navasota Valley Electric Cooperative",
        value: "TX - Navasota Valley Electric Cooperative"
      },
      {
        name: "New Braunfels Utilities",
        value: "TX - New Braunfels Utilities"
      },
      { name: "New Leaf", value: "TX - New Leaf" },
      {
        name: "Nueces Electric Cooperative",
        value: "TX - Nueces Electric Cooperative"
      },
      {
        name: "Oncor Electric {Formerly TXU}",
        value: "TX - Oncor Electric {Formerly TXU}"
      },
      { name: "Our energy", value: "TX - Our energy" },
      {
        name: "Panola-Harrison Electric Cooperative",
        value: "TX - Panola-Harrison Electric Cooperative"
      },
      {
        name: "Pedernales Electric Cooperative",
        value: "TX - Pedernales Electric Cooperative"
      },
      { name: "Penny wise", value: "TX - Penny wise" },
      { name: "Reach", value: "TX - Reach" },
      { name: "Reliant Energy", value: "TX - Reliant Energy" },
      {
        name: "Rio Grande Electric Cooperative",
        value: "TX - Rio Grande Electric Cooperative"
      },
      {
        name: "Rusk County Electric Cooperative",
        value: "TX - Rusk County Electric Cooperative"
      },
      { name: "Sam Houston Electric", value: "TX - Sam Houston Electric" },
      {
        name: "San Bernard Electric Co-Op",
        value: "TX - San Bernard Electric Co-Op"
      },
      {
        name: "San Patricio Electric Co-Op",
        value: "TX - San Patricio Electric Co-Op"
      },
      { name: "Sharyland Utilities", value: "TX - Sharyland Utilities" },
      { name: "Source", value: "TX - Source" },
      {
        name: "South Plains Electric Cooperative",
        value: "TX - South Plains Electric Cooperative"
      },
      {
        name: "Southwest Power and Light",
        value: "TX - Southwest Power and Light"
      },
      {
        name: "Southwestern Electric Power Company {SWEPCO}",
        value: "TX - Southwestern Electric Power Company {SWEPCO}"
      },
      { name: "Spark Energy", value: "TX - Spark Energy" },
      { name: "Star Tex Power", value: "TX - Star Tex Power" },
      { name: "Star Trex", value: "TX - Star Trex" },
      { name: "Stream Energy", value: "TX - Stream Energy" },
      { name: "Summer", value: "TX - Summer" },
      { name: "Tara Energy", value: "TX - Tara Energy" },
      { name: "Taylor Electric Co-Op", value: "TX - Taylor Electric Co-Op" },
      {
        name: "Texas Electric Service Company",
        value: "TX - Texas Electric Service Company"
      },
      { name: "Texpo", value: "TX - Texpo" },
      { name: "Tri Eagle Energy", value: "TX - Tri Eagle Energy" },
      {
        name: "Trinity Valley Electric Cooperative",
        value: "TX - Trinity Valley Electric Cooperative"
      },
      { name: "Tru Smart", value: "TX - Tru Smart" },
      { name: "True Electric", value: "TX - True Electric" },
      { name: "TXU", value: "TX - TXU" },
      {
        name: "United Cooperative Services",
        value: "TX - United Cooperative Services"
      },
      {
        name: "Upshur Rural Electric Co-Op",
        value: "TX - Upshur Rural Electric Co-Op"
      },
      { name: "V247 Power Corportion", value: "TX - V247 Power Corportion" },
      { name: "Veteran", value: "TX - Veteran" },
      {
        name: "Victoria Electric Cooperative",
        value: "TX - Victoria Electric Cooperative"
      },
      { name: "Wise Electric Co-Op", value: "TX - Wise Electric Co-Op" },
      {
        name: "Wood County Electric Cooperative",
        value: "TX - Wood County Electric Cooperative"
      },
      { name: "WTU Retail Energy", value: "TX - WTU Retail Energy" },
      { name: "XOOM Energy", value: "TX - XOOM Energy" },
      { name: "YEP", value: "TX - YEP" },
      { name: "Other", value: "TX - Other" }
    ]
  },
  {
    UT: [
      {
        name: "Brigham City Public Power",
        value: "UT - Brigham City Public Power"
      },
      {
        name: "City of Santa Clara Power",
        value: "UT - City of Santa Clara Power"
      },
      {
        name: "Dixie Escalante Electric",
        value: "UT - Dixie Escalante Electric"
      },
      {
        name: "Garkane Energy Cooperative",
        value: "UT - Garkane Energy Cooperative"
      },
      { name: "Heber Light and Power", value: "UT - Heber Light and Power" },
      {
        name: "Hurricane City Utilities",
        value: "UT - Hurricane City Utilities"
      },
      {
        name: "Hyrum City Power & Light",
        value: "UT - Hyrum City Power & Light"
      },
      {
        name: "Intermountain Power Agency",
        value: "UT - Intermountain Power Agency"
      },
      { name: "Kaysville City", value: "UT - Kaysville City" },
      { name: "Lehi City Power", value: "UT - Lehi City Power" },
      {
        name: "Moon Lake Electric Association",
        value: "UT - Moon Lake Electric Association"
      },
      { name: "Murray City Power", value: "UT - Murray City Power" },
      {
        name: "PacifiCorp {Rocky Mountain Power}",
        value: "UT - PacifiCorp {Rocky Mountain Power}"
      },
      { name: "Provo City Power", value: "UT - Provo City Power" },
      {
        name: "Strawberry Electric Service District",
        value: "UT - Strawberry Electric Service District"
      },
      { name: "Other", value: "UT - Other" }
    ]
  },
  {
    VA: [
      {
        name: "A and N Electric Cooperative",
        value: "VA - A and N Electric Cooperative"
      },
      { name: "Allegheny Power", value: "VA - Allegheny Power" },
      { name: "Appalachian Power", value: "VA - Appalachian Power" },
      {
        name: "Bristol Virginia Utilities",
        value: "VA - Bristol Virginia Utilities"
      },
      {
        name: "Central Virginia Electric Cooperative",
        value: "VA - Central Virginia Electric Cooperative"
      },
      {
        name: "Community Electric Cooperative",
        value: "VA - Community Electric Cooperative"
      },
      {
        name: "Craig-Botetourt Electric Cooperative",
        value: "VA - Craig-Botetourt Electric Cooperative"
      },
      { name: "Danville Utilities", value: "VA - Danville Utilities" },
      { name: "Delmarva Power", value: "VA - Delmarva Power" },
      {
        name: "Dominion Virginia Power",
        value: "VA - Dominion Virginia Power"
      },
      {
        name: "Franklin Electric Department",
        value: "VA - Franklin Electric Department"
      },
      { name: "IGS Energy", value: "VA - IGS Energy" },
      {
        name: "Mecklenburg Electric Cooperative",
        value: "VA - Mecklenburg Electric Cooperative"
      },
      {
        name: "Northern Neck Electric Cooperative",
        value: "VA - Northern Neck Electric Cooperative"
      },
      { name: "NOVEC", value: "VA - NOVEC" },
      { name: "Piedmont EMC", value: "VA - Piedmont EMC" },
      {
        name: "Prince George Electric Cooperative",
        value: "VA - Prince George Electric Cooperative"
      },
      {
        name: "Radford Electric Department",
        value: "VA - Radford Electric Department"
      },
      {
        name: "Rappahannock Electric Cooperative",
        value: "VA - Rappahannock Electric Cooperative"
      },
      {
        name: "Shenandoah Valley Electric Cooperative",
        value: "VA - Shenandoah Valley Electric Cooperative"
      },
      {
        name: "Southside Electric Cooperative",
        value: "VA - Southside Electric Cooperative"
      },
      {
        name: "Town of Richlands Electric Department",
        value: "VA - Town of Richlands Electric Department"
      },
      { name: "Town of Vienna", value: "VA - Town of Vienna" },
      {
        name: "Wakefield Municipal Gas and Lighting",
        value: "VA - Wakefield Municipal Gas and Lighting"
      },
      { name: "Other", value: "VA - Other" }
    ]
  },
  {
    VT: [
      {
        name: "Central Vermont Public Service",
        value: "VT - Central Vermont Public Service"
      },
      { name: "Green Mountain Power", value: "VT - Green Mountain Power" },
      {
        name: "Hardwick Electric Department",
        value: "VT - Hardwick Electric Department"
      },
      {
        name: "Vermont Electric Cooperative",
        value: "VT - Vermont Electric Cooperative"
      },
      {
        name: "Washington Electric Cooperative",
        value: "VT - Washington Electric Cooperative"
      },
      { name: "Other", value: "VT - Other" }
    ]
  },
  {
    WA: [
      { name: "Avista Utilities", value: "WA - Avista Utilities" },
      { name: "Benton PUD", value: "WA - Benton PUD" },
      {
        name: "Chelan County Public Utility District",
        value: "WA - Chelan County Public Utility District"
      },
      { name: "City of Blaine", value: "WA - City of Blaine" },
      { name: "Columbia REA", value: "WA - Columbia REA" },
      { name: "Cowlitz PUD", value: "WA - Cowlitz PUD" },
      {
        name: "Douglas County Public Utility District",
        value: "WA - Douglas County Public Utility District"
      },
      {
        name: "Grant County Public Utility District",
        value: "WA - Grant County Public Utility District"
      },
      {
        name: "Klickitat Public Utility District",
        value: "WA - Klickitat Public Utility District"
      },
      {
        name: "Lewis County Public Works",
        value: "WA - Lewis County Public Works"
      },
      {
        name: "Mason County Public Utility District 3",
        value: "WA - Mason County Public Utility District 3"
      },
      {
        name: "Modern Electric Water Company",
        value: "WA - Modern Electric Water Company"
      },
      {
        name: "Orcas Power and Light Cooperative {OPALCO}",
        value: "WA - Orcas Power and Light Cooperative {OPALCO}"
      },
      {
        name: "Pacific County Public Utility District No. 2",
        value: "WA - Pacific County Public Utility District No. 2"
      },
      {
        name: "PacifiCorp {Pacific Power}",
        value: "WA - PacifiCorp {Pacific Power}"
      },
      {
        name: "Peninsula Light Company",
        value: "WA - Peninsula Light Company"
      },
      { name: "Puget Sound Energy", value: "WA - Puget Sound Energy" },
      { name: "Seattle City Light", value: "WA - Seattle City Light" },
      {
        name: "Snohomish County Public Utility District {PUD}",
        value: "WA - Snohomish County Public Utility District {PUD}"
      },
      { name: "Tacoma Power", value: "WA - Tacoma Power" },
      { name: "Tanner Electric", value: "WA - Tanner Electric" },
      { name: "Other", value: "WA - Other" }
    ]
  },
  {
    WI: [
      { name: "Alliant Energy", value: "WI - Alliant Energy" },
      { name: "Dairyland Power Co-op", value: "WI - Dairyland Power Co-op" },
      {
        name: "Eau Claire Energy Cooperative",
        value: "WI - Eau Claire Energy Cooperative"
      },
      { name: "Kaukauna Utilities", value: "WI - Kaukauna Utilities" },
      {
        name: "Madison Gas and Electric",
        value: "WI - Madison Gas and Electric"
      },
      {
        name: "Pierce Pepin Cooperative Services",
        value: "WI - Pierce Pepin Cooperative Services"
      },
      {
        name: "Polk-Burnett Electric Cooperative",
        value: "WI - Polk-Burnett Electric Cooperative"
      },
      {
        name: "Richland Electric Cooperative",
        value: "WI - Richland Electric Cooperative"
      },
      {
        name: "River Falls Municipal Utility",
        value: "WI - River Falls Municipal Utility"
      },
      {
        name: "Superior Water Light and Power",
        value: "WI - Superior Water Light and Power"
      },
      {
        name: "Village of Hustisford Electric",
        value: "WI - Village of Hustisford Electric"
      },
      { name: "We Energies", value: "WI - We Energies" },
      {
        name: "Wisconsin Power and Light Company",
        value: "WI - Wisconsin Power and Light Company"
      },
      {
        name: "Wisconsin Public Service Corporation",
        value: "WI - Wisconsin Public Service Corporation"
      },
      { name: "Xcel Energy", value: "WI - Xcel Energy" },
      { name: "Other", value: "WI - Other" }
    ]
  },
  {
    WV: [
      { name: "Allegheny Power", value: "WV - Allegheny Power" },
      { name: "Appalachian Power", value: "WV - Appalachian Power" },
      {
        name: "Wheeling Electric Power {AEP Ohio}",
        value: "WV - Wheeling Electric Power {AEP Ohio}"
      },
      { name: "Other", value: "WV - Other" }
    ]
  },
  {
    WY: [
      {
        name: "Cheyenne Light Fuel & Power",
        value: "WY - Cheyenne Light Fuel & Power"
      },
      { name: "High Plains Power", value: "WY - High Plains Power" },
      { name: "High West Energy", value: "WY - High West Energy" },
      { name: "Lower Valley Energy", value: "WY - Lower Valley Energy" },
      {
        name: "PacifiCorp {Rocky Mountain Power}",
        value: "WY - PacifiCorp {Rocky Mountain Power}"
      },
      {
        name: "Wheatland Rural Electric Association",
        value: "WY - Wheatland Rural Electric Association"
      },
      { name: "Other", value: "WY - Other" }
    ]
  }
];
