import React, { useEffect, useState } from "react";
import "../../App.css";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import themeDefault from "../../CustomTheme.js";
import { Slide } from "@material-ui/core";
import Loading from "../../components/Backdrop/BackDrop";
import jsyaml from "js-yaml";
import Notifications from "../Notifications/Notifications";
import MainPage from "../Pages/MainPage";

function LifeInsurance({ configFilePath, bannerImagePath }) {
  const history = createBrowserHistory();
  const routes = () => {
    return (
      <Router history={history}>
        <MainPage
          vertical={"life_insurance"}
          bannerImagePath={bannerImagePath}
        />
        <Loading />
      </Router>
    );
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  useEffect(() => {
    fetch(configFilePath)
      .then((r) => r.text())
      .then((text) => {
        const json = jsyaml.load(text);
        document
          .getElementsByClassName("App")[0]
          .setAttribute("life", JSON.stringify(json));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <MuiThemeProvider theme={themeDefault}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={10}
        TransitionComponent={TransitionUp}
        className="customAlert"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div id="approot" className="App">
          <div className="content">
            <div className="routes-container">{routes()}</div>
          </div>
          <Notifications />
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default LifeInsurance;
