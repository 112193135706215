import React, { useState, useEffect } from "react";
import { Typography, InputBase, TextField } from "@material-ui/core";
import useStyles from "./components.style";
import { useTranslation } from "react-i18next";
import { NavigationButton } from "../../CustomButton/Button";
import {
  FormatQuestion,
  FormatHomeClaimSectionTitle,
  ChangeTitle,
  FormatMedicineSectionTitle
} from "../../../services/utility";

const TextFieldQuestion = ({
  handleNext,
  dataHandler,
  data,
  handlePrevious,
  questionFieldValues,
  addedDriverCount,
  vertical,
  lifePersonCount,
  medicineCount,
  homeClaimCount,
  healthChildCount
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState({
    value: false
  });
  const val = data.id;
  const validate_occupation = /^[^-\s-\d][a-zA-Z\s]*$/; // validates only space and alphabet
  const validate_ten_digits = /^(?:\d{1,10}(\.\d{0,2})?|\.?\d{1,2})$/; // sets to decimals
  const validate_eight_digits = /^(?:\d{1,8}(\.\d{0,2})?|\.?\d{1,2})$/; // sets to decimals
  const validate_weight = /^(?:\d{1,3}(\.\d{0,2})?|\.?\d{1,2})$/; // without decimals
  const validate_five_digit_decimal = /^(?:\d{1,5}(\.\d{0,2})?|\.?\d{1,2})$/; // without decimals
  const validate_five_digit_no_decimal = /^(?:\d{1,5})$/; // without decimals
  const disableValue = () => {
    if (data?.field === "optional") return false;
    if (data.id === "square_footage") {
      if (questionFieldValues[val]?.length === 0) return false;
      return questionFieldValues[val]?.length < 3 ? true : false;
    } else if (data?.id === "year_built") {
      return questionFieldValues[val]?.length < 4 ? true : false;
    } else if (data?.id === "replacement_cost") {
      return questionFieldValues[val]?.length < 5 ? true : false;
    } else if (data?.id === "household_income") {
      return questionFieldValues[val] < 5000 ? true : false;
    } else if (data?.id === "dwelling_value") {
      return questionFieldValues[val] < 1000 ? true : false;
    } else {
      return !questionFieldValues[val]?.length;
    }
  };

  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(questionFieldValues);
  }, [questionFieldValues]);

  const titleFormat = (question) => {
    if (vertical === "auto_insurance") {
      if (data.id === "annual_miles") {
        return question.replace("{MAKE}", questionFieldValues.make);
      } else if (data.id === "submodel") {
        return question.replace(
          "{MAKE} {MODEL}",
          `${questionFieldValues.make} ${questionFieldValues.model}`
        );
      } else {
        return FormatQuestion(t, data.id, question, addedDriverCount);
      }
    } else if (vertical === "home") {
      return FormatHomeClaimSectionTitle(question, homeClaimCount);
    } else if (vertical === "life_insurance") {
      if (
        data.id === "medication_name" ||
        data.id === "dosage" ||
        data.id === "frequency" ||
        data.id === "comment"
      ) {
        return FormatMedicineSectionTitle(
          data.title,
          medicineCount,
          lifePersonCount
        );
      } else {
        return ChangeTitle(data.title, lifePersonCount, "person");
      }
    } else if (vertical === "health_insurance") {
      return ChangeTitle(data.title, healthChildCount, "child");
    } else {
      return question;
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {data ? titleFormat(data.title) : t("What is the value?")}
      </Typography>
      <TextField
        error={error.value}
        id="outlined-basic"
        placeholder={data && data.placeholder ? data.placeholder : ""}
        variant="outlined"
        InputProps={{
          className: `${classes.textInputField} ${classes.spaceBottom} ${classes.spaceTop}`
        }}
        value={values[val]?.length ? values[val] : ""}
        helperText={error.value && "Field should not be empty."}
        onChange={(e) => {
          let input = e.target.value;
          if (data.id === "months_at_current_residence") {
            input = e.target.value;

            if (
              !input ||
              (input[0].match("[1-9]") &&
                validate_five_digit_no_decimal.test(input))
            ) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "annual_miles") {
            input = e.target.value;

            if (
              !input ||
              (input[0].match("[1-9]") &&
                validate_five_digit_decimal.test(input))
            ) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "drivers_count") {
            input = e.target.value.slice(0, 1);
            if (!input || input[0].match("[1-5]")) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "occupation") {
            if (!input || validate_occupation.test(input)) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (
            data.id === "comprehensive_deductible" ||
            data.id === "property_damage" ||
            data.id === "collision_deductible" ||
            data.id === "square_footage" ||
            data.id === "dwelling_value" ||
            data.id === "paid_amount" ||
            data.id === "replacement_cost"
          ) {
            input = e.target.value;
            if (
              !input ||
              (input[0].match("[1-9]") && validate_ten_digits.test(input))
            ) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "household_income") {
            input = e.target.value;
            if (
              !input ||
              (input[0].match("[1-9]") && validate_eight_digits.test(input))
            ) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "weight") {
            input = e.target.value;
            if (
              !input ||
              (input[0].match("[1-5]") && validate_weight.test(input))
            ) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "zip_code") {
            input = e.target.value.slice(0, 5);
            if (!input || input[0].match("[0-9]")) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (data.id === "year_built") {
            input = e.target.value.slice(0, 4);
            if (!input || input[0].match("[1-9]")) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else if (
            data.id === "stories" ||
            data.id === "bedrooms" ||
            data.id === "bathrooms"
          ) {
            input = e.target.value.slice(0, 1);
            if (!input || input[0].match("[1-9]")) {
              const d = { ...values, [val]: input };
              dataHandler(d);
            }
          } else {
            const d = { ...values, [val]: e.target.value };
            dataHandler(d);
          }
          setError({ ...error, value: false });
        }}
      />

      <NavigationButton
        disabledValue={disableValue()}
        onClickPrev={() => handlePrevious()}
        onClickNext={() => handleNext()}
      />
    </div>
  );
};

export default TextFieldQuestion;
