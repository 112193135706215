import { createSelector } from "reselect";

const featureSelector = ({ common }) => common;

/**
 * get location
 */
export const getLocationDetail = createSelector(
  featureSelector,
  ({ location }) => {
    return location.data;
  }
);
