import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontSize: 16,
    fontFamily: "Raleway",
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    },
    lineHeight: {
      heading: 1.25,
      title: 1.4,
      body: 1.625,
      button: 1.5,
      bodyLarge: 2.5
    }
  },
  palette: {
    primary: {
      light: "#05BDCF5C",
      main: /^#([0-9a-f]{3}){1,2}$/i.test(localStorage.getItem("theme"))
        ? localStorage.getItem("theme")
        : "#05BDCF",
      dark: "#00A0AF",
      headerBg: "#F8F8F8",
      darkText: "#444444",
      greyText: "#A7A7A7",
      disabledGrey: "#E8F3F3",
      labelGrey: "#D1D1D1",
      shadow: "#0000001A",
      booleanButton: "#F6F6F6",
      emailPara: "#717171",
      borderColor: "#E4E4E4",
      hintIconBg: "#808080"
    },
    secondary: {
      main: "hsl(253, 73%, 56%)"
    },
    neutral: {
      100: "hsl(0, 0%, 100%)", //#ffffff
      150: "hsl(0, 0%, 93%)", //#ededed
      200: "hsl(233, 16%, 44%)", // #5e6282
      300: "hsl(225, 24%, 29%)", // #38415c
      400: "hsl(233, 52%, 19%)", // #171d4a
      500: "hsl(234, 51%, 16%)", //	#14183e
      600: "hsl(240, 6%, 3%)", //#070708
      700: "hsl(0, 0%, 0%)" // #000000
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});
