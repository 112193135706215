import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: "1.5rem",
    margin: "0 10px 10px 10px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.25rem"
    }
  },
  inputTextProperty: {
    color: theme.palette.primary.greyText,
    opacity: 1,
    textTransform: "capitalize"
  },
  termsText: {
    fontSize: "0.8rem",
    textAlign: "center",
    width: "60%",
    textTransform: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "300px"
    }
  },
  fs10: { fontSize: "10px" },
  spaceBottom: { marginBottom: 10 },
  spaceTop: { marginTop: 10 },
  spaceRight: { marginRight: 15 },
  textInputField: {
    fontSize: "15px",
    height: "45px",
    width: "370px",
    backgroundColor: "white",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  },
  textInputField2: {
    fontSize: "15px",
    height: "45px",
    width: "175px",
    margin: "auto",
    backgroundColor: "white",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "140px"
    }
  },
  textField2Wrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "370px",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  },
  termsLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  nxtBtn: {
    textTransform: "capitalize",
    color: theme.palette.neutral[100],
    marginBottom: 15
  },
  emailParaContainer: {
    // maxWidth: "750px",
    width: "60%",
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.primary.emailPara,
    textTransform: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80%"
    }
  },
  emailPara: {
    fontSize: "12px",
    textAlign: "center",
    textIndent: 30,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
      textIndent: 0
    }
  },
  disabledBg: { backgroundColor: theme.palette.primary.booleanButton },
  insuranceLink: { textDecoration: "none", color: "#05BDCF" },
  iconMarginLeft: { marginLeft: -8 },
  autoCompleteOptions: {
    fontSize: 15
  },
  autoCompleteField: {
    border: `1px solid ${theme.palette.primary.borderColor}`,
    paddingLeft: "5px",
    borderRadius: 5,
    opacity: 1,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.darkText}`
    },
    "&:focus-within": {
      border: `2px solid ${theme.palette.primary.main}`
    }
  }
}));
