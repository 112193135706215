import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    marginBottom: 10,
    maxHeight: "130px",
    overflow: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      maxHeight: "40vh"
    }
  },
  options: {
    justifyContent: "center",
    margin: "0 10px"
  },
  question: {
    color: theme.palette.primary.darkText,
    fontSize: "1.5rem",
    margin: "0 10px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.25rem",
      margin: "10px 20px"
    }
  },
  spaceRight: { marginRight: 15 },
  styledIcon: {
    fontSize: "18px",
    color: theme.palette.primary.darkText,
    opacity: 1
  },
  btn: {
    fontSize: "18px",
    textTransform: "capitalize",
    backgroundColor: theme.palette.neutral[100],
    border: "1px solid #E4E4E4",
    borderRadius: "5px",
    boxShadow: "none",
    padding: "10px 0",
    width: "220px",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
      padding: "10px 0"
    }
  },
  btnChecked: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  },
  radioBoxCover: {
    width: "15rem",
    margin: "10px 10px 0 0",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    paddingLeft: "5px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "5px auto",
      justifyContent: "center"
    }
  },
  questionPopupWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "900px"
  },

  formLabelStyle: {
    textTransform: "capitalize",
    border: "2px solid grey",
    borderRadius: "5px",
    background: "lightgrey",
    paddingRight: "15px",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  answerLabel: {
    display: "inline-block",
    fontSize: "0.875rem",
    lineHeight: 1,
    fontWeight: theme.typography.weights.medium
  },

  title: {
    fontSize: "1.5rem",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.semiBold,
    marginTop: 30,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.2rem"
    }
  },
  titleLength: {
    [theme.breakpoints.up("sm")]: {
      width: "25ch"
    }
  },
  textCenter: { textAlign: "center" },
  // browse option for autocomplete
  autoCompleteOptions: {
    fontSize: 15
  },
  textInputField: {
    fontSize: "15px",
    height: "45px",
    width: "370px",
    backgroundColor: "white",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "36px",
      width: "300px"
    }
  },
  autoCompleteField: {
    border: `1px solid ${theme.palette.primary.borderColor}`,
    paddingLeft: "5px",
    borderRadius: 5,
    opacity: 1,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.darkText}`
    },
    "&:focus-within": {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  spaceBottom: { marginBottom: 10 },
  expiryDateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10
  }
}));
