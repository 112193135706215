import { loaderOperations } from "../loader";
import { alertOperations } from "../alerts";
import { getAllMakersAPI, getModelAPI } from "../../services/api";
import * as actions from "./actions";
import i18n from "../../i18n";
/**
 * Fetch vehcle maker from NHTSA
 * @param {*} params
 */
const fetchAllMakers = (MAX_ID) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      let res = await getAllMakersAPI();
      const makes = [];
      for (const element of res.data.Results) {
        element.Make_ID <= MAX_ID && makes.push(element.Make_Name);
      }
      dispatch(
        actions.fetchVehicleMakeSuccess({
          data: makes.sort()
        })
      );

      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};
/**
 * Fetch vehcle model with comibanation of year and maker from NHTSA
 * @param {*} params
 */
const fetchVehicleModel = (make, year) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      let res = await getModelAPI(make, year);

      const models = [];
      for (const element of res.data.Results) {
        models.push(element?.Model_Name);
      }
      dispatch(
        actions.fetchVehicleModelSuccess({
          data: models.sort()
        })
      );

      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

export { fetchAllMakers, fetchVehicleModel };
