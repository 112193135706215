import * as types from "./types";

/**
 * Fetch List Success for vehicle make
 * @param {*} data
 */
const fetchVehicleMakeSuccess = (data) => ({
  type: types.FETCH_VEHICLE_MAKE_SUCCESS,
  payload: data
});
/**
 * Fetch List Success for vehicle model by make and year
 * @param {*} data
 */
const fetchVehicleModelSuccess = (data) => ({
  type: types.FETCH_VEHICLE_MODEL_SUCCESS,
  payload: data
});

export { fetchVehicleMakeSuccess, fetchVehicleModelSuccess };
